import { DbUser } from 'types';
import { basePath } from './utils/config';
import makeRequest from './utils/makeRequest';
import { TResponseApi } from 'interfaces/general.interface';
import { TUpdateUserApiResponse } from './responseInterfaces/user.response.interface';

// TESTED
export const loginDbUserAPI = async (token: string): Promise<any> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${basePath}/user/login/client`, params);
  const data = await response.json();
  return { response, data };
};

export const updateDbUserAPI = async (
  body: { filter: { id: string }; update: DbUser },
  token: string
): Promise<TResponseApi & TUpdateUserApiResponse> => {
  const params = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${basePath}/user/update_user`, params);
  const data = await response.json();
  return { response, data };
};

export const updateUserAvatarAPI = async (
  userId: string,
  image: File,
  token: any
): Promise<any> => {
  const url = `${basePath}/upload/client_user_profile_image?user_id=${userId} `;
  const formData = new FormData();
  formData.append('image', image);
  return makeRequest(url, true, true, 'POST', token, formData, '');
};

export const updateCompanyLogoAPI = async (
  clientId: string,
  image: File,
  token: any
): Promise<any> => {
  const url = `${basePath}/upload/client_logo?client_id=${clientId} `;
  const formData = new FormData();
  formData.append('image', image);
  return makeRequest(url, true, true, 'POST', token, formData, '');
};

export const updateUserLanguageAPI = async (
  body: any,
  token: string
): Promise<TResponseApi & TUpdateUserApiResponse> => {
  const params = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${basePath}/user/update_user`, params);
  const data = await response.json();
  return { response, data };
};

export const verifyEmailAPI = async (
  body: { filter: { id: string }; update: { enabled: boolean } },
  token: string
): Promise<TResponseApi & TUpdateUserApiResponse> => {
  const params = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${basePath}/user/update_user`, params);
  const data = await response.json();
  return { response, data };
};

export const acceptTermsAPI = async (
  body: { filter: { id: string }; update: { signed: boolean } },
  token: string
): Promise<TResponseApi & TUpdateUserApiResponse> => {
  const params = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${basePath}/user/update_user`, params);
  const data = await response.json();
  return { response, data };
};

export const changePasswordAPI = async (
  auth0Id: any,
  newPsw: string,
  token: string
): Promise<any> => {
  const params = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      filter: { id: auth0Id },
      update: { password: newPsw },
    }),
  };
  const response = await fetch(`${basePath}/user/update_user_password`, params);
  const data = await response.json();
  return { response, data };
};
