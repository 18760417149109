export const countriesTranslation = {
  'google-address-form_country_AF': 'Afghanistan',
  'google-address-form_country_AX': 'Isole Åland',
  'google-address-form_country_AL': 'Albania',
  'google-address-form_country_DZ': 'Algeria',
  'google-address-form_country_AS': 'Samoa Americane',
  'google-address-form_country_AD': 'Andorra',
  'google-address-form_country_AO': 'Angola',
  'google-address-form_country_AI': 'Anguilla',
  'google-address-form_country_AQ': 'Antartide',
  'google-address-form_country_AG': 'Antigua e Barbuda',
  'google-address-form_country_AR': 'Argentina',
  'google-address-form_country_AM': 'Armenia',
  'google-address-form_country_AW': 'Aruba',
  'google-address-form_country_AU': 'Australia',
  'google-address-form_country_AT': 'Austria',
  'google-address-form_country_AZ': 'Azerbaigian',
  'google-address-form_country_BS': 'Bahamas',
  'google-address-form_country_BH': 'Bahrein',
  'google-address-form_country_BD': 'Bangladesh',
  'google-address-form_country_BB': 'Barbados',
  'google-address-form_country_BY': 'Bielorussia',
  'google-address-form_country_BE': 'Belgio',
  'google-address-form_country_BZ': 'Belize',
  'google-address-form_country_BJ': 'Benin',
  'google-address-form_country_BM': 'Bermuda',
  'google-address-form_country_BT': 'Bhutan',
  'google-address-form_country_BO': 'Bolivia',
  'google-address-form_country_BQ': 'Bonaire, Sint Eustatius e Saba',
  'google-address-form_country_BA': 'Bosnia ed Erzegovina',
  'google-address-form_country_BW': 'Botswana',
  'google-address-form_country_BV': 'Bouvet Island',
  'google-address-form_country_BR': 'Brasile',
  'google-address-form_country_IO': "Territorio britannico dell'Oceano Indiano",
  'google-address-form_country_BN': 'Brunei Darussalam',
  'google-address-form_country_BG': 'Bulgaria',
  'google-address-form_country_BF': 'Burkina Faso',
  'google-address-form_country_BI': 'Burundi',
  'google-address-form_country_CV': 'Capo Verde',
  'google-address-form_country_KH': 'Cambogia',
  'google-address-form_country_CM': 'Camerun',
  'google-address-form_country_CA': 'Canada',
  'google-address-form_country_KY': 'Isole Cayman',
  'google-address-form_country_CF': 'Repubblica Centrafricana',
  'google-address-form_country_TD': 'Ciad',
  'google-address-form_country_CL': 'Cile',
  'google-address-form_country_CN': 'Cina',
  'google-address-form_country_CX': 'Isola di Natale',
  'google-address-form_country_CC': 'Isole Cocos',
  'google-address-form_country_CO': 'Colombia',
  'google-address-form_country_KM': 'Comore',
  'google-address-form_country_CG': 'Congo (Brazzaville)',
  'google-address-form_country_CD': 'Congo (Repubblica Democratica del)',
  'google-address-form_country_CK': 'Isole Cook',
  'google-address-form_country_CR': 'Costa Rica',
  'google-address-form_country_HR': 'Croazia',
  'google-address-form_country_CU': 'Cuba',
  'google-address-form_country_CW': 'Curaçao',
  'google-address-form_country_CY': 'Cipro',
  'google-address-form_country_CZ': 'Cechia',
  'google-address-form_country_DK': 'Danimarca',
  'google-address-form_country_DJ': 'Gibuti',
  'google-address-form_country_DM': 'Dominica',
  'google-address-form_country_DO': 'Repubblica Dominicana',
  'google-address-form_country_EC': 'Ecuador',
  'google-address-form_country_EG': 'Egitto',
  'google-address-form_country_SV': 'El Salvador',
  'google-address-form_country_GQ': 'Guinea Equatoriale',
  'google-address-form_country_ER': 'Eritrea',
  'google-address-form_country_EE': 'Estonia',
  'google-address-form_country_SZ': 'Eswatini',
  'google-address-form_country_ET': 'Etiopia',
  'google-address-form_country_FK': 'Isole Falkland (Malvine)',
  'google-address-form_country_FO': 'Isole Fær Øer',
  'google-address-form_country_FJ': 'Figi',
  'google-address-form_country_FI': 'Finlandia',
  'google-address-form_country_FR': 'Francia',
  'google-address-form_country_GF': 'Guiana Francese',
  'google-address-form_country_PF': 'Polinesia Francese',
  'google-address-form_country_TF': 'Terre Australi Francesi',
  'google-address-form_country_GA': 'Gabon',
  'google-address-form_country_GM': 'Gambia',
  'google-address-form_country_GE': 'Georgia',
  'google-address-form_country_DE': 'Germania',
  'google-address-form_country_GH': 'Ghana',
  'google-address-form_country_GI': 'Gibilterra',
  'google-address-form_country_GR': 'Grecia',
  'google-address-form_country_GL': 'Groenlandia',
  'google-address-form_country_GD': 'Grenada',
  'google-address-form_country_GP': 'Guadalupa',
  'google-address-form_country_GU': 'Guam',
  'google-address-form_country_GT': 'Guatemala',
  'google-address-form_country_GG': 'Guernsey',
  'google-address-form_country_GN': 'Guinea',
  'google-address-form_country_GW': 'Guinea-Bissau',
  'google-address-form_country_GY': 'Guyana',
  'google-address-form_country_HT': 'Haiti',
  'google-address-form_country_HM': 'Isole Heard e McDonald',
  'google-address-form_country_VA': 'Santa Sede',
  'google-address-form_country_HN': 'Honduras',
  'google-address-form_country_HK': 'Hong Kong',
  'google-address-form_country_HU': 'Ungheria',
  'google-address-form_country_IS': 'Islanda',
  'google-address-form_country_IN': 'India',
  'google-address-form_country_ID': 'Indonesia',
  'google-address-form_country_IR': 'Iran',
  'google-address-form_country_IQ': 'Iraq',
  'google-address-form_country_IE': 'Irlanda',
  'google-address-form_country_IM': 'Isola di Man',
  'google-address-form_country_IL': 'Israele',
  'google-address-form_country_IT': 'Italia',
  'google-address-form_country_JM': 'Giamaica',
  'google-address-form_country_JP': 'Giappone',
  'google-address-form_country_JE': 'Jersey',
  'google-address-form_country_JO': 'Giordania',
  'google-address-form_country_KZ': 'Kazakistan',
  'google-address-form_country_KE': 'Kenya',
  'google-address-form_country_KI': 'Kiribati',
  'google-address-form_country_KP': 'Corea del Nord',
  'google-address-form_country_KR': 'Corea del Sud',
  'google-address-form_country_KW': 'Kuwait',
  'google-address-form_country_KG': 'Kirghizistan',
  'google-address-form_country_LA': 'Laos',
  'google-address-form_country_LV': 'Lettonia',
  'google-address-form_country_LB': 'Libano',
  'google-address-form_country_LS': 'Lesotho',
  'google-address-form_country_LR': 'Liberia',
  'google-address-form_country_LY': 'Libia',
  'google-address-form_country_LI': 'Liechtenstein',
  'google-address-form_country_LT': 'Lituania',
  'google-address-form_country_LU': 'Lussemburgo',
  'google-address-form_country_MO': 'Macao',
  'google-address-form_country_MG': 'Madagascar',
  'google-address-form_country_MW': 'Malawi',
  'google-address-form_country_MY': 'Malesia',
  'google-address-form_country_MV': 'Maldive',
  'google-address-form_country_ML': 'Mali',
  'google-address-form_country_MT': 'Malta',
  'google-address-form_country_MH': 'Isole Marshall',
  'google-address-form_country_MQ': 'Martinica',
  'google-address-form_country_MR': 'Mauritania',
  'google-address-form_country_MU': 'Mauritius',
  'google-address-form_country_YT': 'Mayotte',
  'google-address-form_country_MX': 'Messico',
  'google-address-form_country_FM': 'Micronesia',
  'google-address-form_country_MD': 'Moldavia',
  'google-address-form_country_MC': 'Monaco',
  'google-address-form_country_MN': 'Mongolia',
  'google-address-form_country_ME': 'Montenegro',
  'google-address-form_country_MS': 'Montserrat',
  'google-address-form_country_MA': 'Marocco',
  'google-address-form_country_MZ': 'Mozambico',
  'google-address-form_country_MM': 'Myanmar',
  'google-address-form_country_NA': 'Namibia',
  'google-address-form_country_NR': 'Nauru',
  'google-address-form_country_NP': 'Nepal',
  'google-address-form_country_NL': 'Paesi Bassi',
  'google-address-form_country_NC': 'Nuova Caledonia',
  'google-address-form_country_NZ': 'Nuova Zelanda',
  'google-address-form_country_NI': 'Nicaragua',
  'google-address-form_country_NE': 'Niger',
  'google-address-form_country_NG': 'Nigeria',
  'google-address-form_country_NU': 'Niue',
  'google-address-form_country_NF': 'Isola Norfolk',
  'google-address-form_country_MK': 'Macedonia del Nord',
  'google-address-form_country_MP': 'Isole Marianne Settentrionali',
  'google-address-form_country_NO': 'Norvegia',
  'google-address-form_country_OM': 'Oman',
  'google-address-form_country_PK': 'Pakistan',
  'google-address-form_country_PW': 'Palau',
  'google-address-form_country_PS': 'Palestina',
  'google-address-form_country_PA': 'Panamá',
  'google-address-form_country_PG': 'Papua Nuova Guinea',
  'google-address-form_country_PY': 'Paraguay',
  'google-address-form_country_PE': 'Perù',
  'google-address-form_country_PH': 'Filippine',
  'google-address-form_country_PN': 'Isole Pitcairn',
  'google-address-form_country_PL': 'Polonia',
  'google-address-form_country_PT': 'Portogallo',
  'google-address-form_country_PR': 'Porto Rico',
  'google-address-form_country_QA': 'Qatar',
  'google-address-form_country_RE': 'Riunione',
  'google-address-form_country_RO': 'Romania',
  'google-address-form_country_RU': 'Russia',
  'google-address-form_country_RW': 'Ruanda',
  'google-address-form_country_BL': 'Saint-Barthélemy',
  'google-address-form_country_SH': "Sant'Elena",
  'google-address-form_country_KN': 'Saint Kitts e Nevis',
  'google-address-form_country_LC': 'Santa Lucia',
  'google-address-form_country_MF': 'Saint-Martin',
  'google-address-form_country_PM': 'Saint-Pierre e Miquelon',
  'google-address-form_country_VC': 'Saint Vincent e Grenadine',
  'google-address-form_country_WS': 'Samoa',
  'google-address-form_country_SM': 'San Marino',
  'google-address-form_country_ST': 'Sao Tomé e Príncipe',
  'google-address-form_country_SA': 'Arabia Saudita',
  'google-address-form_country_SN': 'Senegal',
  'google-address-form_country_RS': 'Serbia',
  'google-address-form_country_SC': 'Seychelles',
  'google-address-form_country_SL': 'Sierra Leone',
  'google-address-form_country_SG': 'Singapore',
  'google-address-form_country_SX': 'Saint-Martin (parte olandese)',
  'google-address-form_country_SK': 'Slovacchia',
  'google-address-form_country_SI': 'Slovenia',
  'google-address-form_country_SB': 'Isole Salomone',
  'google-address-form_country_SO': 'Somalia',
  'google-address-form_country_ZA': 'Sudafrica',
  'google-address-form_country_GS': 'Georgia del Sud e Isole Sandwich Australi',
  'google-address-form_country_SS': 'Sudan del Sud',
  'google-address-form_country_ES': 'Spagna',
  'google-address-form_country_LK': 'Sri Lanka',
  'google-address-form_country_SD': 'Sudan',
  'google-address-form_country_SR': 'Suriname',
  'google-address-form_country_SJ': 'Svalbard e Jan Mayen',
  'google-address-form_country_SE': 'Svezia',
  'google-address-form_country_CH': 'Svizzera',
  'google-address-form_country_SY': 'Siria',
  'google-address-form_country_TW': 'Taiwan',
  'google-address-form_country_TJ': 'Tagikistan',
  'google-address-form_country_TZ': 'Tanzania',
  'google-address-form_country_TH': 'Thailandia',
  'google-address-form_country_TL': 'Timor Est',
  'google-address-form_country_TG': 'Togo',
  'google-address-form_country_TK': 'Tokelau',
  'google-address-form_country_TO': 'Tonga',
  'google-address-form_country_TT': 'Trinidad e Tobago',
  'google-address-form_country_TN': 'Tunisia',
  'google-address-form_country_TR': 'Turchia',
  'google-address-form_country_TM': 'Turkmenistan',
  'google-address-form_country_TC': 'Isole Turks e Caicos',
  'google-address-form_country_TV': 'Tuvalu',
  'google-address-form_country_UG': 'Uganda',
  'google-address-form_country_UA': 'Ucraina',
  'google-address-form_country_AE': 'Emirati Arabi Uniti',
  'google-address-form_country_GB': 'Regno Unito',
  'google-address-form_country_US': 'Stati Uniti',
  'google-address-form_country_UY': 'Uruguay',
  'google-address-form_country_UZ': 'Uzbekistan',
  'google-address-form_country_VU': 'Vanuatu',
  'google-address-form_country_VE': 'Venezuela',
  'google-address-form_country_VN': 'Vietnam',
  'google-address-form_country_EH': 'Sahara Occidentale',
  'google-address-form_country_YE': 'Yemen',
  'google-address-form_country_ZM': 'Zambia',
  'google-address-form_country_ZW': 'Zimbabwe',
};
