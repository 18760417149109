import { useReducer, useMemo, useCallback, memo, useEffect } from 'react';
import NotificationsContext from './NotificationsContext';
import initialNotificationsState from './initialNotificationsState';
import userReducer from 'contexts/notifications/reducer/notifications.reducer';
import * as action from 'contexts/notifications/reducer/notifications.actions';
import { useTranslation } from 'react-i18next';
import { ChildrenProps } from 'interfaces/general.interface';

function NotificationsProvider(props: ChildrenProps) {
  const [notifications, dispatch] = useReducer(userReducer, initialNotificationsState);
  const { t: translate } = useTranslation();

  useEffect(() => {
    action.getNotificationsAction(dispatch, translate);
  }, [translate]);

  const markAsReaded = useCallback(
    (type: string, notificationId: string, read: boolean) => {
      action.markAsReadedAction(dispatch, type, notificationId, read, translate);
    },
    [translate]
  );

  const memoProvider = useMemo(
    () => ({
      ...notifications,
      markAsReaded,
    }),
    [notifications, markAsReaded]
  );

  return (
    <NotificationsContext.Provider value={memoProvider}>
      {props.children}
    </NotificationsContext.Provider>
  );
}

export default memo(NotificationsProvider);
