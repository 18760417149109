import { ICatalogState, TQuerySearchArray } from 'interfaces/catalog.interface';
import * as CatalogTypes from './catalog.types';

export default function catalogReducer(state: ICatalogState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case CatalogTypes.GET_CATALOG:
      const getCatalog: ICatalogState = {
        ...state,
        catalogItems: payload,
      };
      return getCatalog;

    case CatalogTypes.SET_RELATED_PRODUCTS:
      const setRelatedProducts: ICatalogState = {
        ...state,
        relatedProducts: payload,
      };
      return setRelatedProducts;

    case CatalogTypes.ADD_MORE_TO_CATALOG:
      const addMoreToCatalog: ICatalogState = {
        ...state,
        catalogItems: {
          ...state.catalogItems,
          data: state.catalogItems.data.concat(payload.data),
        },
      };
      return addMoreToCatalog;

    case CatalogTypes.GET_PRODUCT:
      const getProduct: ICatalogState = {
        ...state,
        productItem: payload,
      };
      return getProduct;

    case CatalogTypes.SET_IS_LOADING:
      const setIsLoading: ICatalogState = {
        ...state,
        isLoading: payload,
      };
      return setIsLoading;

    case CatalogTypes.SET_IS_LOADING_MORE_PRODUCTS:
      const setIsLoadingMoreProducts: ICatalogState = {
        ...state,
        isLoadingMoreProducts: payload,
      };
      return setIsLoadingMoreProducts;

    case CatalogTypes.SET_IS_LOADING_RELATED_PRODUCTS:
      const setIsLoadingRelatedProducts: ICatalogState = {
        ...state,
        isLoadingRelatedProducts: payload,
      };
      return setIsLoadingRelatedProducts;

    case CatalogTypes.RESET_PRICE_SLIDER:
      const resetPriceSlider: ICatalogState = {
        ...state,
        bodyProductSearch: {
          ...state.bodyProductSearch,
          filter: {
            ...state.bodyProductSearch.filter,
            [payload.type]: [],
          },
        },
        bodyProductSearchTemp: {
          ...state.bodyProductSearchTemp,
          filter: {
            ...state.bodyProductSearchTemp.filter,
            [payload.type]: [],
          },
        },
      };
      return resetPriceSlider;

    case CatalogTypes.RESET_TEMP_BODY_FILTERS:
      const resetTempBodyFilters: ICatalogState = {
        ...state,
        bodyProductSearchTemp: state.bodyProductSearch,
        filtersLengthsTemp: state.filtersLengths,
      };
      return resetTempBodyFilters;

    case CatalogTypes.SET_BODY_PRODUCT:
      const setBodyProduct: ICatalogState = {
        ...state,
        bodyProductSearchTemp: {
          ...state.bodyProductSearchTemp,
          options: {
            ...state.bodyProductSearchTemp.options,
            brand_id: payload.brandId,
            client_id: payload.clientId,
            limit: payload.limit,
            language_id: payload.languageId,
          },
          filter: {
            reference: [],
            color: [],
            season: [],
            segmentation: [],
            division: [],
            family: [],
            gender: [],
            pvi: [],
            pvpr: [],
            tag: [],
            tier: [],
          },
        },
        querysearchArray: [],
        filtersLengths: {
          brand: payload.brandId.length,
          color: 0,
          gender: 0,
          division: 0,
          family: 0,
          season: 0,
        },
      };
      return setBodyProduct;

    case CatalogTypes.SET_BODY_PRODUCT_SEARCH:
      const setBodyProductSearch: ICatalogState = {
        ...state,
        bodyProductSearch: payload,
        filtersLengths: {
          brand: payload.options.brand_id.length,
          color: state.filtersLengthsTemp.color,
          gender: state.filtersLengthsTemp.gender,
          division: state.filtersLengthsTemp.division,
          family: state.filtersLengthsTemp.family,
          season: state.filtersLengthsTemp.season,
        },
      };
      return setBodyProductSearch;

    case CatalogTypes.SET_BODY_PRODUCT_SEARCH_TEMP:
      const setBodyProductSearchTemp: ICatalogState = {
        ...state,
        bodyProductSearchTemp: payload,
      };
      return setBodyProductSearchTemp;

    case CatalogTypes.SET_QUERY_SEARCH:
      const setQuerySearch: ICatalogState = {
        ...state,
        querySearch: payload,
      };
      return setQuerySearch;

    case CatalogTypes.INCREMENT_PAGINATION_INDEX:
      const incrementPaginationIndex: ICatalogState = {
        ...state,
        bodyProductSearchTemp: {
          ...state.bodyProductSearchTemp,
          options: {
            ...state.bodyProductSearchTemp.options,
            index: payload,
          },
        },
      };
      return incrementPaginationIndex;

    case CatalogTypes.SET_PRICE_SLIDERS:
      const setPriceSliders: ICatalogState = {
        ...state,
        bodyProductSearchTemp: {
          ...state.bodyProductSearchTemp,
          filter: {
            ...state.bodyProductSearchTemp.filter,
            [payload.field]: payload.value,
          },
        },
      };
      return setPriceSliders;

    case CatalogTypes.ADD_BODY_FILTERS:
      const addBodyFilters: ICatalogState = {
        ...state,
        bodyProductSearchTemp: payload.bodyProductSearchTemp,
        filtersLengthsTemp: {
          ...state.filtersLengthsTemp,
          [payload.field]:
            state.filtersLengthsTemp[
              payload.field as keyof typeof state.filtersLengthsTemp
            ] + 1,
        },
      };
      return addBodyFilters;

    case CatalogTypes.REMOVE_BODY_FILTERS:
      const removeBodyFilters: ICatalogState = {
        ...state,
        bodyProductSearchTemp: payload.bodyProductSearchTemp,
        filtersLengthsTemp: {
          ...state.filtersLengthsTemp,
          [payload.field]:
            state.filtersLengthsTemp[
              payload.field as keyof typeof state.filtersLengthsTemp
            ] - 1,
        },
      };
      return removeBodyFilters;

    case CatalogTypes.ADD_BODY_BRAND_OPTIONS:
      const addBodyBrandOptions: ICatalogState = {
        ...state,
        bodyProductSearchTemp: payload.bodyProductSearchTemp,
        filtersLengths: {
          ...state.filtersLengths,
          brand: state.filtersLengths.brand + 1,
        },
      };
      return addBodyBrandOptions;

    case CatalogTypes.REMOVE_BODY_BRAND_OPTIONS:
      const removeBodyBrandOptions: ICatalogState = {
        ...state,
        bodyProductSearchTemp: payload.bodyProductSearchTemp,
        filtersLengths: {
          ...state.filtersLengths,
          brand: state.filtersLengths.brand - 1,
        },
      };
      return removeBodyBrandOptions;

    case CatalogTypes.RESET_BODY_FILTERS:
      const resetBodyFilters: ICatalogState = {
        ...state,
        bodyProductSearch: {
          ...state.bodyProductSearch,
          options: {
            ...state.bodyProductSearch.options,
            favorite: '',
            // search: [""],
            index: 0,
          },
          filter: {
            ...state.bodyProductSearch.filter,
            color: [],
            season: [],
            segmentation: [],
            division: [],
            family: [],
            gender: [],
            pvi: [],
            pvpr: [],
            tag: [],
            tier: [],
          },
        },
        bodyProductSearchTemp: {
          ...state.bodyProductSearchTemp,
          options: {
            ...state.bodyProductSearchTemp.options,
            favorite: '',
            // search: [""],
            index: 0,
          },
          filter: {
            ...state.bodyProductSearchTemp.filter,
            color: [],
            season: [],
            segmentation: [],
            division: [],
            family: [],
            gender: [],
            pvi: [],
            pvpr: [],
            tag: [],
            tier: [],
          },
        },
        filtersLengths: {
          brand: 0,
          color: 0,
          gender: 0,
          division: 0,
          family: 0,
          season: 0,
        },
        filtersLengthsTemp: {
          brand: 0,
          color: 0,
          gender: 0,
          division: 0,
          family: 0,
          season: 0,
        },
        querysearchArray: [],
      };
      return resetBodyFilters;

    case CatalogTypes.SET_THERE_ARE_MORE_PRODUCTS:
      const setThereAreMoreProducts: ICatalogState = {
        ...state,
        thereAreMoreProducts: payload,
      };
      return setThereAreMoreProducts;

    case CatalogTypes.SET_IS_EMPTY_PRODUCTS:
      const setIsEmptyProducts: ICatalogState = {
        ...state,
        isEmptyProducts: payload,
      };
      return setIsEmptyProducts;

    case CatalogTypes.SET_SORT_BY:
      const setSortBy: ICatalogState = {
        ...state,
        bodyProductSearchTemp: {
          ...state.bodyProductSearchTemp,
          options: {
            ...state.bodyProductSearchTemp.options,
            order: [payload],
          },
        },
      };
      return setSortBy;

    case CatalogTypes.SET_BRANDS_PRODUCTS:
      const setBrandsProducts: ICatalogState = {
        ...state,
        bodyProductSearchTemp: {
          ...state.bodyProductSearchTemp,
          options: {
            ...state.bodyProductSearchTemp.options,
            brand_id: payload,
          },
        },
      };
      return setBrandsProducts;

    case CatalogTypes.SET_BRAND_SELECTED:
      const setBrandSelected: ICatalogState = {
        ...state,
        brandSelected: payload,
      };
      return setBrandSelected;

    case CatalogTypes.SET_FAVORITES:
      const setFavorites: ICatalogState = {
        ...state,
        bodyProductSearchTemp: {
          ...state.bodyProductSearchTemp,
          options: {
            ...state.bodyProductSearchTemp.options,
            favorite: payload,
          },
        },
      };
      return setFavorites;

    case CatalogTypes.SET_FILTERS_LENGTHS:
      const setFiltersLengths: ICatalogState = {
        ...state,
        filtersLengthsTemp: payload,
      };
      return setFiltersLengths;

    case CatalogTypes.SET_PRODUCT_BRAND_ID:
      const setProductBrandId: ICatalogState = {
        ...state,
        productItem: {
          ...state.productItem,
          brand_id: payload,
        },
      };
      return setProductBrandId;

    case CatalogTypes.SET_IS_LOADING_PRODUCT:
      const setIsLoadingProduct: ICatalogState = {
        ...state,
        isLoadingProduct: payload,
      };
      return setIsLoadingProduct;

    case CatalogTypes.ADD_TO_QUERY_SEARCH_ARRAY:
      const addToQuerySearchArray: ICatalogState = {
        ...state,
        querysearchArray: !state.querysearchArray.includes(payload)
          ? [...state.querysearchArray, payload]
          : state.querysearchArray,
      };
      return addToQuerySearchArray;

    case CatalogTypes.REMOVE_FROM_QUERY_SEARCH_ARRAY:
      const removeFromQuerySearchArray: ICatalogState = {
        ...state,
        querysearchArray: state.querysearchArray.filter(
          (item) => item.tag !== payload.tag
        ),
      };
      return removeFromQuerySearchArray;

    case CatalogTypes.SET_QUERY_SEARCH_ARRAY:
      const setQuerySearchArray: ICatalogState = {
        ...state,
        querysearchArray: payload,
      };
      return setQuerySearchArray;

    case CatalogTypes.SET_BODY_SEARCH_FILTER:
      const setBodySearchFilter: ICatalogState = {
        ...state,
        querysearchArray: (payload.querySearchArray as TQuerySearchArray[]).filter(
          (item) => item.tag !== payload.value.tag
        ),
        filtersLengthsTemp: {
          ...state.filtersLengthsTemp,
          [payload.field]:
            state.filtersLengthsTemp[
              payload.field as keyof typeof state.filtersLengthsTemp
            ] - 1,
        },
      };
      return setBodySearchFilter;

    case CatalogTypes.ADD_LIKE_TO_PRODUCT:
      const newObj = {
        favorite_id: payload.id,
        user_id: payload.user_id,
      };
      const addLikeToProduct: ICatalogState = {
        ...state,
        productItem: {
          ...state.productItem,
          favorite: [...state.productItem.favorite, newObj],
        },
      };
      return addLikeToProduct;

    case CatalogTypes.REMOVE_LIKE_TO_PRODUCT:
      const removeLikeToProduct: ICatalogState = {
        ...state,
        productItem: {
          ...state.productItem,
          favorite: state.productItem.favorite.filter((item) => item.user_id !== payload),
        },
      };
      return removeLikeToProduct;

    case CatalogTypes.RESET_CATALOG_ITEMS:
      const resetCatalogItems: ICatalogState = {
        ...state,
        catalogItems: {
          count: 0,
          data: [],
        },
        thereAreMoreProducts: false,
      };
      return resetCatalogItems;

    default:
      return state;
  }
}
