import {
  TcreateSftpUserAPIProps,
  TcreateSftpUserAPIResponse,
  TcreateShopifyConnectionAPIProps,
  TcreateShopifyConnectionAPIResponse,
  TdeleteSftpUserAPIProps,
  TdeleteSftpUserAPIResponse,
  // TgetConnectorsAPIProps,
  // TgetConnectorsAPIResponse,
  TgetSftpUserAPIProps,
  TgetSftpUserAPIResponse,
  TupdateShopifyConnectionAPIProps,
  TupdateShopifyConnectionAPIResponse,
} from './responseInterfaces/meta.response.interface';
import { IGetSalesMappingKeysAPIResponse } from './responseInterfaces/sales.response.interface';
import { basePath, basePathSftpService, dataBasePath } from './utils/config';

// export const getConnectionsAPI = async ({
//   body,
//   token,
// }: TgetConnectorsAPIProps): Promise<TgetConnectorsAPIResponse> => {
//   const params = {
//     method: 'POST',
//     headers: {
//       Authorization: `Bearer ${token}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(body),
//   };
//   const response = await fetch(`${dataBasePath}/connectors/search`, params);
//   const data = await response.json();
//   return { response, data };
// };

// SFTP ///////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////

export const getSftpUserAPI = async ({
  // SFTP ///////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////
  body,
  token,
}: TgetSftpUserAPIProps): Promise<TgetSftpUserAPIResponse> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${basePath}/sftp/get_users`, params);
  const data = await response.json();
  return { response, data };
};

export const createSftpUserAPI = async ({
  body,
  token,
}: TcreateSftpUserAPIProps): Promise<TcreateSftpUserAPIResponse> => {
  const params = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${basePathSftpService}/cli/create_sftp_user`, params);
  const data = await response.json();
  return { response, data };
};

export const deleteSftpUserAPI = async ({
  user_name,
  token,
}: TdeleteSftpUserAPIProps): Promise<TdeleteSftpUserAPIResponse> => {
  const params = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${basePathSftpService}/cli/delete_sftp_user?user_name=${user_name}`,
    params
  );
  const data = await response.json();
  return { response, data };
};
//////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////

// Shopify ///////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////
export const createShopifyConnectionAPI = async ({
  body,
  token,
}: TcreateShopifyConnectionAPIProps): Promise<TcreateShopifyConnectionAPIResponse> => {
  const params = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(
    `${dataBasePath}/shopify_connector/create_connector`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const updateShopifyConnectionAPI = async ({
  body,
  token,
}: TupdateShopifyConnectionAPIProps): Promise<TupdateShopifyConnectionAPIResponse> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(``, params);
  const data = await response.json();
  return { response, data };
};
//////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////

export const getProductsMappingKeysAPI = async (
  token: string
): Promise<IGetSalesMappingKeysAPIResponse> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${basePath}/csv_mapping/get_catalog_keys`, params);
  const data = await response.json();
  return { response, data };
};
