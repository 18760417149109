import { useContext } from 'react';
import SuperAdminContext from 'contexts/superAdmin/SuperAdminContext';

export default function useContextSuperAdmin() {
  const context = useContext(SuperAdminContext);
  if (context === undefined) {
    throw new Error('useContextSuperAdmin must be used within a SuperAdminProvider');
  }
  return context;
}
