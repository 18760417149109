import { IBrandsBodyFetchState, IInitialBrandsState } from 'interfaces/brands.interface';
import * as BrandsTypes from './brands.types';
import * as api from 'api/myBrands.api';
// import { myBrandsError } from 'utils/errorCodes';
// import { notificationError } from 'views/components/UI/notification';
// import { messageError, messageSuccess, messageWarning } from 'views/components/UI/message';

export async function getPaginationBrandsAction(
  dispatch: any,
  bodyFetch: IBrandsBodyFetchState,
  token: string,
  translate: any
) {
  try {
    const brandsFetch = await api.getBrandsAPI(bodyFetch, token);
    if (brandsFetch.response.status === 404) {
      dispatch({ type: BrandsTypes.SET_THERE_ARE_MORE_BRANDS, payload: false });
      return dispatch({ type: BrandsTypes.GET_PAGINATION_BRANDS, payload: [] });
    }
    if (brandsFetch.response.status === 200) {
      for (const [index, brand] of brandsFetch.data.entries()) {
        brand.logo = `${brand.logo}?t=${new Date().getTime()}`;
        brandsFetch.data[index] = brand;
      }
      return dispatch({
        type: BrandsTypes.GET_PAGINATION_BRANDS,
        payload: brandsFetch.data,
      });
    }
  } catch (err) {
    console.log(err);
  } finally {
    dispatch({ type: BrandsTypes.SET_IS_LOADING_MORE_BRANDS, payload: false });
  }
}

export async function getAllBrandsAction({ dispatch, token, translate }: any) {
  try {
    const brandsFetch = await api.getBrandsAPI(
      {
        search: '',
        index: 0,
        limit: 10000,
      },
      token
    );
    if (brandsFetch.response.status === 404) return;
    if (brandsFetch.response.status === 200) {
      for (const [index, brand] of brandsFetch.data.entries()) {
        brand.logo = `${brand.logo}?t=${new Date().getTime()}`;
        brandsFetch.data[index] = brand;
      }
      return dispatch({
        type: BrandsTypes.GET_ALL_BRANDS,
        payload: brandsFetch.data,
      });
    }
  } catch (err) {
    console.log(err);
  } finally {
    dispatch({ type: BrandsTypes.SET_IS_LOADING, payload: false });
  }
}

export async function addMoreBrandsAction(
  dispatch: any,
  brands: IInitialBrandsState,
  token: string,
  translate: any
) {
  dispatch({ type: BrandsTypes.SET_IS_LOADING_MORE_BRANDS, payload: true });
  try {
    const brandsFetch = await api.getBrandsAPI(brands.bodyFetch, token);
    if (brandsFetch.response.status === 404) {
      dispatch({
        type: BrandsTypes.SET_THERE_ARE_MORE_BRANDS,
        payload: false,
      });
    }
    if (brandsFetch.response.status === 200) {
      for (const [index, brand] of brandsFetch.data.entries()) {
        brand.logo = `${brand.logo}?t=${new Date().getTime()}`;
        brandsFetch.data[index] = brand;
      }
      return dispatch({
        type: BrandsTypes.ADD_MORE_BRANDS,
        payload: {
          data: brandsFetch.data,
          index: brands.bodyFetch.index,
        },
      });
    }
  } catch (err) {
    console.log(err);
  } finally {
    dispatch({ type: BrandsTypes.SET_IS_LOADING_MORE_BRANDS, payload: false });
  }
}

export async function setBodySearchAction(
  dispatch: any,
  query: string,
  bodyFetch: IBrandsBodyFetchState,
  token: string,
  translate: any
) {
  bodyFetch.index = 0;
  if (query.length === 0) {
    bodyFetch.limit = 40;
    bodyFetch.search = '';
    dispatch({ type: BrandsTypes.SET_THERE_ARE_MORE_BRANDS, payload: true });
    getPaginationBrandsAction(dispatch, bodyFetch, token, translate);
    return dispatch({ type: BrandsTypes.RESET_BODY_SEARCH });
  }
  if (query.length >= 2) {
    bodyFetch.limit = 1000;
    bodyFetch.search = query;
    getPaginationBrandsAction(dispatch, bodyFetch, token, translate);
  }
  return dispatch({ type: BrandsTypes.SET_BODY_SEARCH, payload: query });
}
