import { countriesTranslation } from 'assets/locale/fr/core/countriesTranslation';

const franceTranslation = {
  ...countriesTranslation,
  // HEADER //////////////////
  // Nav
  header_nav_home: 'Accueil',
  header_nav_dashboard: 'Tableau de bord',
  header_nav_catalog: 'Catalogue',
  'header_nav_catalog-download': 'Télécharger le catalogue',
  header_nav_multimedia: 'Multimédia',
  'header_nav_my-brands': 'Mes Marques',
  'header_sub-nav_view-catalog': 'Voir le catalogue',
  'header_sub-nav_add-product': 'Ajouter un produit',
  'header_sub-nav_multimedia-catalog': 'Catalogue multimédia',
  'header_sub-nav_multimedia-catalog-videos': 'Vidéos',
  'header_sub-nav_multimedia-catalog-images': 'Images',
  'header_sub-nav_multimedia-catalog-banners': 'Bannières',
  'header_sub-nav_my-brands-list': 'Liste des Marques',
  'header_sub-nav_add-brand': 'Ajouter une Marque',
  header_nav_sales: 'Ventes',
  'header_nav_sales-history': 'Historique des téléchargements',
  'header_nav_sales-create': 'Télécharger des ventes',
  // Notifications
  'header_notifications_empty-notifications': "Il n'y a pas de notifications",
  ////////////////////////////

  // SIGNUP PAGE /////////////
  'signup-page_title': "S'inscrire à :",
  'signup-page_sub-title': 'Inscrivez-vous pour développer votre entreprise',
  'signup-page_button-brand': "S'inscrire en tant que marque",
  'signup-page_button-multibrand': "S'inscrire en tant que multimarque",
  'signup-page_button-brand-wh': 'M’inscrire en tant que marque ou multimarque',
  'signup-page_return-to-login': 'Retour à la page de connexion',
  ////////////////////////////

  // HOME PAGE ///////////////
  'home-page_brands_my-brands_title': 'Mes Marques',
  'home-page_brands_item_button-see-catalog': 'Voir le Catalogue',
  'home-page_brands_my-brands_my-brands-link': 'Aller à Mes Marques',
  'home-page_brands_all-brands_title': 'Marques sur HUB',
  'home-page_brands_all-brands_see-more': 'Voir tout',
  'home-page_brands_all-brands_see-less': 'Voir moins',
  'home-page_brands_item_button-connect': 'Connecter',
  'home-page_brands_item_button-see-profile': 'Voir le profil',
  'home-page_brands_item_button-pending': 'En attente',
  'home-page_brands_item_button-disconnected': 'Déconnecté',
  'home-page_brands_item_empty-brands': "Pas encore d'associations",
  'home-page_brands_see_more': 'Voir plus',
  'home-page_brands_see_brand': 'Voir le Profil',
  'home-page_brands_no_more_results': 'Aucun résultat trouvé...',
  'home-page_brands_connect-modal_title': 'La demande a été envoyée à la marque!',
  'home-page_brands_connect-modal_text1':
    "Hub4Retail prendra contact avec la marque pour l'associer.",
  'home-page_brands_connect-modal_text2':
    'Nous vous enverrons un email pour vous mettre à jour.',
  'home-page_brands-not-associated_title': 'Marques que vous vendez non associées',
  'home-page_brands-not-associated_search-input-placeholder':
    'Rechercher dans les marques non associées',
  'home-page_brands-not-associated_selected-brand': 'marque sélectionnée',
  'home-page_brands-not-associated_selected-brands': 'marques sélectionnées',
  'home-page_brands-not-associated_select-all': 'Sélectionner tout',
  'home-page_brands-not-associated_clear-select': 'Effacer la sélection',
  'home-page_brands-not-associated_associate-btn': 'Associer des marques',
  'home-page_my-brands_search-input-placeholder': 'Rechercher dans mes marques',
  ////////////////////////////

  // ACCOUNT PAGE /////////////
  'profile-page_nav_profile': 'Profil',
  'profile-page_nav_company': "Informations de l'entreprise",
  'profile-page_nav_main-contacts': 'Contacts principaux',
  'profile-page_nav_my-brands': 'Mes Marques',
  'profile-page_nav_points-of-sale': 'Points de Vente',
  /////////////////////////////

  // DASHBOARDS //////////
  'dashboard_empty-dashboard': 'Contactez Hub4Retail pour activer ce service.',
  dashboard_dashboard_error:
    'Erreur de chargement du tableau de bord. Si le problème persiste, contactez :',
  dashboard_maintenance_message: 'Nous travaillons pour vous !!',
  //////////////////////////////

  // HELMET ///////////////////
  signup: "S'inscrire",
  home: 'Accueil',
  dashboard: 'Tableau de bord',
  multimedia: 'Multimédia',
  'multimedia-videos': 'Vidéos multimédias',
  'multimedia-images': 'Images multimédias',
  'multimedia-banners': 'Bannières multimédia',
  clients: 'Clients',
  profile: 'Profil',
  company: "Informations de l'entreprise",
  'main-contacts': 'Contacts principaux',
  'commercial-info': 'Informations commerciales',
  'points-of-sale': 'Points de vente',
  catalog: 'Catalogue',
  catalog_download: 'Télécharger le catalogue',
  notifications: 'Notifications',
  helmet_settings: 'Paramètres',
  helmet_meta: 'Meta',
  helmet_users: 'Utilisateurs',
  'helmet_sales-history': 'Historique des ventes',
  'helmet_upload-sales': 'Télécharger les ventes',
  /////////////////////////////

  // USERS ///////////////////////
  // Departments
  users_department_commercial: 'Commercial',
  users_department_administration: 'Administration',
  users_department_agent: 'Agent',
  users_department_owner: 'Propriétaire',
  'users_department_store-manager': 'Responsable de magasin',
  users_department_logistics: 'Logistique',
  users_department_marketing: 'Marketing',
  users_department_others: 'Autres',
  ////////////////////////////////

  // SUPERADMIN //////////////////
  'super-admin_search-input_placeholder': 'Rechercher...',
  ////////////////////////////////

  // ACCOUNT //////////////////
  account_profile_title: 'Mon profil',
  'account-info-panel_title': 'Informations générales',
  'account-company-info-panel_title': "Données de l'entreprise",
  // Profile
  'account-info-panel_profile_name': 'Nom',
  'account-info-panel_profile_lastname': 'Nom de famille',
  'account-info-panel_profile_email': 'Email',
  'account-info-panel_profile_phone-number': 'Numéro de téléphone',
  'account-info-panel_profile_sector': 'Département',
  'account-info-panel_profile_security-data': 'Données de sécurité',
  'account-info-panel_profile_change-password': 'Changer le mot de passe',
  'account-info-panel_profile_pwd_password-label': 'Nouveau mot de passe',
  'account-info-panel_profile_pwd_repeat-password-label':
    'Répétez le nouveau mot de passe',
  'account-info-panel_profile_pwd_change-button': 'Changer le mot de passe',
  'account-info-panel_profile_pwd_validation':
    'Le mot de passe doit comporter au minimum 8 caractères et au maximum 16, 1 numéro, 1 lettre majuscule et une minuscule.',
  'account-info-panel_profile_pwd_validation-match':
    'Les mots de passe ne correspondent pas',
  'account-info-panel_profile_pwd_change-success': 'Mot de passe changé avec succès.',
  'account-info-panel_profile_pwd_empty-inputs': 'Remplissez les champs vides.',
  // Profile form
  'account-profile_form-input_name': 'Entrez votre nom',
  'account-profile_form-input_name-validation': 'Veuillez entrer votre nom',
  'account-profile_form-input_lastname': 'Entrez votre nom de famille',
  'account-profile_form-input_lastname-validation':
    'Veuillez entrer votre nom de famille',
  'account-profile_form-input_email': 'Entrez votre adresse e-mail',
  'account-profile_form-input_email-validation-required':
    'Veuillez entrer votre adresse e-mail',
  'account-profile_form-input_email-validation': "L'adresse e-mail n'est pas valide",
  'account-profile_form-input_sector': 'Entrez le département',
  'account-profile_form-input_sector-validation': 'Veuillez entrer le département',
  'account-profile_form-input_phone-number': '',
  'account-profile_form-input_phone-number-validation':
    'Veuillez entrer le numéro de téléphone',
  // Company
  'account-info-panel_company_title': "Données de l'entreprise",
  'account-info-panel_company_cif': 'Numéro de NIF',
  // 'account-info-panel_company_cif': 'Numéro de NIF (Numéro d\'Identification Fiscale)',
  'account-info-panel_company_trade-name': 'Nom commercial',
  'account-info-panel_company_business-name': 'Raison sociale',
  'account-info-panel_company_address': 'Adresse',
  'account-info-panel_company_postal-code': 'Code postal',
  'account-info-panel_company_population': 'Ville',
  'account-info-panel_company_country': 'Pays',
  'account-info-panel_company_province': 'Province',
  'account-info-panel_company_contact_name': 'Contact Principal',
  'account-info-panel_company_email': 'Email',
  'account-info-panel_company_phone-number': 'Numéro de téléphone',
  'account-info-panel_matas-connected_title': 'Metas Connectés',
  'account-info-panel_active-connections_title': 'Connexions Actives',
  // Company form
  'account-company_form-input_trade-name': 'Entrez le nom commercial',
  'account-company_form-input_trade-name-validation': 'Veuillez entrer le nom commercial',
  'account-company_form-input_business-name': "Entrez le nom de l'entreprise",
  'account-company_form-input_business-name-validation':
    "Veuillez entrer le nom de l'entreprise",
  'account-company_form-input_country': 'Entrez le pays',
  'account-company_form-input_country-validation': 'Veuillez entrer le pays',
  'account-company_form-input_population': 'Entrez la ville',
  'account-company_form-input_population-validation': 'Veuillez entrer la ville',
  'account-company_form-input_address': "Entrez l'adresse",
  'account-company_form-input_address-validation': 'Veuillez entrer votre adresse',
  'account-company_form-input_postal-code': 'Entrez le code postal',
  'account-company_form-input_postal-code-validation': 'Veuillez entrer le code postal',
  'account-company_form-input_cif': "Entrez le NIF (Numéro d'Identification Fiscale)",
  'account-company_form-input_cif-validation':
    "Veuillez entrer le NIF (Numéro d'Identification Fiscale)",
  'account-company_form-input_phone-number': 'Numéro de téléphone',
  'account-company_form-input_phone-number-validation':
    'Veuillez entrer le numéro de téléphone',
  'account-company_form-input_contact_name':
    "Entrez le contact principal de l'entreprise",
  'account-company_form-input_contact_name-validation-required':
    "Veuillez entrer le contact principal de l'entreprise",
  'account-company_form-input_email': "Entrez l'e-mail de l'entreprise",
  'account-company_form-input_email-validation-required':
    'Veuillez entrer votre adresse e-mail',
  'account-company_form-input_email-validation': "L'adresse e-mail n'est pas valide",
  'account-company_form-input_instagram': "Entrez l'URL Instagram",
  'account-company_form-input_youtube': "Entrez l'URL Youtube",
  'account-company_form-input_linkedin': "Entrez l'URL Linkedin",
  'account-company_form-input_facebook': "Entrez l'URL Facebook",
  'account-company_social-media-title': 'Réseaux Sociaux',
  'account-company_form-input_social-media-label': 'Nom du Réseau Social',
  'account-company_form-input_social-media-label-placeholder': 'Nom du Réseau Social',
  'account-company_form-input_social-media-label_validation':
    'Veuillez entrer le nom du réseau social',
  'account-company_form-input_social-media-value': 'URL du Réseau Social',
  'account-company_form-input_social-media-value-placeholder': 'URL du Réseau Social',
  'account-company_form-input_social-media-value_validation':
    "Veuillez entrer l'URL du réseau social",
  'account-company_form-input_social-media-add-button': 'Ajouter un Réseau Social',
  'account-company_form-input_social-media-accept-button_title':
    'Mettre à jour le Réseau Social',
  'account-company_form-input_social-media-reject-button_title':
    'Annuler les modifications',
  'account-company_form-input_social-media-delete-button_title':
    'Supprimer le Réseau Social',
  'account-company_form-input_social-media-delete-button_popconfirm_title':
    'Supprimer le Réseau Social',
  'account-company_form-input_social-media-delete-button_popconfirm_description':
    'Êtes-vous sûr de vouloir supprimer ce Réseau Social?',
  'account-company_form-input_social-media-delete-button_popconfirm_ok': 'Supprimer',
  'account-company_form-input_social-media-delete-button_popconfirm_cancel': 'Annuler',
  // Company drawer
  'account-company_drawer_title': 'Données de l’Entreprise',
  // Main Contacts
  'main-contacts_title': 'Contacts Principaux',
  'main-contacts_add-contact': '+ Ajouter',
  'main-contacts_table_name': 'Nom',
  'main-contacts_table_lastname': 'Nom de famille',
  'main-contacts_table_email': 'E-mail',
  'main-contacts_table_phone-number': 'Numéro de téléphone',
  'main-contacts_table_department': 'Département',
  'main-contacts_table_pop-confirm_title': 'Supprimer le contact',
  'main-contacts_table_pop-confirm_description':
    'Êtes-vous sûr de vouloir supprimer ce contact ?',
  'main-contacts_table_pop-confirm_btn-accept': 'Supprimer',
  'main-contacts_table_pop-confirm_btn-cancel': 'Annuler',
  // Main Contacts drawer
  'main-contacts_drawer_create-title': 'Créer un contact',
  'main-contacts_drawer_update-title': 'Mettre à jour le contact',
  // Main Contacts Form
  'main-contacts_form-label_name': 'Nom',
  'main-contacts_form-label_lastname': 'Nom de famille',
  'main-contacts_form-label_email': 'E-mail',
  'main-contacts_form-label_phone-number': 'Numéro de téléphone',
  'main-contacts_form-label_department': 'Département',
  'main-contacts_form-input_name': 'Entrez le nom',
  'main-contacts_form-input_lastname': 'Entrez le nom de famille',
  'main-contacts_form-input_email': "Entrez l'e-mail",
  'main-contacts_form-input_department': 'Entrez le département',
  'main-contacts_form_sector_label': 'Département',
  'main-contacts_form-input_sector': 'Entrer le département',
  'main-contacts_form-input_sector_validation': 'Entrer le département',
  'main-contacts_form-input_phone-number': 'Entrez le numéro de téléphone',
  'main-contacts_form-input-validation_name': 'Veuillez entrer le nom',
  'main-contacts_form-input-validation_lastname': 'Veuillez entrer le nom de famille',
  'main-contacts_form-input-validation_email': "L'e-mail n'est pas valide",
  'main-contacts_form-input-validation-required_email': "Veuillez entrer l'e-mail",
  'main-contacts_form-input-validation_department': 'Veuillez entrer le département',
  'main-contacts_form-input-validation_phone-number':
    'Veuillez entrer le numéro de téléphone',
  // My brands
  'my-brands_title': 'Mes Marques',
  'my-brands_add-to-my-brands': '+ Ajouter',
  'my-brands_table_brand-name': 'Nom de la Marque',
  'my-brands_table_main-contact': 'Contact Principal',
  'my-brands_table_phone-number': 'Numéro de Téléphone',
  'my-brands_table_email': 'Email',
  'my-brands_table_address': 'Adresse',
  'my-brands_table_city': 'Ville',
  'my-brands_table_pop-confirm_title': 'Dissocier de mes marques',
  'my-brands_table_pop-confirm_description1': 'Êtes-vous sûr de vouloir dissocier',
  'my-brands_table_pop-confirm_description2': 'de mes marques ?',
  'my-brands_table_pop-confirm_btn-accept': 'Dissocier',
  'my-brands_table_pop-confirm_btn-cancel': 'Annuler',
  'my-brands_table_disassociate-tooltip-title': 'Dissocier la marque',
  // My brands drawer
  'my-brands_drawer_title': 'Rechercher et Ajouter des Marques',
  'my-brands_drawer_add-title': 'Créer une nouvelle marque',
  'my-brands_drawer_go-back-to-brands': 'Retour',
  'my-brands_drawer_form_address': 'Adresse',
  'my-brands_drawer_form_address-street-number': 'Rue et numéro',
  'my-brands_drawer_form_contact': 'Personne de contact',
  'my-brands_drawer_form_contact_name': 'Prénom',
  'my-brands_drawer_form_contact_lastname': 'Nom',
  'my-brands_drawer_search-placeholder': 'Recherche',
  'my-brands_drawer_more-brands': 'Vous voulez une marque que vous ne voyez pas ici ?',
  'my-brands_drawer_close-drawer': 'Fermer',
  'my-brands_drawer_brand_associate-btn': 'Associer',
  'my-brands_drawer_brand_see-profile-btn': 'Voir Profil',
  'my-brands_disassociate-modal_title': 'Dissocier',
  'my-brands_disassociate-multiple-modal_title': 'Dissocier les marques',
  'my-brands_disassociate-multiple-modal_content':
    'Êtes-vous sûr de vouloir dissocier les marques sélectionnées et tous leurs points de vente?',
  'my-brands_disassociate-multiple-modal_popconfirm-description':
    'Êtes-vous sûr de vouloir dissocier les marques sélectionnées?',
  'my-brands_disassociate-modal_on-ok': 'Dissocier',
  'my-brands_disassociate-modal_on-cancel': 'Annuler',
  'my-brands_disassociate-modal_content':
    'Êtes-vous sûr de vouloir dissocier cette marque et tous ses points de vente?',
  /////////////////////////////

  // MULTIMEDIA ///////////////
  'multimedia_nav-videos': 'Vidéos',
  'multimedia_nav-images': 'Images',
  'multimedia_sort-btn': 'Trier par',
  'multimedia_filter-btn': 'Filtres',
  'multimedia_apply-filters-btn': 'Appliquer les filtres',
  'multimedia_search-input-placeholder':
    'Rechercher par marque, genre, saison, pays, date',
  'multimedia_search-results': 'Résultats',
  'multimedia_sort-last-favorites': 'Favoris',
  multimedia_download: 'Télécharger',
  multimedia_download_started: 'Le téléchargement a commencé',
  multimedia_open: 'Ouvrir',
  'multimedia_link-copied': 'Lien copié dans le presse-papiers',
  'multimedia_link-copy-button': 'Copier le lien',
  // Sort by
  'multimedia_sort-last-aggregates': 'Derniers ajoutés',
  'multimedia_sort-best-seller': 'Meilleures ventes',
  'multimedia_sort-less-sold': 'Moins vendu',
  'multimedia_sort-best-pvp_great-minor': 'Prix : élevé à bas',
  'multimedia_sort-best-pvp_minor-great': 'Prix : bas à élevé',
  'multimedia_sort-best-pvi_great-minor': 'PVI : élevé à bas',
  'multimedia_sort-best-pvi_minor-great': 'PVI : bas à élevé',
  'multimedia_sort-by-color': 'Par couleur de A à Z',
  'multimedia_sort-by-reference': 'Par référence de A à Z',
  // Images
  'multimedia_images_popover-edit': 'Modifier',
  'multimedia_images_popover-remove': 'Supprimer',
  'multimedia_images_popover-yes': 'Oui',
  'multimedia_images_popover-no': 'Non',
  // Filter
  'multimedia_filter-remove-filters': 'Supprimer les filtres',
  'multimedia_filter-season': 'Saison',
  'multimedia_filter-division': 'Division',
  'multimedia_filter-genders': 'Genre',
  'multimedia_filter-visualization': 'Visualisation',
  'multimedia_filter-image-rights': "Droits d'image",
  'multimedia_filter-country': 'Pays',
  'multimedia_filter-tags': 'Étiquettes',
  'multimedia_filter-favorites': 'Mes favoris',
  'multimedia_filter-start': 'Diffusable depuis',
  'multimedia_filter-end': "Diffusable jusqu'à",
  // Dragger
  'multimedia_upload-images-manually_dragger_msg-error': 'Fichiers autorisés',
  'multimedia_upload-images-manually_dragger_text-1':
    'Cliquez ou déposez et glissez une image',
  // Upload image manually
  'multimedia_upload-images-manually_form-item_selection': 'Sélectionner',
  'multimedia_upload-images-manually_form-item_title': 'Titre',
  'multimedia_upload-images-manually_form-item_title-required':
    "Veuillez saisir le titre de l'image",
  'multimedia_upload-images-manually_form-item_name': 'Nom technique',
  'multimedia_upload-images-manually_form-item_name-required':
    "Veuillez saisir le nom de l'image",
  'multimedia_upload-images-manually_form-item_description': 'Description',
  'multimedia_upload-images-manually_form-item_description-required':
    'Veuillez entrer la description',
  'multimedia_upload-images-manually_form-item_divisions': 'Divisions',
  'multimedia_upload-images-manually_form-item_season': 'Saison',
  'multimedia_upload-images-manually_form-item_language': 'Langue',
  'multimedia_upload-images-manually_form-item_genders': 'Genres',
  'multimedia_upload-images-manually_form-item_products': 'Produits',
  'multimedia_upload-images-manually_form-item_catalog_id': 'ID catalogue',
  'multimedia_upload-images-manually_form-item_catalog_id_text-1':
    'Champ associé à chronos pour définir son circuit publicitaire',
  'multimedia_upload-images-manually_form-item_catalog_id_text-2':
    'Contactez Hub4Retail pour activer Chronos',
  'multimedia_upload-images-manually_form-item_usage': 'Utilisation du contenu',
  'multimedia_upload-images-manually_form-item_visualization': 'Visualisation',
  'multimedia_upload-images-manually_form-item_tags': 'Étiquettes',
  'multimedia_upload-images-manually_form-item_tags_text-1':
    'Créez vos propres étiquettes',
  'multimedia_upload-images-manually_form-item_start': 'Diffusable depuis',
  'multimedia_upload-images-manually_form-item_end': "Diffusable jusqu'à",
  'multimedia_upload-images-manually_form-item_copyright':
    "Cette vidéo est-elle soumise à des droits d'image?",
  'multimedia_upload-images-manually_form-item_enable_download':
    'Permettez-vous le téléchargement?',
  'multimedia_upload-images-manually_form-item_countries': 'Pays',
  'multimedia_upload-images-manually_form-item_add': 'Ajouter',
  'multimedia_upload-images-manually_form-item_next': 'Suivant',
  'multimedia_upload-images-manually_form-item_yes': 'Oui',
  'multimedia_upload-images-manually_form-item_no': 'Non',
  // Modal manually
  'multimedia_upload-images-manually_modal_title': "Mettre à jour l'image",
  'multimedia_upload-images-manually_modal_step-1': 'Sélectionnez une image',
  'multimedia_upload-images-manually_modal_step-2': 'Complétez les données',
  'multimedia_upload-images-manually_modal_step-2-msg': "Titre de l'image",
  'multimedia_upload-images-manually_modal_step-3-msg': 'Image téléchargée avec succès',
  // Upload images CSV
  'multimedia_upload-images_button': 'Télécharger des Images',
  'multimedia_upload-images_step-1': 'Sélectionner des Images',
  'multimedia_upload-images_step-1_dragger_text-1':
    'Cliquez ou faites glisser et déposez des fichiers image',
  'multimedia_upload-images_step-1_dragger_text-2': "Gardez à l'esprit ce qui suit...",
  'multimedia_upload-images_step-1_dragger_text-3': 'Enregistrez les images avec le nom',
  'multimedia_upload-images_step-1_dragger_text-4': "référence_couleur_numéro d'image",
  'multimedia_upload-images_step-2': 'Télécharger CSV',
  'multimedia_upload-images_step-2_dragger_text-1':
    'Cliquez ou faites glisser et déposez le fichier CSV',
  'multimedia_upload-images_step-3': 'Téléchargement Terminé!',
  'multimedia_upload-images_step-3-text': 'fichiers image téléchargés',
  'multimedia_upload-images_modal_title': 'Télécharger des Images',
  'multimedia_upload-images_modal_btn-back': 'Retour',
  'multimedia_upload-images_modal_btn-next': 'Suivant',
  'multimedia_upload-images_modal_btn-close': 'Fermer',
  ////////////////////////////

  // BRAND USERS /////////////////
  'brand-user_title': 'Utilisateurs',
  'brand-user_description':
    'Contrôlez comment les membres de votre équipe interagissent avec Hub4Retail.',
  'brand-user_create-user': 'Créer un utilisateur',
  'brand-user_modal_title-create': 'Ajouter un utilisateur',
  'brand-user_modal_title-edit': 'Éditer un utilisateur',
  'brand-user_modal_input_name': 'Prénom',
  'brand-user_modal_input_lastname': 'Nom',
  'brand-user_modal_input_email': 'Email',
  'brand-user_modal_input_points-of-sale': 'Points de vente',
  'brand-user_modal_input_password': 'Mot de passe',
  'brand-user_modal_input_btn-save': 'Enregistrer',
  'brand-user_modal_input_btn-cancel': 'Annuler',
  'brand-user_table_name': 'Prénom',
  'brand-user_table_email': 'Email',
  'brand-user_table_role': 'Rôle',
  'brand-user_table_last-session': 'Dernière session',
  'brand-user_table_last-session-never': 'Jamais',
  'brand-user_delete-popconfirm_title': 'Supprimer l’utilisateur',
  'brand-user_delete-popconfirm_description':
    'Êtes-vous sûr de vouloir supprimer cet utilisateur?',
  'brand-user_delete-popconfirm_confirm': 'Oui',
  'brand-user_delete-popconfirm_cancel': 'Non',
  ////////////////////////////////

  // CATALOG //////////////////
  'catalog_add-product': 'Ajouter un produit',
  catalog_ProductList_results: 'Résultats',
  'catalog_no-more-results': 'Aucun résultat trouvé...',
  'catalog_tags_delete-all': 'Tout supprimer',
  'catalog_loading-filters': 'Chargement des filtres',
  'catalog_selected-brands-title': 'Marques sélectionnées',
  'catalog_brand-tag': 'Marque',
  'catalog_brands-tag': 'Marques',
  // Sort by
  'catalog_sort-by_dateAsc': 'Derniers ajoutés',
  'catalog_sort-by_dateDesc': 'Les plus anciens',
  'catalog_sort-by_unitsAsc': 'Meilleures ventes',
  'catalog_sort-by_unitsDesc': 'Moins vendus',
  'catalog_sort-by_nameAsc': 'Nom de A à Z',
  'catalog_sort-by_nameDesc': 'Nom de Z à A',
  'catalog_sort-by_colorAsc': 'Couleur de A à Z',
  'catalog_sort-by_colorDesc': 'Couleur de Z à A',
  'catalog_sort-by_pviAsc': 'PVI du plus bas au plus haut',
  'catalog_sort-by_pviDesc': 'PVI du plus haut au plus bas',
  'catalog_sort-by_pvprAsc': 'PVPR du plus bas au plus haut',
  'catalog_sort-by_pvprDesc': 'PVPR du plus haut au plus bas',
  'catalog_sort-by_referenceAsc': 'Référence de A à Z',
  'catalog_sort-by_referenceDesc': 'Référence de Z à A',
  'catalog_sort-all-brands': 'Toutes les marques',
  'catalog_brand-feed-btn': 'Profil de la marque',
  // Catalog download
  'catalog_search-input-placeholder': 'Rechercher par référence, couleur ou description',
  'catalog_download_alert_brand-required': 'Veuillez sélectionner une marque',
  'catalog_download_alert_get-filters-error':
    'Erreur lors de la récupération des filtres',
  'catalog_download_alert_dowloading-compressed-files':
    'Téléchargement des fichiers compressés...',
  'catalog_download_progress-images': 'Images',
  'catalog_download_success-msg': 'Images téléchargées avec succès',
  'catalog_download_success-msg-imgs-and-csv': 'CSV et images téléchargés avec succès',
  'catalog_download_csv-msg-without-images': 'CSV téléchargé avec succès',
  'catalog_download_csv-msg-pending-images':
    'CSV téléchargé avec succès, mais il y a des images en attente',
  'catalog_download_csv-msg-pending-images-navigate':
    'CSV téléchargé avec succès, mais il y a des images en attente. Vous pouvez continuer à naviguer pendant le téléchargement',
  'catalog_download_apply-changes-btn': 'Appliquer les modifications',
  'catalog_download_cancel-filter-btn': 'Annuler',
  'catalog_download_cancel-values-selection-btn': 'Effacer la sélection',
  'catalog_download_cancel-values-download-btn': 'Annuler le téléchargement',
  'catalog_download_clear-filters-btn': 'Effacer les filtres',
  catalog_download_result: 'résultat',
  catalog_download_results: 'résultats',
  catalog_download_columns_brand: 'Marque',
  catalog_download_columns_reference: 'Référence',
  catalog_download_columns_ean: 'EAN',
  catalog_download_columns_size: 'Taille',
  catalog_download_columns_name: 'Nom',
  catalog_download_columns_color: 'Description de la couleur',
  catalog_download_columns_color_code: 'Code couleur',
  catalog_download_columns_pvi: 'PVI',
  catalog_download_columns_pvpr: 'PVPR',
  catalog_download_columns_division: 'Division',
  catalog_download_columns_gender: 'Genre',
  catalog_download_columns_season: 'Saison',
  catalog_download_columns_segmentation: 'Segmentation',
  catalog_download_columns_family: 'Famille',
  catalog_download_columns_material: 'Composition',
  catalog_download_columns_images: 'Images',
  'catalog_download_columns_all-images': 'Tous les produits',
  'catalog_download_columns_with-images': 'Avec images',
  'catalog_download_columns_without-images': 'Sans images',
  catalog_download_columns_favorites: 'Favoris',
  'catalog_download_columns_column-search_search': 'Rechercher',
  'catalog_download_columns_column-search_reset': 'Réinitialiser',
  'catalog_download_columns_column-search_close': 'Fermer',
  'catalog_download_list_select-all': 'Tout sélectionner',
  'catalog_download_list_clear-all': 'Effacer la sélection',
  'catalog_download_list_download-btn': 'Télécharger',
  'catalog_download_list_download-images-btn': 'Télécharger des images',
  catalog_download_list_item: 'élément',
  catalog_download_list_items: 'éléments',
  'catalog_download_list_select-order_popover-title': 'Sélectionner et ordonner',
  ///////////////////////////

  // PRODUCT //////////////////
  product_infoBox_codeEAN: 'Codes',
  product_collection_title: 'Toute la collection',
  product_infoProduct_other_colors: 'Autres couleurs',
  product_infoProduct_currency: '€',
  product_infoProduct_sizes: 'Tailles et prix',
  product_infoProduct_EAN_size: 'Taille',
  product_infoProduct_EAN_code: 'Code EAN',
  product_collapse_title_key1: 'Tailles',
  product_collapse_title_key2: 'INFORMATION GÉNÉRALE',
  product_collapse_title_key3: 'COMPOSITION',
  'product_card-color': 'Couleur',
  'product_mobile_product-details_size': 'Taille',
  'product_mobile_product-details_info': 'Information Générale',
  'product_mobile_product-details_ean-codes': 'Codes EAN',
  'product_mobile_product-details_ean-code': 'Code EAN',
  'product_mobile_product-details_composition': 'Composition',
  'product_related-videos_title': 'Catalogue Multimédia',
  'product_related-videos_subtitle': 'Vidéos Associées',
  'product_related-products_title': 'Cela pourrait aussi vous intéresser...',
  'product_card_sold-units': 'Unités vendues:',
  /////////////////////////////

  // POINTS OF SALE ///////////
  'points-of-sale_title': 'Points de vente',
  'points-of-sale_add-point-of-sale': '+ Ajouter',
  'points-of-sale_empty-points-of-sale': "Il n'y a pas encore de points de vente",
  'points-of-sale_table_brand': 'Marque',
  'points-of-sale_table_connection': 'Connexion',
  'points-of-sale_table_recive-data': 'Recevoir des données',
  'points-of-sale_table_share-data': 'Partager des données',
  'points-of-sale_drawer_title': 'Points de vente',
  'points-of-sale_drawer_name_label': 'Nom',
  'points-of-sale_drawer_add-edit-sale-point_point-of-sale-type':
    'Type de Point de Vente',
  'points-of-sale_drawer_add-edit-sale-point_point-of-sale-type_physical':
    'Magasin Physique',
  'points-of-sale_drawer_add-edit-sale-point_point-of-sale-type_online':
    'Boutique en Ligne',
  'points-of-sale_drawer_add-edit-sale-point_category': 'Catégorie',
  'points-of-sale_drawer_add-edit-sale-point_category_input-validation':
    'Veuillez entrer la catégorie du point de vente',
  'points-of-sale_drawer_exchange_label': 'Type de Monnaie',
  'points-of-sale_drawer_add-edit-sale-point_exchange_input-validation':
    'Veuillez entrer le type de monnaie',
  'points-of-sale_drawer_associate-brand_button_title':
    "Ajouter aux marques avec lesquelles le point de vente travaille et l'associer à mes marques",
  'points-of-sale_drawer_add-brand_button_title':
    'Ajouter aux marques avec lesquelles le point de vente travaille',
  'points-of-sale_drawer_selected-brand_button_title': 'Associer à mes marques',
  'points-of-sale_drawer_associate-all-brands_modal-title-1': "Demande d'association à",
  'points-of-sale_drawer_associate-all-brands_modal-title-2': 'de',
  'points-of-sale_drawer_associate-all-brands_modal-title-3': 'marques',
  'points-of-sale_drawer_associate-all-brands_modal-title-4': 'marque',
  'points-of-sale_drawer_associate-all-brands_modal-button-1': 'marques associées',
  'points-of-sale_drawer_associate-all-brands_modal-button-2':
    'Application des changements',
  'points-of-sale_drawer_associate_button_associate-all': 'non associé',
  'points-of-sale_drawer_associate_button_associate-all-1': 'Associer',
  'points-of-sale_drawer_associate_button_associate-all-2': 'Marques',
  'points-of-sale_drawer_expand-less': 'Voir moins',
  'points-of-sale_drawer_expand-more': 'Voir tout',
  'points-of-sale_drawer_sub-title-1':
    'Marques avec lesquelles le point de vente travaille',
  'points-of-sale_drawer_sub-title-2': 'Ajoutez des marques au point de vente de',
  'points-of-sale_drawer_associate-all_popconfirm_title-1':
    "Voulez-vous envoyer une demande d'association à",
  'points-of-sale_drawer_associate-all_popconfirm_title-2': 'cette marque',
  'points-of-sale_drawer_associate-all_popconfirm_title-3': 'ces marques',
  'points-of-sale_drawer_associate-all_popconfirm_description-1': 'plus',
  'points-of-sale_drawer_associate-all_popconfirm_on-ok': 'Associer',
  'points-of-sale_drawer_associate-all_popconfirm_on-cancel': 'Annuler',
  'points-of-sale_drawer_associate-all_modal_title': 'Modifications effectuées dans',
  'points-of-sale_drawer_associate-all_modal_on-ok': 'Sauvegarder les modifications',
  'points-of-sale_drawer_associate-all_modal_on-cancel': 'Ignorer les modifications',
  'points-of-sale_drawer_associate-all_modal_description-1':
    'Il y a des modifications effectuées dans le point de vente de',
  'points-of-sale_drawer_associate-all_modal_description-2':
    'Voulez-vous sauvegarder les modifications?',
  'points-of-sale_drawer_name_input': 'Entrez un nom',
  'points-of-sale_drawer_name_input-validation': 'Veuillez entrer un nom',
  'points-of-sale_drawer_web_label': 'Web',
  'points-of-sale_drawer_web_input': 'Entrez un site web',
  'points-of-sale_drawer_address_label': 'Adresse',
  'points-of-sale_drawer_address_input': 'Entrez une adresse',
  'points-of-sale_drawer_address_input-validation': 'Veuillez entrer une adresse',
  'points-of-sale_drawer_postal-code_label': 'Code postal',
  'points-of-sale_drawer_postal-code_input': 'Entrez un code postal',
  'points-of-sale_drawer_postal-code_input-validation': 'Veuillez entrer un code postal',
  'points-of-sale_drawer_city_label': 'Ville',
  'points-of-sale_drawer_city_input': 'Entrez une ville',
  'points-of-sale_drawer_city_input-validation': 'Veuillez entrer une ville',
  'points-of-sale_drawer_country_label': 'Pays',
  'points-of-sale_drawer_province_label': 'État/Province',
  'points-of-sale_drawer_province_input': 'Entrez un état/province',
  'points-of-sale_drawer_province_input-validation': 'Veuillez entrer un état/province',
  'points-of-sale_drawer_region_label': 'Région',
  'points-of-sale_drawer_region_input': 'Entrez une région',
  'points-of-sale_drawer_region_input-validation': 'Veuillez entrer une région',
  'points-of-sale_drawer_tel-sufix_label': 'Numéro de téléphone',
  'points-of-sale_drawer_tel_input': 'Entrez un numéro de téléphone',
  'points-of-sale_drawer_tel_input-validation': 'Veuillez entrer un numéro de téléphone',
  'points-of-sale_drawer_data-share_label': 'Partager des données',
  'points-of-sale_drawer_receiving_label': 'Recevoir des données',
  'points-of-sale_drawer_connected_label': 'Connexion',
  'points-of-sale_drawer_brands_title':
    'Recherchez et ajoutez vos marques à ce point de vente',
  'points-of-sale_drawer_brands_input-placeholder': 'Rechercher',
  'points-of-sale_drawer_brands_more-brands':
    'Vous voulez une marque que vous ne voyez pas ici?',
  'points-of-sale_drawer_add-point-of-sale_title': 'Ajouter un point de vente',
  'points-of-sale_drawer_add-brand_title': 'Ajouter une nouvelle marque',
  'points-of-sale_drawer_edit-point-of-sale_title': 'Modifier le Point de Vente',
  'points-of-sale_drawer_brands-to-associate-selected': 'Marques de',
  'points-of-sale_drawer_brands-to-associate-selected-1': "+ Demande d'association",
  'points-of-sale_drawer_brands-selected': 'Marques en',
  'points-of-sale_drawer__brands--brand__add-brand': 'Ajouter',
  'points-of-sale_drawer__brands--brand__asociate-brand': 'Associer',
  'points-of-sale_drawer__edit-button': 'Modifier',
  'points-of-sale_add-edit-sale-point_area': 'Surface commerciale (M2)',
  'points-of-sale_add-edit-sale-point_center-code': 'Code du centre',
  'points-of-sale_add-edit-sale-point_main-contact': 'Contact principal',
  /////////////////////////////

  /// THANKS PAGE //////////////
  ThanksForRegister__register: 'ENREGISTREMENT DES MARQUES',
  ThanksForRegister__title: 'Merci pour votre intérêt pour Hub4Retail!',
  ThanksForRegister__subtitle:
    'Votre demande est en cours de traitement et nous vous contacterons sous peu.',
  ThanksForRegister__button: 'Aller sur le site web',
  /////////////////////////////

  /// REGISTER BRAND ///////////
  RegisterBrand__title1: 'Prêt à commencer à',
  RegisterBrand__title2: 'amener votre entreprise au',
  RegisterBrand__title3: 'niveau suivant?',
  RegisterBrand__subtitle: 'Plateforme conçue pour:',
  RegisterBrand__ex1: 'Améliorer les outils de collaboration et de partage.',
  RegisterBrand__ex2: 'Assurer la sécurité des données en tout temps.',
  RegisterBrand__ex3: 'Augmenter les marges de revenus.',
  RegisterBrand__button: 'Retour',
  RegisterBrand__register: 'Enregistrement',
  registerBrand__form__name: 'Prénom',
  registerBrand__form__lastName: 'Nom',
  registerBrand__form__business: 'Raison sociale',
  registerBrand__form__company: 'Nom commercial',
  registerBrand__form__phone: 'Site web',
  registerBrand__form__email: 'Téléphone',
  registerBrand__form__web: 'Email professionnel',
  registerBrand__form__description: 'Voulez-vous nous en dire plus?',
  registerBrand__form__selector: 'Secteur',
  'registerBrand__form__say-more': 'Voulez-vous nous en dire plus?',
  registerBrand__form__selector__optionDefault: '',
  registerBrand__form__selector__option1: 'Chaussures',
  registerBrand__form__selector__option2: 'Accessoires',
  registerBrand__form__selector__option3: 'Maison-Jardin',
  registerBrand__form__selector__option4: 'Beauté',
  registerBrand__form__selector__option5: 'Électronique',
  registerBrand__form__selector__option6: 'Sport',
  registerBrand__form__selector__option7: 'Automobile',
  registerBrand__form__selector__option8: 'Alimentaire',
  registerBrand__form__selector__option9: 'Autre',
  /////////////////////////////

  // BRAND PAGE //////////
  'brand-page_send-association-btn': "Envoyer demande d'association",
  'brand-page_history-timeline_title': 'Historique',
  'brand-page_history-points-of-sale_title': 'Points de Vente et Connexion',
  'brand-page_history-points-of-sale_total': 'Total',
  'brand-page_history-points-of-sale_name': 'Nom',
  'brand-page_history-points-of-sale_address': 'Adresse',
  'brand-page_history-points-of-sale_city': 'Ville',
  'brand-page_history-points-of-sale_recive-data': 'Réception des Données',
  'brand-page_history-points-of-sale_recive-data_active': 'Actif',
  'brand-page_history-points-of-sale_recive-data_inactive': 'Inactif',
  'brand-page_history-points-of-sale_status_associate': 'Associé',
  'brand-page_history-points-of-sale_status_pending': 'En attente',
  'brand-page_history-points-of-sale_status_no-associate': 'Non associé',
  'brand-page_history-points-of-sale_status': 'Statut',
  'brand-page_history-points-of-sale_status_createdAt': 'Date',
  'brand-page_history-points-of-sale_share-data': 'Partage des Données',
  'brand-page_history-points-of-sale_associated': 'Associé',
  'brand-page_history-points-of-sale_not-associated': 'Non associé',
  'brand-page_history-active-contacts_name': 'Nom',
  'brand-page_history-active-contacts_email': 'Email',
  'brand-page_history-active-contacts_phone-number': 'N° de Téléphone',
  'brand-page_history-active-contacts_department': 'Département',
  'brand-page_history-active-seasons_title': 'Saisons Actives',
  'brand-page_history-active-contacts_title': 'Contacts Principaux',
  'brand-page_history-contacts_popover-title': 'Informations de Contact',
  'brand-page_history-contacts_popover-content_name': 'Nom:',
  'brand-page_history-contacts_popover-content_surname': 'Nom de Famille:',
  'brand-page_history-contacts_popover-content_email': 'Email:',
  'brand-page_history-contacts_popover-content_phone-number': 'Numéro de Téléphone:',
  'brand-page_history-contacts_popover-content_sector': 'Secteur:',
  ////////////////////////

  // SETTINGS PAGE ////////
  'settings-page_nav_chronos': 'Chronos',
  'settings-page_nav_devices': 'Dispositifs',
  'settings-page_nav_touch-screen': 'Écrans Tactiles',
  'settings-page_nav_users': 'Utilisateurs',
  'settings-page_nav_system-settings': 'Paramètres du Système',
  'settings-page_nav_meta': 'Méta',
  'settings-page_nav_billing': 'Facturation',
  'settings-page_nav_support': 'Support',
  // Users page
  'settings-page_users-page_create-user-btn': 'Créer un utilisateur',
  /////////////////////////

  // BRAND FEED PAGE ////////
  'brand-feed_page_info_title': 'À propos de nous',
  'brand-feed_page_buttons_connect': 'Connecter',
  'brand-feed_page_videos_title': 'Catalogue Multimédia',
  'brand-feed_page_videos_subtitle': 'Vidéos associées',
  ///////////////////////////

  // NOTIFICATIONS /////////////////
  // Notifications Page
  'notifications-page_notifications-title': 'Notifications',
  'notifications-page_alerts-title': 'Alertes',
  'notifications_btn-name_catalog': 'Voir le Catalogue',
  'notifications_btn-name_profile': 'Voir le Profil',
  'notifications_list_show-all': 'Tout',
  'notifications_list_show-unreaded': 'Non lus',
  'notifications_list_per-page-select': 'par page',
  // Popover
  notifications_popover_title: 'NOTIFICATIONS',
  'notifications_popover_empty-notifications': 'Pas de notifications',
  'notifications_popover_btn-name_catalog': 'Voir le Catalogue',
  'notifications_popover_btn-name_profile': 'Voir le Profil',
  'notifications_popover_item_mark-as-readed_msg': 'Marqué comme lu',
  'notifications_popover_item_mark-as-unreaded_msg': 'Marqué comme non lu',
  'notifications_popover_item_mark-as-readed': 'Marquer comme lu',
  'notifications_popover_item_mark-as-unreaded': 'Marquer comme non lu',
  'notifications_popover_all-notifications-btn': 'Toutes les notifications',
  notifications_popover_accept: 'Accepter',
  notifications_popover_reject: 'Rejeter',
  alerts_popover_title: 'ALERTES',
  'alerts_popover_empty-notifications': "Pas d'alertes",
  'alerts_popover_all-notifications-btn': 'Toutes les alertes',
  ///////////////////////////////////

  // SALES ///////////////////////////
  'sales_csv-info_title': 'Informations CSV',
  'sales_csv-info_columns': 'Colonnes:',
  'sales_csv-info_rows': 'Lignes:',
  'sales_csv-info_separator': 'Séparateur:',
  'sales_csv-info_headers': 'En-têtes:',
  'sales_csv-info_errors': 'Erreurs:',
  // Add sales CSV
  'sales_add-sales-csv-input-title': 'Télécharger CSV',
  'sales_add-sales-csv_warning-msg-file-pnly-contain-a-header':
    "Le fichier ne contient qu'un en-tête",
  'sales_add-sales-csv-error-column-mismatch':
    'Certaines lignes ne correspondent pas aux en-têtes du CSV',
  'sales_add-sales-xlsx-input-title': 'Télécharger XLSX',
  'sales_add-sales-xlsx_select-row_description':
    'Sélectionnez la ligne contenant les en-têtes',
  'sales_add-sales-xlsx_select-row_input-placeholder': 'Nº de ligne',
  'sales_add-sales-csv-s2-text-1': 'Télécharger les ventes CSV ou XLSX',
  'sales_add-sales-csv-s2-text-2': 'Créer ou sélectionner un mapping de colonnes',
  // Handle not matched points of sale
  'sales_csv-upload_not-matched-points-of-sale_title':
    'Points de vente non correspondants',
  'sales_csv-upload_not-matched-points-of-sale_description':
    "Les points de vente suivants ne correspondent pas aux points de vente de l'entreprise. Veuillez sélectionner un point de vente dans la liste pour chaque point de vente non correspondant.",
  'sales_csv-upload_not-matched-points-of-sale_checking-matching':
    'Vérification des points de vente...',
  // My csv mappings
  'sales_csv-mappings_title': 'Carte de correspondance',
  'sales_csv-mappings_title-create': 'Créer une carte pour le CSV',
  'sales_csv-mappings_save-file_success': 'Fichier de ventes téléchargé avec succès',
  'sales_csv-mappings_send-file-btn': 'Télécharger les ventes',
  // Create mapping form
  'sales_csv-mapping-form_title': 'Sélectionnez les en-têtes CSV pour créer une carte.',
  'sales_csv-mapping-form_columns-without-reference': 'Colonnes CSV non référencées :',
  'sales_csv-mapping-form_periodicity': 'Périodicité',
  'sales_csv-mapping-form_periodicity_required': 'La périodicité est requise',
  'sales_csv-mapping-form_periodicity_dairy': 'Quotidien',
  'sales_csv-mapping-form_periodicity_weekly': 'Hebdomadaire',
  'sales_csv-mapping-form_periodicity_biweekly': 'Bimensuel',
  'sales_csv-mapping-form_save-map-btn': 'Enregistrer la carte',
  'sales_csv-mapping-form_onfinish_field-required-msg':
    "Les champs marqués d'un * sont obligatoires",
  'sales_csv-mapping-form_onfinish_map-points-of-sale-required':
    'Tous les points de vente doivent être mappés',
  // Mapping table
  'sales_csv-mapping-table_column-1_label': 'Champs de ventes',
  'sales_csv-mapping-table_column-2_label': 'Colonnes CSV',
  'sales_csv-mapping-table_column-2_select-label': 'Sélectionnez une colonne',
  'sales_csv-mapping-table_column-2_select-label-multiple':
    'Sélectionnez une ou plusieurs colonnes',
  'sales_csv-mapping-table_column-2_select-format_label': 'Sélectionnez un format',
  'sales_csv-mapping-table_column-3_label': 'Données résultantes',
  // MyCSVMappingInfoPopover
  'sales_csv-mapping-info-popover_periodicity': 'Périodicité:',
  'sales_csv-mapping-info-popover_periodicity-days': 'jours',
  'sales_csv-mapping-info-popover_date-format': 'Format de date:',
  'sales_csv-mapping-info-popover_points-of-sale-mapped': 'Points de vente mappés:',
  'sales_csv-mapping-info-popover_references': 'Références',
  'sales_csv-mapping-info-popover_tags': 'Tags',
  'sales_csv-mapping-info-popover_columns-without-references': 'Colonnes non référencées',
  'sales_csv-mapping-info-popover_popconfirm_title':
    'Êtes-vous sûr de vouloir supprimer cette carte ?',
  'sales_csv-mapping-info-popover_popconfirm_btn': 'Supprimer la carte',
  'sales_csv-mapping-info-popover_popconfirm_ok': 'Oui',
  'sales_csv-mapping-info-popover_popconfirm_cancel': 'Non',
  // SalesHistoryPage
  'sales-history_page_title': 'Historique des téléchargements de ventes',
  'sales-history_page_upload-btn': 'Télécharger des ventes',
  'sales-history_page_table_column-1_title': 'Date de téléchargement',
  'sales-history_page_table_column-2_title': 'Nom du fichier',
  'sales-history_page_table_column-3_title': 'Statut',
  'sales-history_page_table_column-3_processed': 'Traitée',
  'sales-history_page_table_column-3_pending': 'En attente',
  'sales-history_page_table_column-4_popover_title':
    'Supprimer le téléchargement des ventes',
  'sales-history_page_table_column-4_popover_description':
    'Êtes-vous sûr de vouloir supprimer le téléchargement des ventes ?',
  'sales-history_page_table_column-4_delete-btn': 'Supprimer',
  'sales-history_page_table_column-4_dowload-processed-success':
    'Fichier traité correctement téléchargé',
  'sales-history_page_table_column-4_dowload-error':
    'Erreur lors du téléchargement du fichier',
  'sales-history_page_table_column-4_dowload-original-success':
    'Fichier original téléchargé avec succès',
  'sales-history_page_table_column-4_dowload-processed-file':
    'Télécharger le fichier traité',
  'sales-history_page_table_column-4_dowload-original-file':
    'Télécharger le fichier original',
  'sales-history_page_table_original-name': 'Nom de fichier original',
  'sales-history_page_table_upload-type': 'Type de téléchargement',
  'sales-history_page_table_file-type': 'Type de fichier',
  'sales-history_page_table_pending-mapping-title': 'Mappage en attente',
  'sales-history_page_table_mapping-error-title': 'Une erreur est survenue',
  'sales-history_page_table_download-file-error-msg':
    'Erreur lors du téléchargement du fichier',
  'sales-history_page_table_download-file-format-not-supported':
    'Format de fichier non pris en charge',
  'sales-history_page_table_create-mapping-label': 'Créer un mappage',
  'sales-history_page_table_info-popover_title':
    'Lignes du fichier où des erreurs se produisent',
  'sales-history_page_table_info-popover_contact-with-us':
    "Si le problème persiste, contactez l'équipe de support (help@hub4retail.com) et fournissez ce numéro d'identification du problème :",
  'sales-history_page_table_info-popover_UNKNOWN_CHAR':
    "Un caractère ne correspondant pas à l'encodage spécifié dans le fichier a été trouvé.",
  'sales-history_page_table_info-popover_PTO_MISSING':
    'Mappage du point de vente en attente.',
  'sales-history_page_table_info-popover_MISSING_PTO_MAP':
    'Mappage du point de vente en attente.',
  'sales-history_page_table_info-popover_SKIP':
    "Échec du traitement, en train d'ignorer.",
  'sales-history_page_table_info-popover_MAP_ID_NOT_FOUND':
    'Aucun mappage ne correspond, créez le mappage pour traiter le fichier.',
  'sales-history_page_table_info-popover_MAPS_NOT_FOUND':
    'Aucun mappage ne correspond, créez le mappage pour traiter le fichier.',
  'sales-history_page_table_info-popover_MAP_NOT_FOUND':
    'Aucun mappage ne correspond, créez le mappage pour traiter le fichier.',
  'sales-history_page_table_info-popover_BRAND_CLIENT_ID_NOT_FOUND':
    "Un fichier est créé dans un dossier avec un identifiant de marque ou de client qui n'existe plus dans la base de données.",
  'sales-history_page_table_info-popover_FOLDER_NOT_FOUND':
    "L'enregistrement de l'utilisateur SFTP contient un identifiant de marque ou de client, mais le dossier avec cet identifiant n'existe pas dans Amazon S3, il n'est donc pas possible de récupérer les fichiers.",
  'sales-history_page_table_info-popover_FOLDER_LIST_UNAVAILABLE':
    "Impossible de se connecter à Amazon S3. L'utilisateur AWS a été supprimé ou les autorisations ont changé, rendant la connexion à S3 impossible.",
  'sales-history_page_table_info-popover_FILES_NOT_FOUND':
    'Le dossier pour cette marque ou ce client est vide. Aucun fichier à traiter.',
  'sales-history_page_table_info-popover_NO_CONTENT_TYPE':
    "Le fichier téléchargé sur AWS n'a pas de type de contenu défini.",
  'sales-history_page_table_info-popover_NO_ENCODING':
    "Le fichier téléchargé sur AWS n'a pas de type de contenu défini.",
  'sales-history_page_table_info-popover_NO_FILE_METADATA':
    'Impossible de lire le contenu du fichier.',
  'sales-history_page_table_info-popover_CANNOT_CREATE_BUFFER':
    'Impossible de convertir le flux de données du fichier en tampon.',
  'sales-history_page_table_info-popover_HEADERS_NOT_FOUND':
    'En-têtes de fichier non trouvées.',
  'sales-history_page_table_info-popover_SIGNATURE_MISMATCH':
    'Aucun mappage ne correspond, créez le mappage pour traiter le fichier.',
  'sales-history_page_table_info-popover_SEPARATOR_NOT_FOUND':
    'Aucun séparateur trouvé dans le mappage.',
  'sales-history_page_table_info-popover_SEPARATOR_MISMATCH':
    "Le séparateur dans le mappage ne correspond pas au séparateur trouvé dans l'en-tête du fichier.",
  'sales-history_page_table_info-popover_DATAEFORMAT_NOT_FOUND':
    'Format de date non trouvé.',
  'sales-history_page_table_info-popover_DATE_PARSE_ERROR':
    'Le format de date dans le mappage ne correspond pas au format de date du fichier.',
  'sales-history_page_table_info-popover_JSON_NORM_ERROR':
    "Lecture des données du fichier (json) annulée en raison d'erreurs.",
  'sales-history_page_table_info-popover_JSON_PARSE_ABORT':
    "Processus annulé en raison d'erreurs.",
  'sales-history_page_table_info-popover_BRAND_CLIENT_NOT_FOUND':
    'Identifiants de marque ou de client non trouvés.',
  'sales-history_page_table_info-popover_MISSING_CSV_DATA':
    'Le CSV ne contient pas de données. Supprimez le fichier si nécessaire.',
  'sales-history_page_table_info-popover_DATA_TRIM_ERROR':
    'Impossible de normaliser les espaces dans certains chaînes.',
  'sales-history_page_table_info-popover_DATA_INTEGRITY_ERROR':
    "Le nombre de colonnes dans certaines lignes ne correspond pas au nombre d'en-têtes. Corrigez le fichier et téléchargez-le à nouveau.",
  'sales-history_page_table_search_reset': 'Réinitialiser',
  'sales-history_page_delete-sales-history-not-processed-popover_title':
    'Supprimer toutes les ventes non traitées',
  'sales-history_page_delete-sales-history-not-processed-popover_description':
    'Êtes-vous sûr de vouloir supprimer toutes les ventes non traitées ?',
  'sales-history_page_delete-sales-history-not-processed-popover_delete-all-btn':
    'Tout supprimer',
  'sales-history_page_delete-sales-history-not-processed-msg-success':
    'Tous les fichiers non traités ont été supprimés',
  'sales-history_page_reload-sales-history': "Recharger l'historique",
  ////////////////////////////////////

  // MAPPING KEYS //////////////////////////////////////
  // Sales mapping keys
  'mapping-keys-field__multibrand': 'Multi-marques',
  'mapping-keys-field__multibrand_point_of_sale': 'Point de vente',
  'mapping-keys-field__point_of_sale_id': 'ID du point de vente',
  'mapping-keys-field__client_id': 'ID de multimarque',
  'mapping-keys-field__date': 'Date',
  'mapping-keys-field__hour': 'Heure',
  'mapping-keys-field__floor': 'Étage',
  'mapping-keys-field__caja': 'Caisse',
  'mapping-keys-field__order_number': 'Numéro de commande',
  'mapping-keys-field__order_line': 'Ligne de commande',
  'mapping-keys-field__product_reference': 'Référence produit',
  'mapping-keys-field__product_description': 'Description produit',
  'mapping-keys-field__brand_ean': 'EAN marque',
  'mapping-keys-field__product_ean_code': 'EAN multi-marques',
  'mapping-keys-field__product_brand': 'Marque',
  'mapping-keys-field__color_code': 'Code couleur',
  'mapping-keys-field__product_color': 'Nom de la couleur',
  'mapping-keys-field__product_size_eu': 'Taille',
  'mapping-keys-field__product_season': 'Saison',
  'mapping-keys-field__division': 'Division',
  'mapping-keys-field__family': 'Famille',
  'mapping-keys-field__subfamily': 'Sous-famille',
  'mapping-keys-field__gender': 'Genre',
  'mapping-keys-field__units': 'Unités',
  'mapping-keys-field__price': 'Prix de vente',
  'mapping-keys-field__cost': "Prix d'achat",
  'mapping-keys-field__discount': 'Montant de la remise',
  'mapping-keys-field__discount_percentaje': 'Remise%',
  'mapping-keys-field__payment_method': 'Mode de paiement',
  'mapping-keys-field__seller': 'Vendeur',
  //////////////////////////////////////////////////////

  // META ///////////////////////////
  meta_connections_title: 'Connexions',
  'meta_connections_new-connection': '+ Nouveau',
  meta_connections_modal_title: 'Nouvelle connexion',
  'meta_connections_modal_form_user-name': 'Nom d’utilisateur',
  'meta_connections_modal_form_user-name_required':
    'Veuillez entrer le nom d’utilisateur',
  meta_connections_modal_form_password: 'Mot de passe',
  meta_connections_modal_form_password_required: 'Veuillez entrer le mot de passe',
  meta_connections_modal_form_period: 'Fréquence',
  meta_table_createdAt: 'Date de création',
  'meta_table_user-name': 'Utilisateur',
  meta_table_password: 'Mot de passe',
  meta_table_type: 'Type',
  meta_table_period: 'Fréquence d’envoi',
  ///////////////////////////////////

  // ACTIONS/REDUCER ///////////////
  // Main contacts
  'action_get-main-contacts_error':
    'Erreur lors de la récupération des contacts principaux',
  'action_select-main-contact_error':
    'Erreur lors de la sélection des contacts principaux',
  'action_create-main-contact_success': 'Contact créé avec succès',
  'action_create-main-contact_error': 'Erreur lors de la création du contact',
  'action_update-main-contact_success': 'Contact mis à jour avec succès',
  'action_update-main-contact_error': 'Erreur lors de la mise à jour du contact',
  'action_delete-main-contact_success': 'Contact supprimé avec succès',
  'action_delete-main-contact_error': 'Erreur lors de la suppression du contact',
  // My Brands
  'action_my-brands_get-my-brands_error': 'Erreur lors de la récupération de mes marques',
  'action_my-brands_remove-from-my-brands_success': 'Marque désolidarisée avec succès',
  'action_my-brands_remove-from-my-brands-multiple_success':
    'Marques dissociées avec succès',
  'action_my-brands_remove-from-my-brands_error':
    'Erreur lors de la désolidarisation de la marque',
  'action_my-brands_add-to-my-brands_exists': 'Vous avez déjà ajouté cette marque',
  'action_my-brands_add-to-my-brands_error': "Erreur lors de l'ajout de la marque",
  'action_my-brands_add-to-my-brands_success':
    "Demande d'association envoyée avec succès",
  'action_my-brands_create-new-brand_success': 'Marque ajoutée avec succès',
  'action_my-brands_create-new-brand_exists':
    'Une marque portant le même nom existe déjà',
  'action_my-brands_add-to-my-brands_brand-without-tenant':
    "La marque n'est pas encore sur la plateforme, nous vous contacterons lorsque elle sera disponible",
  // Brand
  'action_brand_get-brand_error': "Erreur lors de l'obtention de la marque",
  'action_brand_get-brand_contact-errors':
    "Erreur lors de l'obtention des contacts de la marque",
  'action_brand_share-data-with-brand_error':
    'Erreur lors du partage des données avec la marque',
  'action_brand_share-data-with-brand_share-success':
    'Partage des données avec le point de vente',
  'action_brand_share-data-with-brand_no-share-success':
    'Vous avez arrêté de partager des données avec le point de vente',
  // Sales CSV upload
  'sales-csv-upload_reject-upload-success':
    'Téléchargement des ventes supprimé avec succès',
  'sales-csv-upload_reject-upload-error':
    'Erreur lors de la suppression du téléchargement des ventes',
  'sales-csv-upload_get-mappings-error-1':
    'Erreur lors de la récupération des clés de mapping',
  'sales-csv-upload_get-mappings-error-2':
    'Erreur du serveur lors de la récupération des clés de mapping',
  'sales-csv-upload_get-mappings-error-3':
    'Erreur lors de la récupération de mes mappings',
  'sales-csv-upload_delete-mapping-success': 'Mapping supprimé avec succès',
  'sales-csv-upload_delete-mapping-error': 'Erreur lors de la suppression du mapping',
  'sales-csv-upload_create-mapping-success': 'Mapping créé avec succès',
  'sales-csv-upload_create-mapping-success_without-send-file':
    'Carte créée avec succès. Cela prend généralement moins de deux minutes pour apparaître dans la liste.',
  'sales-csv-upload_create-mapping-error': 'Erreur lors de la création du mapping',
  // Meta
  'action_meta_get-connections_error': 'Erreur lors de la récupération des connexions',
  'action_meta_delete-connection_success': 'Connexion supprimée avec succès',
  'action_meta_delete-connection_error': 'Erreur lors de la suppression de la connexion',
  'action_meta_create-connection_success': 'Connexion créée avec succès',
  'action_meta_create-connection_error': 'Erreur lors de la création de la connexion',
  //////////////////////////

  // MESSAGE PAGES //////////
  // Error
  'errors-page_404_subtitle': "Désolé, la page que vous recherchez n'existe pas.",
  'errors-page_404_btn-go-back': 'Retourner',
  // Coming soon
  'coming-soon-page_title': 'Bientôt disponible!',
  'coming-soon-page_go-back': 'Retour',
  /////////////////////////

  // BREADCRUMBS ///////////////
  //Multimedia
  breadcrumbs_multimedia: 'Multimédia',
  'breadcrumbs_multimedia-videos': 'Vidéos',
  'breadcrumbs_multimedia-images': 'Images',
  // Sales
  breadcrumbs_sales: 'Ventes',
  'breadcrumbs_sales-history': 'Historique des téléchargements',
  'breadcrumbs_sales-create': 'Télécharger des ventes',
  // Catalog
  breadcrumb_catalog: 'Catalogue',
  breadcrumb_product: 'Toutes les marques',
  'breadcrumb_catalog-download': 'Télécharger le catalogue',
  // Clients
  breadcrumbs_clients: 'Clients',
  'breadcrumbs_clients-list': 'Liste des clients',
  'breadcrumbs_add-client': 'Ajouter un client',
  breadcrumbs_resume: 'Résumé',
  'breadcrumbs_business-info': "Informations sur l'entreprise",
  'breadcrumbs_main-contacts': 'Contacts principaux',
  'breadcrumbs_sale-points': 'Points de vente',
  'breadcrumbs_commercial-info': 'Informations commerciales',
  // Account
  breadcrumbs_account: 'Compte',
  breadcrumbs_account_profile: 'Profil',
  breadcrumbs_account_company: "Informations sur l'entreprise",
  breadcrumbs_account_contacts: 'Contacts Principaux',
  'breadcrumbs_account_my-brands': 'Mes Marques',
  'breadcrumbs_account_points-of-sale': 'Points de Vente',
  //////////////////////////////

  // MODALS ///////////////////
  'modal_email-verified_title': 'Vérifiez votre e-mail',
  'modal_email-verified_content':
    'Pour continuer le processus, veuillez vérifier votre e-mail.',
  'modal_email-verified_content-1':
    "Vérifiez l'adresse à partir du lien reçu par email, puis cliquez sur",
  'modal_email-verified_content_btn_resend': 'Renvoyer la vérification',
  'modal_email-verified_content_btn_reload': 'Recharger',
  'modal_email-verified_content_notif_resend_title': 'Notification de renvoi',
  'modal_email-verified_content_notif_resend_content-1':
    "L'email de vérification a été envoyé à cette adresse",
  'modal_email-verified_content_notif_resend_content-2':
    'Vérifiez votre email pour continuer.',
  /////////////////////////////

  // POLICY PRIVACY //////////
  'policy-privacy-modal_title': "Conditions d'utilisation",
  'policy-privacy-modal_btn-accept': 'Accepter',
  'policy-privacy-modal_btn-cancel': 'Annuler',
  'policy-privacy-modal_checkbox': "J'accepte les Conditions d'utilisation",
  'policy-privacy-modal_checkbox-message':
    'Acceptez la politique de confidentialité et les conditions.',
  ////////////////////////////

  // SERVER DOWN MODAL /////
  'server-down-modal_title': 'Erreur du serveur',
  'server-down-modal_content':
    'Nous rencontrons des problèmes avec nos services. Veuillez réessayer plus tard ou contacter le support technique.',
  //////////////////////////

  // NOTIFICATIONS /////////
  'notification-error_message': "Code d'erreur",
  'notification-error_description':
    "Veuillez réessayer plus tard. Si le problème persiste, contactez Hub4Retail (help@hub4retail.com) et fournissez le code d'erreur.",
  //////////////////////////

  // GOOGLE ADDRESS FORM //////
  'google-address-form_address': 'Adresse',
  'google-address-form_floor': 'Étage',
  'google-address-form_door': 'Porte',
  'google-address-form_postal-code': 'Code postal',
  'google-address-form_city': 'Ville',
  'google-address-form_province': 'Province',
  'google-address-form_country': 'Pays',
  'google-address-form_select-country': 'Sélectionnez un pays',
  /////////////////////////////

  // SHARED //////////////////
  settings: 'Paramètres',
  logout: 'Déconnexion',
  'admin-panel_link': "Panneau d'administration",
  'btn-save': 'Enregistrer',
  'btn-cancel': 'Annuler',
  'see-more-content-button': 'See more',
  'see-less-content-button': 'Voir moins',
  'see-all-content-button': 'Voir tout',
  'search-btn-label': 'Rechercher',
  'close-btn-label': 'Fermer',
  ////////////////////////////
};

export default franceTranslation;
