import { TFiltersCatalog } from 'views/pages/main/catalog/catalogPage/components/drawerCatalogFilters/DrawerCatalogFilters';
import { IBodyProductSearch, IFavorite } from './catalog.interface';
import { TAlertTypes } from './types';
import { IColumnsSelectOrderState } from 'views/pages/main/catalog/catalogDownloadPage/components/columnsSelectOrder/ColumnsSelectOrder';
import { MutableRefObject } from 'react';
import { DropResult } from 'react-beautiful-dnd';

export interface IInitialCatalogDownloadState {
  // Filters
  catalogBodyFetch: IBodyProductSearch;
  filters: TFiltersCatalog[];
  imageSelection: TImageSelectionTypes;
  setImageSelection: (value: TImageSelectionTypes) => void;
  setFavorites: (value: boolean) => void;
  setPviSlider: (value: [number, number]) => void;
  setPvprSlider: (value: [number, number]) => void;
  isLoadingFilters: boolean;
  filtersAlert: {
    show: boolean;
    type: TAlertTypes;
    message: string;
    description: string;
  };
  filtersSelected: TCatalogDownloadFiltersSelected;
  isLoadingCatalog: boolean;
  filteredList: ICatalogFetchCSVDataPlainSkuResponse[];
  setFilteredList: (value: ICatalogFetchCSVDataPlainSkuResponse[]) => void;
  selectedValues: React.Key[];
  setSelectedValues: (value: React.Key[]) => void;
  filtersSelectedTags: string[];
  isOpenFilters: boolean;
  filtersSelectedTemp: TCatalogDownloadFiltersSelected;
  openFilters: (value: boolean) => void;
  cancelFilters: () => void;
  cleanFilters: () => void;
  applyFilterChanges: (value: TCatalogDownloadFiltersSelected) => void;
  onChangeFilterSelect: (filterName: string, value: TFiltersSelectsValues[]) => void;
  // Seacth
  querySearch: string;
  setQuerySearch: (value: string) => void;
  applySearch: () => void;
  // Columns order
  columnsOrder: Record<string, IColumnsSelectOrderState>;
  moveColumnsOrder: (result: DropResult) => void;
  checkColumn: (columnKey: string) => void;
  // Download
  downloadingStatus: {
    isDownloading: boolean;
    totalImagesToDownload: number;
    completedDownloads: number;
  };
  downloadCatalog: ({
    completedDownloads,
  }: {
    completedDownloads: MutableRefObject<number>;
  }) => void;
  cancelDownloadCatalog: ({
    completedDownloads,
  }: {
    completedDownloads: MutableRefObject<number>;
  }) => void;
  isDownloadingZip: boolean;
  downloadImages: boolean;
  setDownloadImages: (
    value: boolean,
    columns: Record<string, IColumnsSelectOrderState>
  ) => void;
  xhrsRef: any[];
}

export const imageSelectionTypes = {
  all: 'all',
  withImages: 'with-images',
  withoutImages: 'without-images',
};

export type TImageSelectionTypes = 'all' | 'with-images' | 'without-images';

export type TCatalogDownloadFiltersSelected = {
  brand: TFiltersSelectsValues[];
  division: TFiltersSelectsValues[];
  season: TFiltersSelectsValues[];
  gender: TFiltersSelectsValues[];
  family: TFiltersSelectsValues[];
  color: TFiltersSelectsValues[];
};

export type TFiltersSelectsValues = {
  label: string;
  value: string;
  values: string[];
};

export interface ICatalogFetchCSVDataResponse {
  id: string;
  brand: string;
  brand_id: string;
  ranking: number;
  units: number | null;
  favorites: IFavorite[];
  name: string;
  pvi: number;
  pvpr: number;
  reference: string;
  color: string;
  color_code: string;
  images: string[];
  season: string;
  gender: string;
  division: string;
  segmentation: string;
  family: string;
  material: string;
  sku: TEanSizeCSVMode[];
}

type TEanSizeCSVMode = {
  pvpr: number;
  pvi: number;
  ean: string;
  size: string;
};

export interface ICatalogFetchCSVDataPlainSkuResponse {
  key: number;
  id: string;
  brand: string;
  brand_id: string;
  ranking: number;
  units: number | null;
  favorites: IFavorite[];
  name: string;
  reference: string;
  color: string;
  color_code: string;
  images: string[];
  season: string;
  gender: string;
  division: string;
  segmentation: string;
  family: string;
  material: string;
  ean: string;
  size: string;
  pvi: number;
  pvpr: number;
}
