import { basePath } from './utils/config';

export const getClientsAPI = async (token: string): Promise<any> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      scope: 'client',
      populated: true,
      country_id: '',
      search: '',
      index: 0,
      limit: 1000,
    }),
  };
  const response = await fetch(`${basePath}/superadmin/search`, params);
  const data = await response.json();
  return { response, data };
};
