import * as MainContactsTypes from './mainContacts.types';

export default function mainContactsReducer(state: any, action: any) {
  const { type, payload } = action;

  switch (type) {
    case MainContactsTypes.GET_MAIN_CONTACTS:
      return {
        ...state,
        contacts: payload,
      };

    case MainContactsTypes.SELECT_CONTACT:
      return {
        ...state,
        contactSelected: payload,
      };

    case MainContactsTypes.CREATE_CONTACT:
      return {
        ...state,
        contacts: [...state.contacts, payload],
      };

    case MainContactsTypes.UPDATE_CONTACT:
      return {
        ...state,
        contacts: payload,
      };

    case MainContactsTypes.REMOVE_CONTACT:
      return {
        ...state,
        contacts: payload,
      };

    default:
      return state;
  }
}
