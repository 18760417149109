import {
  IPointsOfSaleStateInitialState,
  IPointOfSaleState,
  IPointsOfSaleCategoryState,
  IPointOfSaleExchangesState,
} from 'interfaces/pointsOfSale.interface';

const initialPointsOfSaleState: IPointsOfSaleStateInitialState = {
  pointsOfSale: [] as IPointOfSaleState[],
  pointsOfSaleSelected: {} as IPointOfSaleState,
  pointsOfSaleCategories: [] as IPointsOfSaleCategoryState[],
  exchanges: [] as IPointOfSaleExchangesState[],
  isLoadingGetPointsOfSale: false,
  selectPointOfSale: (pointOfSale: IPointOfSaleState) => {},
  createPointOfSale: (
    brandId: string,
    setIsOpenDrawer: (value: boolean) => void,
    resetGoogleAddress: any,
    setShowOnlineSelect: any,
    form: any,
    setBrandsAddSelected: any
  ) => {},
  updatePointOfSale: (
    newPointOfSale: IPointOfSaleState,
    setIsOpenDrawer: (value: boolean) => void
  ) => {},
  removePointOfSale: (pointOfSaleId: string) => Promise.resolve(false),
  updatePointOfSaleConnections: (key: string, value: boolean) => {},
};

export default initialPointsOfSaleState;
