/* eslint-disable react-hooks/exhaustive-deps */
import { useReducer, useMemo, useCallback, memo } from 'react';
import MetaContext from './MetaContext';
import initialMetaState from './initialMetaState';
import metaReducer from 'contexts/meta/reducers/meta.reducer';
import * as action from 'contexts/meta/reducers/meta.actions';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import useContextUser from 'hooks/contexts/useContextUser';
import { ChildrenProps } from 'interfaces/general.interface';
import {
  TcreateSftpUserAPIBodyProps,
  TcreateShopifyConnectionAPIBodyProps,
  // TgetConnectorsAPIBodyProps,
  TgetSftpUserAPIBodyProps,
  TupdateShopifyConnectionAPIBodyProps,
} from 'api/responseInterfaces/meta.response.interface';

function MetaProvider(props: ChildrenProps) {
  const [metaState, dispatch] = useReducer(metaReducer, initialMetaState);
  const { getAccessTokenSilently } = useAuth0();
  const { t: translate } = useTranslation();
  const { company } = useContextUser();

  const getConnections = useCallback(async () => {
    if (!company.id) return false;
    const body: TgetSftpUserAPIBodyProps = {
      client_id: [company.id],
      brand_id: [],
      search: [],
      index: 0,
      limit: 1000,
    };
    const token = await getAccessTokenSilently();
    return await action.getConnectionsAction({ dispatch, body, token, translate });
  }, [company.id, getAccessTokenSilently, translate]);
  // const getConnections = useCallback(async () => {
  //   const body: TgetConnectorsAPIBodyProps = {
  //     client_id: company.id,
  //     search: [],
  //     index: 0,
  //     limit: 1000,
  //   };
  //   const token = await getAccessTokenSilently();
  //   return await action.getConnectionsAction({ dispatch, body, token, translate });
  // }, [company.id, getAccessTokenSilently, translate]);

  // SFTP /////////////////////////////////////////////////
  const createSftpConnection = useCallback(
    async (body: TcreateSftpUserAPIBodyProps) => {
      const token = await getAccessTokenSilently();
      return (await action.createSftpConnectionAction(
        dispatch,
        body,
        token,
        translate
      )) as boolean;
    },
    [translate]
  );

  const removeSftpConnection = useCallback(
    async (user_name: string) => {
      const token = await getAccessTokenSilently();
      action.removeSftpConnectionAction(dispatch, user_name, token, translate);
    },
    [translate]
  );
  /////////////////////////////////////////////////////////

  // Shopify //////////////////////////////////////////////
  const getShopifyConnections = useCallback(async () => {}, []);

  const createShopifyConnection = useCallback(
    async ({ body }: { body: TcreateShopifyConnectionAPIBodyProps }) => {
      const token = await getAccessTokenSilently();
      return (await action.createShopifyConnectionAction({
        dispatch,
        body,
        token,
        translate,
      })) as boolean;
    },
    []
  );

  const updateShopifyConnection = useCallback(
    async ({ body }: { body: TupdateShopifyConnectionAPIBodyProps }) => {
      return false;
    },
    []
  );

  const removeShopifyConnection = useCallback(
    async ({ connectionId }: { connectionId: string }) => {
      return false;
    },
    []
  );
  /////////////////////////////////////////////////////////

  const getH4rMappingKeys = useCallback(async () => {
    const token = await getAccessTokenSilently();
    return await action.getH4rMappingKeysAction(dispatch, token, translate);
  }, []);

  const memoProvider = useMemo(
    () => ({
      ...metaState,
      getConnections,
      sftp: {
        ...metaState.sftp,
        createSftpConnection,
        removeSftpConnection,
      },
      shopify: {
        ...metaState.shopify,
        getShopifyConnections,
        createShopifyConnection,
        updateShopifyConnection,
        removeShopifyConnection,
      },
      getH4rMappingKeys,
    }),
    [
      metaState,
      getConnections,
      createSftpConnection,
      removeSftpConnection,
      getShopifyConnections,
      createShopifyConnection,
      updateShopifyConnection,
      removeShopifyConnection,
      getH4rMappingKeys,
    ]
  );

  return (
    <MetaContext.Provider value={memoProvider}>{props.children}</MetaContext.Provider>
  );
}

export default memo(MetaProvider);
