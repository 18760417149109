import * as MetaTypes from './meta.types';
import { metaError } from 'utils/errorCodes';
import { notificationErr } from 'views/components/UI/notification';
import {
  messageError,
  messageSuccess,
  messageWarning,
} from 'views/components/UI/message';
import {
  createSftpUserAPI,
  createShopifyConnectionAPI,
  deleteSftpUserAPI,
  // getConnectionsAPI,
  getProductsMappingKeysAPI,
  getSftpUserAPI,
} from 'api/meta.api';
import {
  TcreateSftpUserAPIBodyProps,
  TcreateShopifyConnectionAPIBodyProps,
  // TgetConnectorsAPIBodyProps,
  TgetSftpUserAPIBodyProps,
  // TgetSftpUserAPIProps,
} from 'api/responseInterfaces/meta.response.interface';
import { getSalesMappingKeysAPI } from 'api/sales.api';
import { TH4RMappingKeysItem, TShopifyConnectionItem } from 'interfaces/meta.interface';
import React from 'react';

export async function getConnectionsAction({
  dispatch,
  body,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  body: TgetSftpUserAPIBodyProps;
  token: string;
  translate: any;
}) {
  dispatch({ type: MetaTypes.SET_IS_LOADING_CONNECTIONS, payload: true });
  try {
    const getConnectionsFetch = await getSftpUserAPI({ body, token });

    if (getConnectionsFetch.response.status === 200) {
      if (getConnectionsFetch.data.length > 0) {
        dispatch({
          type: MetaTypes.SET_SFTP_CONNECTIONS,
          payload: getConnectionsFetch.data.map((connection) => ({
            ...connection,
            type: 'sftp',
            status: null,
          })),
        });
      }
      // if (getConnectionsFetch.data.shopify.length > 0) {
      //   dispatch({
      //     type: MetaTypes.SET_SHOPIFY_CONNECTIONS,
      //     payload: getConnectionsFetch.data.shopify.map((connection) => ({
      //       ...connection,
      //       type: 'shopify',
      //       status: null,
      //     })),
      //   });
      // }
      return true;
    }
    return false;
  } catch (err) {
    messageError(translate('action_meta_get-connections_error'));
    return false;
  } finally {
    dispatch({ type: MetaTypes.SET_IS_LOADING_CONNECTIONS, payload: false });
  }
}
// export async function getConnectionsAction({
//   dispatch,
//   body,
//   token,
//   translate,
// }: {
//   dispatch: React.Dispatch<any>;
//   body: TgetConnectorsAPIBodyProps;
//   token: string;
//   translate: any;
// }) {
//   dispatch({ type: MetaTypes.SET_IS_LOADING_CONNECTIONS, payload: true });
//   try {
//     const getConnectionsFetch = await getConnectionsAPI({ body, token });

//     if (getConnectionsFetch.response.status === 200) {
//       if (getConnectionsFetch.data.sftp.length > 0) {
//         dispatch({
//           type: MetaTypes.SET_SFTP_CONNECTIONS,
//           payload: getConnectionsFetch.data.sftp.map((connection) => ({
//             ...connection,
//             type: 'sftp',
//             status: null,
//           })),
//         });
//       }
//       if (getConnectionsFetch.data.shopify.length > 0) {
//         dispatch({
//           type: MetaTypes.SET_SHOPIFY_CONNECTIONS,
//           payload: getConnectionsFetch.data.shopify.map((connection) => ({
//             ...connection,
//             type: 'shopify',
//             status: null,
//           })),
//         });
//       }
//       return true;
//     }
//     return false;
//   } catch (err) {
//     messageError(translate('action_meta_get-connections_error'));
//     return false;
//   } finally {
//     dispatch({ type: MetaTypes.SET_IS_LOADING_CONNECTIONS, payload: false });
//   }
// }

// SFTP ////////////////////////////////////////////////////
export async function removeSftpConnectionAction(
  dispatch: any,
  user_name: string,
  token: string,
  translate: any
) {
  dispatch({
    type: MetaTypes.SET_IS_LOADING_DELETE_SFPT_CONNECTIONS,
    payload: { loading: true, user_name: user_name },
  });
  try {
    const deleteSftpUserConnectionFetch = await deleteSftpUserAPI({
      user_name,
      token,
    });
    if (deleteSftpUserConnectionFetch.response.status === 200) {
      messageSuccess(translate('action_meta_delete-connection_success'));
      return dispatch({
        type: MetaTypes.DELETE_SFTP_CONNECTION,
        payload: user_name,
      });
    } else {
      messageError(translate('action_meta_delete-connection_error'));
      notificationErr(metaError.delete_sftp_connection_002, translate);
    }
  } catch (error) {
    messageError(translate('action_meta_delete-connection_error'));
    notificationErr(metaError.delete_sftp_connection_001, translate);
  } finally {
    dispatch({
      type: MetaTypes.SET_IS_LOADING_DELETE_SFPT_CONNECTIONS,
      payload: { loading: false, user_name: '' },
    });
  }
}

export async function createSftpConnectionAction(
  dispatch: any,
  body: TcreateSftpUserAPIBodyProps,
  token: string,
  translate: any
) {
  dispatch({
    type: MetaTypes.SET_IS_LOADING_CREATE_SFPT_CONNECTIONS,
    payload: true,
  });
  try {
    const createSftpUserConnectionFetch = await createSftpUserAPI({
      body,
      token,
    });

    if (createSftpUserConnectionFetch.response.status === 403) {
      messageWarning(translate('action_meta_create-sftp-connection_error-403'));
      return false;
    }

    if (createSftpUserConnectionFetch.response.status === 200) {
      messageSuccess(translate('action_meta_create-connection_success'));
      dispatch({
        type: MetaTypes.ADD_NEW_CONNECTION,
        payload: createSftpUserConnectionFetch.data,
      });
      return true;
    } else {
      messageError(translate('action_meta_create-connection_error'));
      notificationErr(metaError.create_sftp_connection_002, translate);
      return false;
    }
  } catch (error) {
    messageError(translate('action_meta_create-connection_error'));
    notificationErr(metaError.create_sftp_connection_001, translate);
    return false;
  } finally {
    dispatch({
      type: MetaTypes.SET_IS_LOADING_CREATE_SFPT_CONNECTIONS,
      payload: false,
    });
  }
  ////////////////////////////////////////////////////////////
}

// Shopify /////////////////////////////////////////////////
export async function createShopifyConnectionAction({
  dispatch,
  body,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  body: TcreateShopifyConnectionAPIBodyProps;
  token: string;
  translate: any;
}) {
  dispatch({ type: MetaTypes.SET_IS_LOADING_CREATE_SHOPIFY_CONNECTIONS, payload: true });
  try {
    const createShopifyConnectionFetch = await createShopifyConnectionAPI({
      body,
      token,
    });
    if (createShopifyConnectionFetch.response.status === 409) {
      messageWarning(translate('action_meta_create-shopify-connection_error-409'));
      return false;
    }

    if (
      createShopifyConnectionFetch.response.status === 200 ||
      createShopifyConnectionFetch.response.status === 201
    ) {
      const newConnection: TShopifyConnectionItem = {
        ...createShopifyConnectionFetch.data,
        type: 'shopify',
        status: null,
      };
      dispatch({
        type: MetaTypes.ADD_NEW_SHOPIFY_CONNECTION,
        payload: newConnection,
      });
      return true;
    }
    messageError(translate('action_meta_create-shopify-connection_error'));
    notificationErr(metaError.create_shopify_connection_002, translate);
    return false;
  } catch (err) {
    console.log({ err });
    notificationErr(metaError.create_shopify_connection_001, translate);
    messageError(translate('action_meta_create-shopify-connection_error'));
    return false;
  } finally {
    dispatch({
      type: MetaTypes.SET_IS_LOADING_CREATE_SHOPIFY_CONNECTIONS,
      payload: false,
    });
  }
}

////////////////////////////////////////////////////////////

export async function getH4rMappingKeysAction(
  dispatch: any,
  token: string,
  translate: any
) {
  try {
    const [saleKeys, productKeys] = await Promise.all([
      getSalesMappingKeysAPI(token),
      getProductsMappingKeysAPI(token),
    ]);

    let keys: TH4RMappingKeysItem[] = [];
    if (saleKeys.response.status === 200 && saleKeys.data.length > 0) {
      keys = keys.concat(saleKeys.data.map((key) => ({ ...key, type: 'sales' })));
    }

    if (productKeys.response.status === 200 && productKeys.data.length > 0) {
      keys = keys.concat(productKeys.data.map((key) => ({ ...key, type: 'products' })));
    }

    dispatch({
      type: MetaTypes.SET_MAPPING_KEYS,
      payload: keys,
    });
    return true;
  } catch (error) {
    messageError(translate('action_meta_get-mapping-keys_error'));
    return false;
  }
}
