import { IInitialBrandState } from 'interfaces/brand.interface';
import * as BrandTypes from './brand.types';

export default function brandReducer(state: IInitialBrandState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case BrandTypes.GET_BRAND_INFO:
      const getBrandInfo: IInitialBrandState = {
        ...state,
        brand: { ...state.brand, info: payload },
      };
      return getBrandInfo;

    case BrandTypes.SELECT_TENANT:
      const selectTenant: IInitialBrandState = {
        ...state,
        brand: { ...state.brand, tenantSelected: payload },
      };
      return selectTenant;

    case BrandTypes.GET_BRAND_POINTS_OF_SALE:
      const getBrandPointsOfSale: IInitialBrandState = {
        ...state,
        brand: {
          ...state.brand,
          tenantSelected: {
            ...state.brand.tenantSelected,
            pointsOfSale: payload,
          },
        },
      };
      return getBrandPointsOfSale;

    case BrandTypes.SET_IS_LOADING:
      const setIsLoading: IInitialBrandState = {
        ...state,
        isLoading: payload,
      };
      return setIsLoading;

    default:
      return state;
  }
}
