import { TSocialMedia } from 'interfaces/user.interface';
import { basePath } from './utils/config';

export type TCompanyAPIResponse = {
  id: string;
  name: string;
  legal_name: string;
  country_id: string;
  floor: null | string;
  door: null | string;
  contact_name: string;
  lat: null | number;
  lng: null | number;
  region: string;
  province: string;
  city: string;
  address: string;
  postal_code: string;
  email: string;
  tel_prefix: string;
  tel_sufix: string;
  bio: string;
  brand_id: string;
  cif: string;
  logo: string;
  web: string;
  enabled: boolean;
  linkedin: null | string;
  facebook: null | string;
  instagram: null | string;
  youtube: null | string;
  createdAt: string;
  updatedAt: string;
  brand: {
    id: string;
    name: string;
    logo: string;
  };
  country: {
    id: string;
    name: string;
  };
  tenant_social_media: TSocialMedia[];
};

export interface IGetCompanyInfoAPIResponse {
  response: Response;
  data: TCompanyAPIResponse;
}

export const getTenantInfoAPI = async ({
  tenantId,
  token,
}: {
  tenantId: string;
  token: string;
}): Promise<IGetCompanyInfoAPIResponse> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${basePath}/brand_profile/get_tenant_profile?tenant_id=${tenantId}&country_id=${'45a0b620-9c84-4dc9-a280-ee3d51665fcc'}`,
    params
  );
  const data = await response.json();
  return { response, data };
};
