import { IinitialBrandFeedState } from '../initialBrandFeedState';
import * as BrandFeedTypes from './brandFeed.types';

export default function brandFeedReducer(state: IinitialBrandFeedState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case BrandFeedTypes.GET_BRAND_FEED:
      const getBrandFeed: IinitialBrandFeedState = {
        ...state,
        brand: payload,
      };
      return getBrandFeed;

    default:
      return state;
  }
}
