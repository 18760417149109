import {
  IBrandState,
  IHistoryItemState,
  IInfoBrandState,
  IInitialBrandState,
  ITenantState,
} from 'interfaces/brand.interface';

const brandState: IBrandState = {
  info: {} as IInfoBrandState,
  tenantSelected: {} as ITenantState,
  activeSeasons: 0,
  history: [] as IHistoryItemState[],
};

const initialBrandState: IInitialBrandState = {
  brand: brandState,
  isLoading: true,
  getBrand: (brandId: string) => {},
  selectTenant: (tenant: ITenantState) => {},
  shareDataWithBrand: (datashareId: string, value: boolean) => {},
};

export default initialBrandState;
