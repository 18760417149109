export const SET_SALES_HISTORY = 'SET_SALES_HISTORY';
export const SET_IS_LOADING_HISTORY = 'SET_IS_LOADING_HISTORY';
export const SET_REMOVE_UPLOAD = 'SET_REMOVE_UPLOAD';
export const SET_IS_LOADING_REJECT_UPLOAD = 'SET_IS_LOADING_REJECT_UPLOAD';
export const SET_CSV_SELECTED = 'SET_CSV_SELECTED';
export const SET_CSV_SELECTED_CSV_DATA = 'SET_CSV_SELECTED_CSV_DATA';
export const SET_SEND_FILE = 'SET_SEND_FILE';
export const SET_IS_LOADING_CSV_SELECTED = 'SET_IS_LOADING_CSV_SELECTED';
export const SET_CSV_MAPPINGS = 'SET_CSV_MAPPINGS';
export const SET_CSV_MAPPING_KEYS = 'SET_CSV_MAPPING_KEYS';
export const SET_IS_LOADING_CSV_MAPPINGS = 'SET_IS_LOADING_CSV_MAPPINGS';
export const DELETE_MAPPING = 'DELETE_MAPPING';
export const CREATE_MAPPING = 'CREATE_MAPPING';
