import { getCountriesAPI } from 'api/countries.api';
import * as CountriesTypes from './countries.types';
import { messageError } from 'views/components/UI/message';

export async function getCountriesAction(
  dispatch: Function,
  token: string,
  translate: Function
) {
  try {
    const getCountriesFetch = await getCountriesAPI(token);
    if (getCountriesFetch.response.status === 200) {
      dispatch({
        type: CountriesTypes.GET_COUNTRIES,
        payload: getCountriesFetch.data,
      });
    }
  } catch (error) {
    messageError('Error al obtener los paises.');
    console.log(error);
  }
}
