import { useContext } from 'react';
import MyBrandsContext from 'contexts/myBrands/MyBrandsContext';

export default function useContextMyBrands() {
  const context = useContext(MyBrandsContext);
  if (context === undefined) {
    throw new Error('useContextMyBrands must be used within a MyBrandsProvider');
  }
  return context;
}
