export const userCompanyError = {
  login_user_001: 'UCE|LU-001',
  login_user_002: 'UCE|LU-002',
  login_user_003: 'UCE|LU-003',
  login_user_004: 'UCE|LU-004',
  login_user_005: 'UCE|LU-005',
  login_user_006: 'UCE|LU-006',
  update_user_001: 'UCE|UU-001',
  update_user_002: 'UCE|UU-002',
  update_user_avatar_001: 'UCE|UUA-001',
  delete_user_001: 'UCE|DU-001',
  update_company_001: 'UCE|UC-001',
  update_company_002: 'UCE|UC-002',
  update_company_logo_001: 'UCE|UCL-001',
  update_language_001: 'UCE|UL-001',
  update_language_002: 'UCE|UL-002',
  updateSocialMedia_001: 'UCE|USM-001',
  updateSocialMedia_002: 'UCE|USM-002',
  removeSocialMedia_001: 'UCE|RSM-001',
  removeSocialMedia_002: 'UCE|RSM-002',
};

export const mainContactError = {
  get_contacts_001: 'MCE|GC-001',
  select_contact_001: 'MCE|SC-001',
  create_contact_001: 'MCE|CC-001',
  update_contact_001: 'MCE|UC-001',
  update_contact_002: 'MCE|UC-002',
  remove_contact_001: 'MCE|RC-001',
  remove_contact_002: 'MCE|RC-002',
};

export const myBrandsError = {
  get_my_brands_001: 'MBE|GMB-001',
  get_my_brands_002: 'MBE|GMB-002',
  get_my_brands_003: 'MBE|GMB-003',
  remove_my_from_my_brands_001: 'MBE|RMB-001',
  remove_my_from_my_brands_002: 'MBE|RMB-002',
  remove_my_from_my_brands_multiple_001: 'MBE|RMBM-001',
  remove_my_from_my_brands_multiple_002: 'MBE|RMBM-002',
  add_to_my_brands_001: 'MBE|ATMB-001',
  add_to_my_brands_002: 'MBE|ATMB-002',
  add_to_my_brands_003: 'MBE|ATMB-003',
  add_to_my_brands_004: 'MBE|ATMB-004',
};

export const brandError = {
  get_brand_001: 'BE|GB-001',
  get_brand_002: 'BE|GB-002',
  get_brand_003: 'BE|GB-003',
  share_data_with_brand_001: 'BE|SDWB-001',
  share_data_with_brand_002: 'BE|SDWB-002',
};

export const pointsOfSaleError = {
  get_points_of_sale_001: 'PSE|GPS-001',
  get_points_of_sale_002: 'PSE|GPS-002',
  get_points_of_sale_003: 'PSE|GPS-003',
  get_points_of_sale_004: 'PSE|GPS-004',
  select_point_of_sale_001: 'PSE|SPS-002',
  create_point_of_sale_001: 'PSE|CPS-001',
  create_point_of_sale_002: 'PSE|CPS-002',
  create_point_of_sale_003: 'PSE|CPS-003',
  update_point_of_sale_001: 'PSE|UPS-001',
  update_point_of_sale_002: 'PSE|UPS-002',
  remove_point_of_sale_001: 'PSE|RPS-001',
  remove_point_of_sale_002: 'PSE|RPS-002',
  get_exchanges_001: 'PSE|GE-001',
  get_exchanges_002: 'PSE|GE-002',
};

export const brandUsersError = {
  get_brand_users_001: 'BUE|GBU-001',
  select_brand_user_001: 'BUE|SBU-001',
  create_brand_user_001: 'BUE|CBU-001',
  create_brand_user_002: 'BUE|CBU-002',
  update_brand_user_001: 'BUE|UBU-001',
  update_brand_user_002: 'BUE|UBU-002',
  update_brand_user_003: 'BUE|UBU-003',
  update_brand_user_004: 'BUE|UBU-004',
  update_brand_user_005: 'BUE|UBU-005',
};

export const multimediaError = {
  get_multimedia_001: 'MME|GM-001',
  get_more_multimedia_001: 'MME|GMM-001',
  search_multimedia_001: 'MME|SM-001',
  select_image_multimedia_001: 'MME|RIM-001',
  get_by_brand_001: 'MME|GB-001',
};

export const catalogError = {
  get_catalog_001: 'CE|GC-001',
  add_more_products_to_catalog_001: 'CE|AMPTC-001',
  search_catalog_001: 'CE|SC-001',
  filter_catalog_001: 'CE|FC-001',
  sort_by_001: 'CE|SB-001',
  sort_by_brand_001: 'CE|SBB-001',
};

export const salesError = {
  get_sales_history_001: 'CSV|GSH-001',
  reject_upload_001: 'CSV|RU-001',
  reject_upload_002: 'CSV|RU-002',
  get_mappings_001: 'CSV|GM-001',
  get_mappings_002: 'CSV|GM-002',
  get_mappings_003: 'CSV|GM-003',
  delete_mapping_001: 'CSV|DM-001',
  delete_mapping_002: 'CSV|DM-002',
  create_mapping_001: 'CSV|CM-001',
  create_mapping_002: 'CSV|CM-002',
  create_mapping_003: 'CSV|CM-003',
  save_sales_file_001: 'CSV|SSF-001',
  save_sales_file_002: 'CSV|SSF-002',
  update_points_of_sale_map_001: 'CSV|UPSM-001',
  update_points_of_sale_map_002: 'CSV|UPSM-002',
  update_points_of_sale_map_003: 'CSV|UPSM-003',
};

export const metaError = {
  get_sftp_connections_001: 'ME|GSC-001',
  delete_sftp_connection_001: 'ME|DSC-001',
  delete_sftp_connection_002: 'ME|DSC-002',
  create_sftp_connection_001: 'ME|CSC-001',
  create_sftp_connection_002: 'ME|CSC-002',
  create_shopify_connection_001: 'ME|CSC-001',
  create_shopify_connection_002: 'ME|CSC-002',
};
