import {
  ISAdminClientState,
  ISAdminUserClientState,
} from 'interfaces/superAdmin.interface';
import * as PointsOfSaleTypes from './superAdmin.types';
import { getClientsAPI } from 'api/superAdmin.api';
import moment from 'moment';
import { IBrandUser } from 'interfaces/brandUsers.interface';

export async function getClientsAction(dispatch: any, token: string, translate: any) {
  try {
    const getClientsFetch = await getClientsAPI(token);
    if (getClientsFetch.response.status === 200) {
      getClientsFetch.data.forEach((item: any, index: number) => {
        getClientsFetch.data[index].logo = item.logo
          ? `${item.logo}?ts=${moment().unix()}`
          : '';
      });
      dispatch({
        type: PointsOfSaleTypes.SET_CLIENTS,
        payload: getClientsFetch.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export async function removeUserClientAction(
  dispatch: any,
  clients: ISAdminClientState[],
  clientId: string,
  userId: string,
  translate: any
) {
  const findIndexClient = clients.findIndex((client) => client.id === clientId);
  if (findIndexClient !== -1) {
    const userClients = clients[findIndexClient].user_clients.filter(
      (user) => user.user_id !== userId
    );
    clients[findIndexClient].user_clients = userClients;
    dispatch({
      type: PointsOfSaleTypes.SET_CLIENTS,
      payload: clients,
    });
  }
}

export async function addUserClientAction(
  dispatch: any,
  clients: ISAdminClientState[],
  clientId: string,
  user: IBrandUser,
  translate: any
) {
  const newUser: ISAdminUserClientState = {
    id: user.key,
    user_id: user.key,
    client_id: clientId,
    createdAt: undefined,
    updatedAt: undefined,
    user: {
      id: user.key,
      auth_id: user.auth_id,
      username: user.username,
      name: user.name,
      surname: user.surname,
      tel_prefix: '',
      tel_sufix: '',
      profile_image: '',
      i18n_lang_id: user.i18n_lang_id,
      i18n_lang_code: 'es',
      role: user.role,
      dashboard: '',
      sector: user.sector,
      signed: user.signed,
      first_login: user.first_login,
      enabled: user.enabled,
      lastLoging: user.lastLoging,
      createdAt: user.createdAt,
      updatedAt: user.updatedAt,
    },
  };
  const findIndexClient = clients.findIndex((client) => client.id === clientId);
  if (findIndexClient !== -1) {
    clients[findIndexClient].user_clients.push(newUser);
    dispatch({
      type: PointsOfSaleTypes.SET_CLIENTS,
      payload: clients,
    });
  }
}
