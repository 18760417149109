import { RouterProvider } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import router from './router/router';
import MenuMobileProvider from 'contexts/UI/menuMobile/MenuMobileProvider';
import UserProvider from 'contexts/user/UserProvider';
import LanguageProvider from 'contexts/UI/language/LanguageProvider';
import CatalogProvider from 'contexts/catalog/CatalogProvider';
import MainContactsProvider from 'contexts/mainContacts/MainContactsProvider';
import MyBrandsProvider from 'contexts/myBrands/MyBrandsProvider';
import MultimediaProvider from 'contexts/multimedia/MultimediaProvider';
import DrawersMultimediaProvider from 'contexts/UI/drawersMultimedia/DrawersMultimediaProvider';
import BrandProvider from 'contexts/brand/BrandProvider';
import PointsOfSaleProvider from 'contexts/pointsOfSale/PointsOfSaleProvider';
import BrandUsersProvider from 'contexts/brandUsers/BrandUsersProvider';
import BrandsProvider from 'contexts/brands/BrandsProvider';
import GoogleAddressProvider from 'contexts/UI/googleAddress/GoogleAddressProvider';
import { memo, useCallback } from 'react';
import NotificationsProvider from 'contexts/notifications/NotificationsProvider';
import SuperAdminProvider from 'contexts/superAdmin/SuperAdminProvider';
import CatalogDownloadProvider from 'contexts/catalogDownload/CatalogDownloadProvider';
import CountriesProvider from 'contexts/UI/countries/CountriesProvider';
import BrandFeedProvider from 'contexts/brandFeed/BrandFeedProvider';
import SalesProvider from 'contexts/sales/SalesProvider';
import MetaProvider from 'contexts/meta/MetaProvider';

function App() {
  const getClientId = useCallback(() => {
    if (process.env.REACT_APP_NODE_ENV === 'local' || !process.env.REACT_APP_NODE_ENV) {
      return process.env.REACT_APP_AUTH0_CLIENT_ID_LOCAL;
    } else if (process.env.REACT_APP_NODE_ENV === 'prod') {
      return process.env.REACT_APP_AUTH0_CLIENT_ID_PRODUCTION;
    } else if (process.env.REACT_APP_NODE_ENV === 'dev') {
      return process.env.REACT_APP_AUTH0_CLIENT_ID_DEV;
    } else if (process.env.REACT_APP_NODE_ENV === 'staging') {
      return process.env.REACT_APP_AUTH0_CLIENT_ID_STAGING;
    }
  }, []);

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
      clientId={getClientId() as string}
      authorizationParams={{
        redirect_uri: window.location.origin + '/',
        audience: process.env.REACT_APP_AUTH0_AUDIENCE as string,
      }}
    >
      <LanguageProvider>
        <UserProvider>
          <SuperAdminProvider>
            <CountriesProvider>
              <NotificationsProvider>
                <MenuMobileProvider>
                  <DrawersMultimediaProvider>
                    <MyBrandsProvider>
                      <PointsOfSaleProvider>
                        <SalesProvider>
                          <CatalogProvider>
                            <CatalogDownloadProvider>
                              <MultimediaProvider>
                                <MainContactsProvider>
                                  <BrandProvider>
                                    <BrandFeedProvider>
                                      <BrandUsersProvider>
                                        <BrandsProvider>
                                          <GoogleAddressProvider>
                                            <MetaProvider>
                                              <RouterProvider
                                                router={router}
                                                fallbackElement={<></>}
                                              />
                                            </MetaProvider>
                                          </GoogleAddressProvider>
                                        </BrandsProvider>
                                      </BrandUsersProvider>
                                    </BrandFeedProvider>
                                  </BrandProvider>
                                </MainContactsProvider>
                              </MultimediaProvider>
                            </CatalogDownloadProvider>
                          </CatalogProvider>
                        </SalesProvider>
                      </PointsOfSaleProvider>
                    </MyBrandsProvider>
                  </DrawersMultimediaProvider>
                </MenuMobileProvider>
              </NotificationsProvider>
            </CountriesProvider>
          </SuperAdminProvider>
        </UserProvider>
      </LanguageProvider>
    </Auth0Provider>
  );
}

export default memo(App);
