export const SET_IS_LOADING_CONNECTIONS = 'SET_IS_LOADING_CONNECTIONS';
// SFTP
export const SET_SFTP_CONNECTIONS = 'SET_SFTP_CONNECTIONS';
export const DELETE_SFTP_CONNECTION = 'DELETE_SFTP_CONNECTION';
export const SET_IS_LOADING_DELETE_SFPT_CONNECTIONS =
  'SET_IS_LOADING_DELETE_SFPT_CONNECTIONS';
export const ADD_NEW_CONNECTION = 'ADD_NEW_CONNECTION';
export const SET_IS_LOADING_CREATE_SFPT_CONNECTIONS =
  'SET_IS_LOADING_CREATE_SFPT_CONNECTIONS';
// SHOPIFY
export const SET_SHOPIFY_CONNECTIONS = 'SET_SHOPIFY_CONNECTIONS';
export const DELETE_SHOPIFY_CONNECTION = 'DELETE_SHOPIFY_CONNECTION';
export const SET_IS_LOADING_DELETE_SHOPIFY_CONNECTIONS =
  'SET_IS_LOADING_DELETE_SHOPIFY_CONNECTIONS';
export const ADD_NEW_SHOPIFY_CONNECTION = 'ADD_NEW_SHOPIFY_CONNECTION';
export const SET_IS_LOADING_CREATE_SHOPIFY_CONNECTIONS =
  'SET_IS_LOADING_CREATE_SHOPIFY_CONNECTIONS';
export const UPDATE_SHOPIFY_CONNECTION = 'UPDATE_SHOPIFY_CONNECTION';
export const SET_IS_LOADING_UPDATE_SHOPIFY_CONNECTIONS =
  'SET_IS_LOADING_UPDATE_SHOPIFY_CONNECTIONS';
export const SET_MAPPING_KEYS = 'SET_MAPPING_KEYS';
