import { countriesTranslation } from 'assets/locale/en/core/countriesTranslation';

const englishTranslation = {
  ...countriesTranslation,
  // HEADER //////////////////
  // Nav
  header_nav_home: 'Home',
  header_nav_dashboard: 'Dashboard',
  header_nav_catalog: 'Catalog',
  'header_nav_catalog-download': 'Download catalog',
  header_nav_multimedia: 'Multimedia',
  'header_nav_my-brands': 'My Brands',
  'header_sub-nav_view-catalog': 'View Catalog',
  'header_sub-nav_add-product': 'Add Product',
  'header_sub-nav_multimedia-catalog': 'Multimedia Catalog',
  'header_sub-nav_multimedia-catalog-videos': 'Videos',
  'header_sub-nav_multimedia-catalog-images': 'Images',
  'header_sub-nav_multimedia-catalog-banners': 'Banners',
  'header_sub-nav_my-brands-list': 'Brands List',
  'header_sub-nav_add-brand': 'Add Brand',
  header_nav_sales: 'Sales',
  'header_nav_sales-history': 'Upload History',
  'header_nav_sales-create': 'Upload Sales',
  // Notifications
  'header_notifications_empty-notifications': 'There are no notifications',
  ////////////////////////////

  // SIGNUP PAGE /////////////
  'signup-page_title': 'Sign up for:',
  'signup-page_sub-title': 'Sign up to expand your business',
  'signup-page_button-brand': 'Sign up as a Brand',
  'signup-page_button-multibrand': 'Sign up as a Wholesale',
  'signup-page_button-brand-wh': 'Sign Up as a Brand or Multi-brand',
  'signup-page_return-to-login': 'Return to login',
  ////////////////////////////

  // HOME PAGE ///////////////
  'home-page_brands_my-brands_title': 'My Brands',
  'home-page_brands_item_button-see-catalog': 'View Catalog',
  'home-page_brands_my-brands_my-brands-link': 'Go to My Brands',
  'home-page_brands_all-brands_title': 'Brands on HUB',
  'home-page_brands_all-brands_see-more': 'See all',
  'home-page_brands_all-brands_see-less': 'See less',
  'home-page_brands_item_button-connect': 'Connect',
  'home-page_brands_item_button-see-profile': 'View Profile',
  'home-page_brands_item_button-pending': 'Pending',
  'home-page_brands_item_button-disconnected': 'Disconnected',
  'home-page_brands_item_empty-brands': 'No associations yet',
  'home-page_brands_see_more': 'See more',
  'home-page_brands_see_brand': 'View Profile',
  'home-page_brands_no_more_results': 'No results found...',
  'home-page_brands_connect-modal_title': 'The request has been sent to the brand!',
  'home-page_brands_connect-modal_text1':
    'Hub4Retail will get in touch with the brand to associate it.',
  'home-page_brands_connect-modal_text2': 'We will be sending you an email updating you.',
  'home-page_brands-not-associated_title': 'Brands you sell not associated',
  'home-page_brands-not-associated_search-input-placeholder':
    'Search in unassociated brands',
  'home-page_brands-not-associated_selected-brand': 'selected brand',
  'home-page_brands-not-associated_selected-brands': 'selected brands',
  'home-page_brands-not-associated_select-all': 'Select all',
  'home-page_brands-not-associated_clear-select': 'Clear selection',
  'home-page_brands-not-associated_associate-btn': 'Associate brands',
  'home-page_my-brands_search-input-placeholder': 'Search in my brands',
  ////////////////////////////

  // ACCOUNT PAGE /////////////
  'profile-page_nav_profile': 'Profile',
  'profile-page_nav_company': 'Company Information',
  'profile-page_nav_main-contacts': 'Main Contacts',
  'profile-page_nav_my-brands': 'My Brands',
  'profile-page_nav_points-of-sale': 'Store',
  /////////////////////////////

  // DASHBOARDS //////////////
  'dashboard_empty-dashboard': 'Contact Hub4Retail to enable this service.',
  dashboard_dashboard_error:
    'Error loading the dashboard. If the problem persists, contact:',
  dashboard_maintenance_message: 'We are working for you!!',
  /////////////////////////////

  // HELMET ///////////////////
  signup: 'Sign up',
  home: 'Home',
  dashboard: 'Dashboard',
  multimedia: 'Multimedia',
  'multimedia-videos': 'Multimedia videos',
  'multimedia-images': 'Multimedia images',
  'multimedia-banners': 'Multimedia Banners',
  clients: 'Clients',
  profile: 'Profile',
  company: 'Company Information',
  'main-contacts': 'Main Contacts',
  'commercial-info': 'Commercial Information',
  'points-of-sale': 'Stores',
  catalog: 'Catalog',
  catalog_download: 'Download catalog',
  notifications: 'Notifications',
  helmet_settings: 'Settings',
  helmet_meta: 'Meta',
  helmet_users: 'Users',
  'helmet_sales-history': 'Sales History',
  'helmet_upload-sales': 'Upload Sales',
  /////////////////////////////

  // USERS ///////////////////////
  // Departments
  users_department_commercial: 'Commercial',
  users_department_administration: 'Administration',
  users_department_agent: 'Agent',
  users_department_owner: 'Owner',
  'users_department_store-manager': 'Store Manager',
  users_department_logistics: 'Logistics',
  users_department_marketing: 'Marketing',
  users_department_others: 'Others',
  ////////////////////////////////

  // SUPERADMIN //////////////////
  'super-admin_search-input_placeholder': 'Search...',
  ////////////////////////////////

  // ACCOUNT //////////////////
  account_profile_title: 'My Profile',
  'account-info-panel_title': 'General Information',
  'account-company-info-panel_title': 'Company Data',
  // Profile
  'account-info-panel_profile_name': 'Name',
  'account-info-panel_profile_lastname': 'Last Name',
  'account-info-panel_profile_email': 'Email',
  'account-info-panel_profile_phone-number': 'Phone Number',
  'account-info-panel_profile_sector': 'Departament',
  'account-info-panel_profile_security-data': 'Security Data',
  'account-info-panel_profile_change-password': 'Change Password',
  'account-info-panel_profile_pwd_password-label': 'New Password',
  'account-info-panel_profile_pwd_repeat-password-label': 'Repeat the New Password',
  'account-info-panel_profile_pwd_change-button': 'Change Password',
  'account-info-panel_profile_pwd_validation':
    'The password must be at least 8 characters and a maximum of 16, 1 number, 1 uppercase letter, and one lowercase letter.',
  'account-info-panel_profile_pwd_validation-match': 'The passwords do not match',
  'account-info-panel_profile_pwd_change-success': 'Password changed successfully.',
  'account-info-panel_profile_pwd_empty-inputs': 'Fill in the empty fields.',
  // Profile form
  'account-profile_form-input_name': 'Enter your name',
  'account-profile_form-input_name-validation': 'Please enter your name',
  'account-profile_form-input_lastname': 'Enter your last name',
  'account-profile_form-input_lastname-validation': 'Please enter your last name',
  'account-profile_form-input_email': 'Enter your email',
  'account-profile_form-input_email-validation-required': 'Please enter your email',
  'account-profile_form-input_email-validation': 'The email is not valid',
  'account-profile_form-input_sector': 'Enter the departament',
  'account-profile_form-input_sector-validation': 'Please enter the departament',
  'account-profile_form-input_phone-number': '',
  'account-profile_form-input_phone-number-validation': 'Please enter the phone number',
  // Company
  'account-info-panel_company_title': 'Company Data',
  'account-info-panel_company_cif': 'Tax ID',
  'account-info-panel_company_trade-name': 'Brand Name',
  'account-info-panel_company_business-name': 'Company Name',
  'account-info-panel_company_address': 'Address',
  'account-info-panel_company_postal-code': 'Postal Code',
  'account-info-panel_company_population': 'City',
  'account-info-panel_company_country': 'Country',
  'account-info-panel_company_province': 'Province',
  'account-info-panel_company_contact_name': 'Primary Contact',
  'account-info-panel_company_email': 'Email',
  'account-info-panel_company_phone-number': 'Phone Number',
  'account-info-panel_matas-connected_title': 'Metas Connected',
  'account-info-panel_active-connections_title': 'Active Connections',
  // Company form
  'account-company_form-input_trade-name': 'Enter the Brand Name',
  'account-company_form-input_trade-name-validation': 'Please enter the brand name',
  'account-company_form-input_business-name': 'Enter the Company Name',
  'account-company_form-input_business-name-validation': 'Please enter the company name',
  'account-company_form-input_country': 'Enter the country',
  'account-company_form-input_country-validation': 'Please enter the country',
  'account-company_form-input_population': 'Enter the City',
  'account-company_form-input_population-validation': 'Please enter the city',
  'account-company_form-input_address': 'Enter the address',
  'account-company_form-input_address-validation': 'Please enter your address',
  'account-company_form-input_postal-code': 'Enter the postal code',
  'account-company_form-input_postal-code-validation': 'Please enter the postal code',
  'account-company_form-input_cif': 'Enter the VAT (Tax Identification)',
  'account-company_form-input_cif-validation':
    'Please enter the VAT (Tax Identification)',
  'account-company_form-input_phone-number': 'Phone Number',
  'account-company_form-input_phone-number-validation': 'Please enter the phone number',
  'account-company_form-input_contact_name': "Enter the company's primary contact",
  'account-company_form-input_contact_name-validation-required':
    "Please enter the company's primary contact",
  'account-company_form-input_email': 'Enter the company email',
  'account-company_form-input_email-validation-required': 'Please enter your email',
  'account-company_form-input_email-validation': 'The email is not valid',
  'account-company_form-input_instagram': 'Enter Instagram URL',
  'account-company_form-input_youtube': 'Enter Youtube URL',
  'account-company_form-input_linkedin': 'Enter Linkedin URL',
  'account-company_form-input_facebook': 'Enter Facebook URL',
  'account-company_social-media-title': 'Social Media',
  'account-company_form-input_social-media-label': 'Social Media Name',
  'account-company_form-input_social-media-label-placeholder': 'Social Media Name',
  'account-company_form-input_social-media-label_validation':
    'Please, enter the social media name',
  'account-company_form-input_social-media-value': 'Social Media URL',
  'account-company_form-input_social-media-value-placeholder': 'Social Media URL',
  'account-company_form-input_social-media-value_validation':
    'Please, enter the social media URL',
  'account-company_form-input_social-media-add-button': 'Add Social Media',
  'account-company_form-input_social-media-accept-button_title': 'Update Social Media',
  'account-company_form-input_social-media-reject-button_title': 'Discard changes',
  'account-company_form-input_social-media-delete-button_title': 'Delete Social Media',
  'account-company_form-input_social-media-delete-button_popconfirm_title':
    'Delete Social Media',
  'account-company_form-input_social-media-delete-button_popconfirm_description':
    'Are you sure you want to delete this Social Media?',
  'account-company_form-input_social-media-delete-button_popconfirm_ok': 'Delete',
  'account-company_form-input_social-media-delete-button_popconfirm_cancel': 'Cancel',
  // Company drawer
  'account-company_drawer_title': 'Company Data',
  // Main Contacts
  'main-contacts_title': 'Main Contacts',
  'main-contacts_add-contact': '+ Add',
  'main-contacts_table_name': 'Name',
  'main-contacts_table_lastname': 'Last Name',
  'main-contacts_table_email': 'Email',
  'main-contacts_table_phone-number': 'Phone Number',
  'main-contacts_table_department': 'Department',
  'main-contacts_table_pop-confirm_title': 'Delete contact',
  'main-contacts_table_pop-confirm_description':
    'Are you sure you want to delete this contact?',
  'main-contacts_table_pop-confirm_btn-accept': 'Delete',
  'main-contacts_table_pop-confirm_btn-cancel': 'Cancel',
  // Main Contacts drawer
  'main-contacts_drawer_create-title': 'Create contact',
  'main-contacts_drawer_update-title': 'Update contact',
  // Main Contacts Form
  'main-contacts_form-label_name': 'Name',
  'main-contacts_form-label_lastname': 'Last Name',
  'main-contacts_form-label_email': 'Email',
  'main-contacts_form-label_phone-number': 'Phone Number',
  'main-contacts_form-label_department': 'Department',
  'main-contacts_form-input_name': 'Enter the name',
  'main-contacts_form-input_lastname': 'Enter the last name',
  'main-contacts_form-input_email': 'Enter the email',
  'main-contacts_form-input_department': 'Enter the department',
  'main-contacts_form_sector_label': 'Department',
  'main-contacts_form-input_sector': 'Enter the department',
  'main-contacts_form-input_sector_validation': 'Enter the department',
  'main-contacts_form-input_phone-number': 'Enter the phone number',
  'main-contacts_form-input-validation_name': 'Please enter the name',
  'main-contacts_form-input-validation_lastname': 'Please enter the last name',
  'main-contacts_form-input-validation_email': 'The email is not valid',
  'main-contacts_form-input-validation-required_email': 'Please enter the email',
  'main-contacts_form-input-validation_department': 'Please enter the department',
  'main-contacts_form-input-validation_phone-number': 'Please enter the phone number',
  // My brands
  'my-brands_title': 'My Brands',
  'my-brands_add-to-my-brands': '+ Add',
  'my-brands_table_brand-name': 'Brand Name',
  'my-brands_table_main-contact': 'Main Contact',
  'my-brands_table_phone-number': 'Phone Number',
  'my-brands_table_email': 'Email',
  'my-brands_table_address': 'Address',
  'my-brands_table_city': 'City',
  'my-brands_table_pop-confirm_title': 'Unlink from my brands',
  'my-brands_table_pop-confirm_description1': 'Are you sure you want to unlink',
  'my-brands_table_pop-confirm_description2': 'from my brands?',
  'my-brands_table_pop-confirm_btn-accept': 'Unlink',
  'my-brands_table_pop-confirm_btn-cancel': 'Cancel',
  'my-brands_table_disassociate-tooltip-title': 'Unlink brand',
  // My brands drawer
  'my-brands_drawer_title': 'Search and Add Brands',
  'my-brands_drawer_add-title': 'Create New Brand',
  'my-brands_drawer_go-back-to-brands': 'Go Back',
  'my-brands_drawer_form_address': 'Address',
  'my-brands_drawer_form_address-street-number': 'Street and Number',
  'my-brands_drawer_form_contact': 'Contact Person',
  'my-brands_drawer_form_contact_name': 'Name',
  'my-brands_drawer_form_contact_lastname': 'Last Name',
  'my-brands_drawer_search-placeholder': 'Search',
  'my-brands_drawer_more-brands': "Want a brand you don't see here?",
  'my-brands_drawer_close-drawer': 'Close',
  'my-brands_drawer_brand_associate-btn': 'Associate',
  'my-brands_drawer_brand_see-profile-btn': 'View Profile',
  'my-brands_disassociate-modal_title': 'Unlink',
  'my-brands_disassociate-multiple-modal_title': 'Unlink Brands',
  'my-brands_disassociate-multiple-modal_content':
    'Are you sure you want to unlink the selected brands and all their points of sale?',
  'my-brands_disassociate-multiple-modal_popconfirm-description':
    'Are you sure you want to unlink the selected brands?',
  'my-brands_disassociate-modal_on-ok': 'Unlink',
  'my-brands_disassociate-modal_on-cancel': 'Cancel',
  'my-brands_disassociate-modal_content':
    'Are you sure you want to unlink this brand and all its outlets?',
  /////////////////////////////

  // MULTIMEDIA ///////////////
  'multimedia_nav-videos': 'Videos',
  'multimedia_nav-images': 'Images',
  'multimedia_sort-btn': 'Sort by',
  'multimedia_filter-btn': 'Filters',
  'multimedia_apply-filters-btn': 'Apply filters',
  'multimedia_search-input-placeholder': 'Search by brand, season, country, date',
  'multimedia_search-results': 'Results',
  'multimedia_sort-last-favorites': 'Favorites',
  multimedia_download: 'Download',
  multimedia_download_started: 'The download has started',
  multimedia_open: 'Open',
  'multimedia_link-copied': 'Link copied to clipboard',
  'multimedia_link-copy-button': 'Copy link',
  // Sort by
  'multimedia_sort-last-aggregates': 'Last added',
  'multimedia_sort-best-seller': 'Best seller',
  'multimedia_sort-less-sold': 'Least sold',
  'multimedia_sort-best-pvp_great-minor': 'Price: high to low',
  'multimedia_sort-best-pvp_minor-great': 'Price: low to high',
  'multimedia_sort-best-pvi_great-minor': 'PVI: high to low',
  'multimedia_sort-best-pvi_minor-great': 'PVI: low to high',
  'multimedia_sort-by-color': 'By color A-Z',
  'multimedia_sort-by-reference': 'By reference A-Z',
  // Images
  'multimedia_images_popover-edit': 'Edit',
  'multimedia_images_popover-remove': 'Remove',
  'multimedia_images_popover-yes': 'Yes',
  'multimedia_images_popover-no': 'No',
  // Filter
  'multimedia_filter-remove-filters': 'Clear Filters',
  'multimedia_filter-season': 'Season',
  'multimedia_filter-division': 'Division',
  'multimedia_filter-genders': 'Gender',
  'multimedia_filter-visualization': 'Visualization',
  'multimedia_filter-image-rights': 'Image Rights',
  'multimedia_filter-country': 'Country',
  'multimedia_filter-tags': 'Tags',
  'multimedia_filter-favorites': 'My Favorites',
  'multimedia_filter-start': 'Broadcastable From',
  'multimedia_filter-end': 'Broadcastable Until',
  // Dragger
  'multimedia_upload-images-manually_dragger_msg-error': 'Accepted Files',
  'multimedia_upload-images-manually_dragger_text-1': 'Click or drag and drop an image',
  // Upload image manually
  'multimedia_upload-images-manually_form-item_selection': 'Select',
  'multimedia_upload-images-manually_form-item_title': 'Title',
  'multimedia_upload-images-manually_form-item_title-required':
    'Please enter the image title',
  'multimedia_upload-images-manually_form-item_name': 'Technical Name',
  'multimedia_upload-images-manually_form-item_name-required':
    'Please enter the image name',
  'multimedia_upload-images-manually_form-item_description': 'Description',
  'multimedia_upload-images-manually_form-item_description-required':
    'Please enter the description',
  'multimedia_upload-images-manually_form-item_divisions': 'Divisions',
  'multimedia_upload-images-manually_form-item_season': 'Season',
  'multimedia_upload-images-manually_form-item_language': 'Language',
  'multimedia_upload-images-manually_form-item_genders': 'Genders',
  'multimedia_upload-images-manually_form-item_products': 'Products',
  'multimedia_upload-images-manually_form-item_catalog_id': 'Catalog ID',
  'multimedia_upload-images-manually_form-item_catalog_id_text-1':
    'Field associated with chronos to define its advertising circuit',
  'multimedia_upload-images-manually_form-item_catalog_id_text-2':
    'Contact Hub4Retail to activate Chronos',
  'multimedia_upload-images-manually_form-item_usage': 'Content Use',
  'multimedia_upload-images-manually_form-item_visualization': 'Visualization',
  'multimedia_upload-images-manually_form-item_tags': 'Tags',
  'multimedia_upload-images-manually_form-item_tags_text-1': 'Create your own tags',
  'multimedia_upload-images-manually_form-item_start': 'Broadcastable From',
  'multimedia_upload-images-manually_form-item_end': 'Broadcastable Until',
  'multimedia_upload-images-manually_form-item_copyright':
    'Is this video subject to image rights?',
  'multimedia_upload-images-manually_form-item_enable_download':
    'Do you allow downloading?',
  'multimedia_upload-images-manually_form-item_countries': 'Countries',
  'multimedia_upload-images-manually_form-item_add': 'Add',
  'multimedia_upload-images-manually_form-item_next': 'Next',
  'multimedia_upload-images-manually_form-item_yes': 'Yes',
  'multimedia_upload-images-manually_form-item_no': 'No',
  // Modal manually
  'multimedia_upload-images-manually_modal_title': 'Update Image',
  'multimedia_upload-images-manually_modal_step-1': 'Select an image',
  'multimedia_upload-images-manually_modal_step-2': 'Complete the data',
  'multimedia_upload-images-manually_modal_step-2-msg': 'Image title',
  'multimedia_upload-images-manually_modal_step-3-msg': 'Image uploaded successfully',
  // Upload images CSV
  'multimedia_upload-images_button': 'Upload Images',
  'multimedia_upload-images_step-1': 'Select Images',
  'multimedia_upload-images_step-1_dragger_text-1': 'Click or drag and drop image files',
  'multimedia_upload-images_step-1_dragger_text-2': 'Keep the following in mind...',
  'multimedia_upload-images_step-1_dragger_text-3': 'Save the images with the name',
  'multimedia_upload-images_step-1_dragger_text-4': 'reference_color_image number',
  'multimedia_upload-images_step-2': 'Upload CSV',
  'multimedia_upload-images_step-2_dragger_text-1': 'Click or drag and drop the CSV file',
  'multimedia_upload-images_step-3': 'Upload Completed!',
  'multimedia_upload-images_step-3-text': 'image files uploaded',
  'multimedia_upload-images_modal_title': 'Upload Images',
  'multimedia_upload-images_modal_btn-back': 'Back',
  'multimedia_upload-images_modal_btn-next': 'Next',
  'multimedia_upload-images_modal_btn-close': 'Close',
  ////////////////////////////

  // BRAND USERS /////////////////
  'brand-user_title': 'Users',
  'brand-user_description': 'Control how members of your team interact with Hub4Retail.',
  'brand-user_create-user': 'Add user',
  'brand-user_modal_title-create': 'Add user',
  'brand-user_modal_title-edit': 'Edit user',
  'brand-user_modal_input_name': 'Name',
  'brand-user_modal_input_lastname': 'Last name',
  'brand-user_modal_input_email': 'Email',
  'brand-user_modal_input_points-of-sale': 'Points of sale',
  'brand-user_modal_input_password': 'Password',
  'brand-user_modal_input_btn-save': 'Save',
  'brand-user_modal_input_btn-cancel': 'Cancel',
  'brand-user_table_name': 'Name',
  'brand-user_table_email': 'Email',
  'brand-user_table_role': 'Role',
  'brand-user_table_last-session': 'Last session',
  'brand-user_table_last-session-never': 'Never',
  'brand-user_delete-popconfirm_title': 'Delete the user',
  'brand-user_delete-popconfirm_description':
    'Are you sure you want to delete this user?',
  'brand-user_delete-popconfirm_confirm': 'Yes',
  'brand-user_delete-popconfirm_cancel': 'No',
  ////////////////////////////////

  // CATALOG //////////////////
  'catalog_add-product': 'Add product',
  catalog_ProductList_results: 'Results',
  'catalog_no-more-results': 'No results found...',
  'catalog_tags_delete-all': 'Delete all',
  'catalog_loading-filters': 'Loading filters',
  'catalog_selected-brands-title': 'Selected brands',
  'catalog_brand-tag': 'Brand',
  'catalog_brands-tag': 'Brands',
  // Sort by
  'catalog_sort-by_dateAsc': 'Latest added',
  'catalog_sort-by_dateDesc': 'Oldest',
  'catalog_sort-by_unitsAsc': 'Best-selling',
  'catalog_sort-by_unitsDesc': 'Least sold',
  'catalog_sort-by_nameAsc': 'Name a-z',
  'catalog_sort-by_nameDesc': 'Name z-a',
  'catalog_sort-by_colorAsc': 'Color a-z',
  'catalog_sort-by_colorDesc': 'Color z-a',
  'catalog_sort-by_pviAsc': 'PVI from lowest to highest',
  'catalog_sort-by_pviDesc': 'PVI from highest to lowest',
  'catalog_sort-by_pvprAsc': 'PVPR from lowest to highest',
  'catalog_sort-by_pvprDesc': 'PVPR from highest to lowest',
  'catalog_sort-by_referenceAsc': 'Reference a-z',
  'catalog_sort-by_referenceDesc': 'Reference z-a',
  'catalog_sort-all-brands': 'All Brands',
  'catalog_brand-feed-btn': 'Brand Profile',
  // Catalog download
  'catalog_search-input-placeholder': 'Search by reference, color or description',
  'catalog_download_alert_brand-required': 'Please select a brand',
  'catalog_download_alert_get-filters-error': 'Error getting filters',
  'catalog_download_alert_dowloading-compressed-files': 'Downloading compressed files...',
  'catalog_download_progress-images': 'Images',
  'catalog_download_success-msg': 'Images downloaded successfully',
  'catalog_download_success-msg-imgs-and-csv': 'CSV and images downloaded successfully',
  'catalog_download_csv-msg-without-images': 'CSV downloaded successfully',
  'catalog_download_csv-msg-pending-images':
    'CSV downloaded successfully, but there are pending images',
  'catalog_download_csv-msg-pending-images-navigate':
    'CSV downloaded successfully, but there are pending images. You can continue browsing while downloading',
  'catalog_download_apply-changes-btn': 'Apply changes',
  'catalog_download_cancel-filter-btn': 'Cancel',
  'catalog_download_cancel-values-selection-btn': 'Clear selection',
  'catalog_download_cancel-values-download-btn': 'Cancel download',
  'catalog_download_clear-filters-btn': 'Clear filters',
  catalog_download_result: 'result',
  catalog_download_results: 'results',
  catalog_download_columns_brand: 'Brand',
  catalog_download_columns_reference: 'Reference',
  catalog_download_columns_ean: 'EAN',
  catalog_download_columns_size: 'Size',
  catalog_download_columns_name: 'Name',
  catalog_download_columns_color: 'Color description',
  catalog_download_columns_color_code: 'Color code',
  catalog_download_columns_pvi: 'PVI',
  catalog_download_columns_pvpr: 'PVPR',
  catalog_download_columns_division: 'Division',
  catalog_download_columns_gender: 'Gender',
  catalog_download_columns_season: 'Season',
  catalog_download_columns_segmentation: 'Segmentation',
  catalog_download_columns_family: 'Family',
  catalog_download_columns_material: 'Composition',
  catalog_download_columns_images: 'Images',
  'catalog_download_columns_all-images': 'All products',
  'catalog_download_columns_with-images': 'With images',
  'catalog_download_columns_without-images': 'Without images',
  catalog_download_columns_favorites: 'Favorites',
  'catalog_download_columns_column-search_search': 'Search',
  'catalog_download_columns_column-search_reset': 'Reset',
  'catalog_download_columns_column-search_close': 'Close',
  'catalog_download_list_select-all': 'Select all',
  'catalog_download_list_clear-all': 'Clear selection',
  'catalog_download_list_download-btn': 'Download',
  'catalog_download_list_download-images-btn': 'Download images',
  catalog_download_list_item: 'item',
  catalog_download_list_items: 'items',
  'catalog_download_list_select-order_popover-title': 'Select and order',
  //////////////////////////////

  // PRODUCT ///////////////////
  product_infoBox_codeEAN: 'Codes',
  product_collection_title: 'The Entire Collection',
  product_infoProduct_other_colors: 'Other Colors',
  product_infoProduct_currency: '€',
  product_infoProduct_sizes: 'Sizes and prices',
  product_infoProduct_EAN_size: 'Size',
  product_infoProduct_EAN_code: 'EAN Code',
  product_collapse_title_key1: 'Sizes',
  product_collapse_title_key2: 'GENERAL INFORMATION',
  product_collapse_title_key3: 'COMPOSITION',
  'product_card-color': 'Color',
  'product_mobile_product-details_size': 'Size',
  'product_mobile_product-details_info': 'General Information',
  'product_mobile_product-details_ean-codes': 'EAN Codes',
  'product_mobile_product-details_ean-code': 'EAN Code',
  'product_mobile_product-details_composition': 'Composition',
  'product_related-videos_title': 'Multimedia Catalog',
  'product_related-videos_subtitle': 'Related Videos',
  'product_related-products_title': 'You might also be interested in...',
  'product_card_sold-units': 'Sold units:',
  //////////////////////////////

  // POINTS OF SALE ///////////
  'points-of-sale_title': 'Stores',
  'points-of-sale_add-point-of-sale': '+ Add',
  'points-of-sale_empty-points-of-sale': 'There are no points of sale yet',
  'points-of-sale_table_brand': 'Brand',
  'points-of-sale_table_connection': 'Connection',
  'points-of-sale_table_recive-data': 'Receive Data',
  'points-of-sale_table_share-data': 'Share Data',
  'points-of-sale_drawer_title': 'Stores',
  'points-of-sale_drawer_name_label': 'Name',
  'points-of-sale_drawer_add-edit-sale-point_point-of-sale-type': 'Type of Point of Sale',
  'points-of-sale_drawer_add-edit-sale-point_point-of-sale-type_physical':
    'Physical Store',
  'points-of-sale_drawer_add-edit-sale-point_point-of-sale-type_online': 'Online Store',
  'points-of-sale_drawer_add-edit-sale-point_category': 'Category',
  'points-of-sale_drawer_add-edit-sale-point_category_input-validation':
    'Please enter the point of sale category',
  'points-of-sale_drawer_exchange_label': 'Currency Type',
  'points-of-sale_drawer_add-edit-sale-point_exchange_input-validation':
    'Please, enter the currency type',
  'points-of-sale_drawer_associate-brand_button_title':
    'Add to brands that the point of sale works with and associate it with my brands',
  'points-of-sale_drawer_add-brand_button_title':
    'Add to brands that the point of sale works with',
  'points-of-sale_drawer_selected-brand_button_title': 'Associate with my brands',
  'points-of-sale_drawer_associate-all-brands_modal-title-1': 'Requesting association to',
  'points-of-sale_drawer_associate-all-brands_modal-title-2': 'of',
  'points-of-sale_drawer_associate-all-brands_modal-title-3': 'brands',
  'points-of-sale_drawer_associate-all-brands_modal-title-4': 'brand',
  'points-of-sale_drawer_associate-all-brands_modal-button-1': 'associated brands',
  'points-of-sale_drawer_associate-all-brands_modal-button-2': 'Applying changes',
  'points-of-sale_drawer_associate_button_associate-all': 'unassociated',
  'points-of-sale_drawer_associate_button_associate-all-1': 'Associate',
  'points-of-sale_drawer_associate_button_associate-all-2': 'Brands',
  'points-of-sale_drawer_expand-less': 'See less',
  'points-of-sale_drawer_expand-more': 'See all',
  'points-of-sale_drawer_sub-title-1': 'Brands that the point of sale works with',
  'points-of-sale_drawer_sub-title-2': 'Add brands to the point of sale of',
  'points-of-sale_drawer_associate-all_popconfirm_title-1':
    'Do you want to send association request to',
  'points-of-sale_drawer_associate-all_popconfirm_title-2': 'this brand',
  'points-of-sale_drawer_associate-all_popconfirm_title-3': 'these brands',
  'points-of-sale_drawer_associate-all_popconfirm_description-1': 'more',
  'points-of-sale_drawer_associate-all_popconfirm_on-ok': 'Associate',
  'points-of-sale_drawer_associate-all_popconfirm_on-cancel': 'Cancel',
  'points-of-sale_drawer_associate-all_modal_title': 'Changes made in',
  'points-of-sale_drawer_associate-all_modal_on-ok': 'Save changes',
  'points-of-sale_drawer_associate-all_modal_on-cancel': 'Discard changes',
  'points-of-sale_drawer_associate-all_modal_description-1':
    'There are changes made in the point of sale of',
  'points-of-sale_drawer_associate-all_modal_description-2':
    'Do you want to save the changes?',
  'points-of-sale_drawer_name_input': 'Enter a name',
  'points-of-sale_drawer_name_input-validation': 'Please, enter a name',
  'points-of-sale_drawer_web_label': 'Web',
  'points-of-sale_drawer_web_input': 'Enter a website',
  'points-of-sale_drawer_address_label': 'Address',
  'points-of-sale_drawer_address_input': 'Enter an address',
  'points-of-sale_drawer_address_input-validation': 'Please, enter an address',
  'points-of-sale_drawer_postal-code_label': 'Postal Code',
  'points-of-sale_drawer_postal-code_input': 'Enter a postal code',
  'points-of-sale_drawer_postal-code_input-validation': 'Please, enter a postal code',
  'points-of-sale_drawer_city_label': 'City',
  'points-of-sale_drawer_city_input': 'Enter a city',
  'points-of-sale_drawer_city_input-validation': 'Please, enter a city',
  'points-of-sale_drawer_country_label': 'Country',
  'points-of-sale_drawer_province_label': 'State/Province',
  'points-of-sale_drawer_province_input': 'Enter a state/province',
  'points-of-sale_drawer_province_input-validation': 'Please, enter a state/province',
  'points-of-sale_drawer_region_label': 'Region',
  'points-of-sale_drawer_region_input': 'Enter a region',
  'points-of-sale_drawer_region_input-validation': 'Please, enter a region',
  'points-of-sale_drawer_tel-sufix_label': 'Phone Number',
  'points-of-sale_drawer_tel_input': 'Enter a phone number',
  'points-of-sale_drawer_tel_input-validation': 'Please, enter a phone number',
  'points-of-sale_drawer_data-share_label': 'Share data',
  'points-of-sale_drawer_receiving_label': 'Receive data',
  'points-of-sale_drawer_connected_label': 'Connection',
  'points-of-sale_drawer_brands_title': 'Search and add your brands at this store',
  'points-of-sale_drawer_brands_input-placeholder': 'Search',
  'points-of-sale_drawer_brands_more-brands': "Want a brand you don't see here?",
  'points-of-sale_drawer_add-point-of-sale_title': 'Add point of sale',
  'points-of-sale_drawer_add-brand_title': 'Add new brand',
  'points-of-sale_drawer_edit-point-of-sale_title': 'Edit Point of Sale',
  'points-of-sale_drawer_brands-to-associate-selected': 'Brands from',
  'points-of-sale_drawer_brands-to-associate-selected-1': '+ Association request',
  'points-of-sale_drawer_brands-selected': 'Brands at',
  'points-of-sale_drawer__brands--brand__add-brand': 'Add',
  'points-of-sale_drawer__brands--brand__asociate-brand': 'Associate',
  'points-of-sale_drawer__edit-button': 'Edit',
  'points-of-sale_add-edit-sale-point_area': 'Commercial area (M2)',
  'points-of-sale_add-edit-sale-point_center-code': 'Center code',
  'points-of-sale_add-edit-sale-point_main-contact': 'Main Contact',
  /////////////////////////////

  /// THANKS PAGE //////////////
  ThanksForRegister__register: 'BRAND REGISTRATION',
  ThanksForRegister__title: 'Thank you for your interest in Hub4Retail!',
  ThanksForRegister__subtitle:
    'Your request is being processed and we will get back to you shortly.',
  ThanksForRegister__button: 'Go to the website',
  /////////////////////////////

  /// REGISTER BRAND ///////////
  RegisterBrand__title1: 'Ready to start',
  RegisterBrand__title2: 'take your business to the',
  RegisterBrand__title3: 'next level?',
  RegisterBrand__subtitle: 'Platform designed for:',
  RegisterBrand__ex1: 'Improve collaboration and sharing tools.',
  RegisterBrand__ex2: 'Ensure data security at all times.',
  RegisterBrand__ex3: 'Increase revenue margins.',
  RegisterBrand__button: 'Back',
  RegisterBrand__register: 'Registration',
  registerBrand__form__name: 'First name',
  registerBrand__form__lastName: 'Last name',
  registerBrand__form__business: 'Corporate name',
  registerBrand__form__company: 'Trade name',
  registerBrand__form__phone: 'Website',
  registerBrand__form__email: 'Phone',
  registerBrand__form__web: 'Corporate email',
  registerBrand__form__description: 'Would you like to tell us more?',
  registerBrand__form__selector: 'Sector',
  'registerBrand__form__say-more': 'Would you like to tell us more?',
  registerBrand__form__selector__optionDefault: '',
  registerBrand__form__selector__option1: 'Footwear',
  registerBrand__form__selector__option2: 'Accessories',
  registerBrand__form__selector__option3: 'Home-Garden',
  registerBrand__form__selector__option4: 'Beauty',
  registerBrand__form__selector__option5: 'Electronics',
  registerBrand__form__selector__option6: 'Sports',
  registerBrand__form__selector__option7: 'Automotive',
  registerBrand__form__selector__option8: 'Food',
  registerBrand__form__selector__option9: 'Other',
  /////////////////////////////

  // BRAND PAGE //////////
  'brand-page_send-association-btn': 'Send association request',
  'brand-page_history-timeline_title': 'History',
  'brand-page_history-points-of-sale_title': 'Stores and Connection',
  'brand-page_history-points-of-sale_total': 'Total',
  'brand-page_history-points-of-sale_name': 'Name',
  'brand-page_history-points-of-sale_address': 'Address',
  'brand-page_history-points-of-sale_city': 'City',
  'brand-page_history-points-of-sale_recive-data': 'Receive Data',
  'brand-page_history-points-of-sale_recive-data_active': 'Active',
  'brand-page_history-points-of-sale_recive-data_inactive': 'Inactive',
  'brand-page_history-points-of-sale_status_associate': 'Associated',
  'brand-page_history-points-of-sale_status_pending': 'Pending',
  'brand-page_history-points-of-sale_status_no-associate': 'Not associated',
  'brand-page_history-points-of-sale_status': 'Status',
  'brand-page_history-points-of-sale_status_createdAt': 'Date',
  'brand-page_history-points-of-sale_share-data': 'Share Data',
  'brand-page_history-points-of-sale_associated': 'Associated',
  'brand-page_history-points-of-sale_not-associated': 'Not associated',
  'brand-page_history-active-contacts_name': 'Name',
  'brand-page_history-active-contacts_email': 'Email',
  'brand-page_history-active-contacts_phone-number': 'Phone Number',
  'brand-page_history-active-contacts_department': 'Department',
  'brand-page_history-active-seasons_title': 'Active Seasons',
  'brand-page_history-active-contacts_title': 'Primary Contacts',
  'brand-page_history-contacts_popover-title': 'Contact Information',
  'brand-page_history-contacts_popover-content_name': 'Name:',
  'brand-page_history-contacts_popover-content_surname': 'Surname:',
  'brand-page_history-contacts_popover-content_email': 'Email:',
  'brand-page_history-contacts_popover-content_phone-number': 'Phone Number:',
  'brand-page_history-contacts_popover-content_sector': 'Sector:',
  ////////////////////////

  // BRAND FEED PAGE ////////
  'brand-feed_page_info_title': 'About Us',
  'brand-feed_page_buttons_connect': 'Connect',
  'brand-feed_page_videos_title': 'Multimedia Catalog',
  'brand-feed_page_videos_subtitle': 'Related Videos',
  ///////////////////////////

  // SETTINGS PAGE ////////
  'settings-page_nav_chronos': 'Chronos',
  'settings-page_nav_devices': 'Devices',
  'settings-page_nav_touch-screen': 'Touch Screens',
  'settings-page_nav_users': 'Users',
  'settings-page_nav_system-settings': 'System Settings',
  'settings-page_nav_meta': 'Meta',
  'settings-page_nav_billing': 'Billing',
  'settings-page_nav_support': 'Support',
  // Users page
  'settings-page_users-page_create-user-btn': 'Create User',
  /////////////////////////

  // NOTIFICATIONS /////////////////
  // Notifications Page
  'notifications-page_notifications-title': 'Notifications',
  'notifications-page_alerts-title': 'Alerts',
  'notifications_btn-name_catalog': 'View Catalog',
  'notifications_btn-name_profile': 'View Profile',
  'notifications_list_show-all': 'All',
  'notifications_list_show-unreaded': 'Unread',
  'notifications_list_per-page-select': 'per page',
  // Popover
  notifications_popover_title: 'NOTIFICATIONS',
  'notifications_popover_empty-notifications': 'No notifications',
  'notifications_popover_btn-name_catalog': 'View Catalog',
  'notifications_popover_btn-name_profile': 'View Profile',
  'notifications_popover_item_mark-as-readed_msg': 'Marked as read',
  'notifications_popover_item_mark-as-unreaded_msg': 'Marked as unread',
  'notifications_popover_item_mark-as-readed': 'Mark as read',
  'notifications_popover_item_mark-as-unreaded': 'Mark as unread',
  'notifications_popover_all-notifications-btn': 'All notifications',
  notifications_popover_accept: 'Accept',
  notifications_popover_reject: 'Reject',
  alerts_popover_title: 'ALERTS',
  'alerts_popover_empty-notifications': 'No alerts',
  'alerts_popover_all-notifications-btn': 'All alerts',
  ///////////////////////////////////

  // SALES ///////////////////////////
  'sales_csv-info_title': 'CSV Information',
  'sales_csv-info_columns': 'Columns:',
  'sales_csv-info_rows': 'Rows:',
  'sales_csv-info_separator': 'Separator:',
  'sales_csv-info_headers': 'Headers:',
  'sales_csv-info_errors': 'Errors:',
  // Add sales CSV
  'sales_add-sales-csv-input-title': 'Upload CSV',
  'sales_add-sales-csv_warning-msg-file-pnly-contain-a-header':
    'The file only contains a header',
  'sales_add-sales-csv-error-column-mismatch': 'Some lines do not match the CSV headers',
  'sales_add-sales-xlsx-input-title': 'Upload XLSX',
  'sales_add-sales-xlsx_select-row_description':
    'Select the row that contains the headers',
  'sales_add-sales-xlsx_select-row_input-placeholder': 'Row number',
  'sales_add-sales-csv-s2-text-1': 'Upload CSV or XLSX sales',
  'sales_add-sales-csv-s2-text-2': 'Create or select a column mapping',
  // Handle not matched points of sale
  'sales_csv-upload_not-matched-points-of-sale_title': 'Unmatched Points of Sale',
  'sales_csv-upload_not-matched-points-of-sale_description':
    "The following points of sale do not match the company's points of sale. Please select a point of sale from the list for each unmatched point of sale.",
  'sales_csv-upload_not-matched-points-of-sale_checking-matching':
    'Checking points of sale...',
  // My csv mappings
  'sales_csv-mappings_title': 'Matching Map',
  'sales_csv-mappings_title-create': 'Create a map for the CSV',
  'sales_csv-mappings_save-file_success': 'Sales file successfully uploaded',
  'sales_csv-mappings_send-file-btn': 'Upload Sales',
  // Create mapping form
  'sales_csv-mapping-form_title': 'Select CSV headers to create a map.',
  'sales_csv-mapping-form_columns-without-reference': 'Unreferenced CSV Columns:',
  'sales_csv-mapping-form_periodicity': 'Periodicity',
  'sales_csv-mapping-form_periodicity_required': 'Periodicity is required',
  'sales_csv-mapping-form_periodicity_dairy': 'Daily',
  'sales_csv-mapping-form_periodicity_weekly': 'Weekly',
  'sales_csv-mapping-form_periodicity_biweekly': 'Biweekly',
  'sales_csv-mapping-form_save-map-btn': 'Save Map',
  'sales_csv-mapping-form_onfinish_field-required-msg':
    'Fields marked with * are required',
  'sales_csv-mapping-form_onfinish_map-points-of-sale-required':
    'All points of sale must be mapped',
  // Mapping table
  'sales_csv-mapping-table_column-1_label': 'Sales Fields',
  'sales_csv-mapping-table_column-2_label': 'CSV Columns',
  'sales_csv-mapping-table_column-2_select-label': 'Select a column',
  'sales_csv-mapping-table_column-2_select-label-multiple': 'Select one or more columns',
  'sales_csv-mapping-table_column-2_select-format_label': 'Select a format',
  'sales_csv-mapping-table_column-3_label': 'Resulting Data',
  // MyCSVMappingInfoPopover
  'sales_csv-mapping-info-popover_periodicity': 'Periodicity:',
  'sales_csv-mapping-info-popover_periodicity-days': 'days',
  'sales_csv-mapping-info-popover_date-format': 'Date Format:',
  'sales_csv-mapping-info-popover_points-of-sale-mapped': 'Mapped Points of Sale:',
  'sales_csv-mapping-info-popover_references': 'References',
  'sales_csv-mapping-info-popover_tags': 'Tags',
  'sales_csv-mapping-info-popover_columns-without-references': 'Unreferenced Columns',
  'sales_csv-mapping-info-popover_popconfirm_title':
    'Are you sure you want to delete this map?',
  'sales_csv-mapping-info-popover_popconfirm_btn': 'Delete Map',
  'sales_csv-mapping-info-popover_popconfirm_ok': 'Yes',
  'sales_csv-mapping-info-popover_popconfirm_cancel': 'No',
  // SalesHistoryPage
  'sales-history_page_title': 'Sales Upload History',
  'sales-history_page_upload-btn': 'Upload Sales',
  'sales-history_page_table_column-1_title': 'Upload Date',
  'sales-history_page_table_column-2_title': 'File Name',
  'sales-history_page_table_column-3_title': 'Status',
  'sales-history_page_table_column-3_processed': 'Processed',
  'sales-history_page_table_column-3_pending': 'Pending',
  'sales-history_page_table_column-4_popover_title': 'Delete Sales Upload',
  'sales-history_page_table_column-4_popover_description':
    'Are you sure you want to delete the sales upload?',
  'sales-history_page_table_column-4_delete-btn': 'Delete',
  'sales-history_page_table_column-4_dowload-processed-success':
    'Processed file correctly downloaded',
  'sales-history_page_table_column-4_dowload-error': 'Error Downloading File',
  'sales-history_page_table_column-4_dowload-original-success':
    'Original file downloaded successfully',
  'sales-history_page_table_column-4_dowload-processed-file': 'Download processed file',
  'sales-history_page_table_column-4_dowload-original-file': 'Download original file',
  'sales-history_page_table_original-name': 'Original file name',
  'sales-history_page_table_upload-type': 'Upload type',
  'sales-history_page_table_file-type': 'File type',
  'sales-history_page_table_pending-mapping-title': 'Pending mapping',
  'sales-history_page_table_mapping-error-title': 'An error has occurred',
  'sales-history_page_table_download-file-error-msg': 'Error downloading the file',
  'sales-history_page_table_download-file-format-not-supported':
    'File format not supported',
  'sales-history_page_table_create-mapping-label': 'Create mapping',
  'sales-history_page_table_info-popover_title': 'File lines where errors are occurring',
  'sales-history_page_table_info-popover_contact-with-us':
    'If the problem persists, contact the support team (help@hub4retail.com) and provide this issue ID number:',
  'sales-history_page_table_info-popover_UNKNOWN_CHAR':
    'A character that does not match the specified encoding in the file has been found.',
  'sales-history_page_table_info-popover_PTO_MISSING': 'Pending point of sale mapping.',
  'sales-history_page_table_info-popover_MISSING_PTO_MAP':
    'Pending point of sale mapping.',
  'sales-history_page_table_info-popover_SKIP': 'Processing failed, skipping.',
  'sales-history_page_table_info-popover_MAP_ID_NOT_FOUND':
    'No map matches, create the map to process the file.',
  'sales-history_page_table_info-popover_MAPS_NOT_FOUND':
    'No maps match, create the map to process the file.',
  'sales-history_page_table_info-popover_MAP_NOT_FOUND':
    'No map matches, create the map to process the file.',
  'sales-history_page_table_info-popover_BRAND_CLIENT_ID_NOT_FOUND':
    'There is a file created in a folder with a brand or client ID that no longer exists in the database.',
  'sales-history_page_table_info-popover_FOLDER_NOT_FOUND':
    'The SFTP user record contains a brand or client ID, but the folder with this ID does not exist in Amazon S3, so it is not possible to retrieve the files.',
  'sales-history_page_table_info-popover_FOLDER_LIST_UNAVAILABLE':
    'Unable to connect to Amazon S3. The AWS user has been deleted, or permissions have changed, making the connection to S3 impossible.',
  'sales-history_page_table_info-popover_FILES_NOT_FOUND':
    'The folder for that brand or client is empty. No files to process.',
  'sales-history_page_table_info-popover_NO_CONTENT_TYPE':
    'The file uploaded to AWS does not have a defined content type.',
  'sales-history_page_table_info-popover_NO_ENCODING':
    'The file uploaded to AWS does not have a defined content type.',
  'sales-history_page_table_info-popover_NO_FILE_METADATA':
    'Unable to read the file content.',
  'sales-history_page_table_info-popover_CANNOT_CREATE_BUFFER':
    'Unable to convert the file data stream to a buffer.',
  'sales-history_page_table_info-popover_HEADERS_NOT_FOUND': 'File headers not found.',
  'sales-history_page_table_info-popover_SIGNATURE_MISMATCH':
    'No map matches, create the map to process the file.',
  'sales-history_page_table_info-popover_SEPARATOR_NOT_FOUND':
    'No separator found in the map.',
  'sales-history_page_table_info-popover_SEPARATOR_MISMATCH':
    'The separator in the map does not match the separator found in the file header.',
  'sales-history_page_table_info-popover_DATAEFORMAT_NOT_FOUND': 'Date format not found.',
  'sales-history_page_table_info-popover_DATE_PARSE_ERROR':
    'The date format in the map does not match the date format in the file.',
  'sales-history_page_table_info-popover_JSON_NORM_ERROR':
    'Data file (json) reading aborted due to errors.',
  'sales-history_page_table_info-popover_JSON_PARSE_ABORT':
    'Process aborted due to errors.',
  'sales-history_page_table_info-popover_BRAND_CLIENT_NOT_FOUND':
    'Brand or client IDs not found.',
  'sales-history_page_table_info-popover_MISSING_CSV_DATA':
    'The CSV has no data. Delete the file if necessary.',
  'sales-history_page_table_info-popover_DATA_TRIM_ERROR':
    'Unable to normalize spaces in some strings.',
  'sales-history_page_table_info-popover_DATA_INTEGRITY_ERROR':
    'The number of columns in some rows does not match the number of headers. Fix the file and re-upload.',
  'sales-history_page_table_search_reset': 'Reset',
  'sales-history_page_delete-sales-history-not-processed-popover_title':
    'Delete all unprocessed sales',
  'sales-history_page_delete-sales-history-not-processed-popover_description':
    'Are you sure you want to delete all unprocessed sales?',
  'sales-history_page_delete-sales-history-not-processed-popover_delete-all-btn':
    'Delete all',
  'sales-history_page_delete-sales-history-not-processed-msg-success':
    'All unprocessed files have been deleted',
  'sales-history_page_reload-sales-history': 'Reload history',
  ////////////////////////////////////

  // MAPPING KEYS //////////////////////////////////////
  // Sales mapping keys
  'mapping-keys-field__multibrand': 'Multibrand',
  'mapping-keys-field__multibrand_point_of_sale': 'Point of Sale',
  'mapping-keys-field__point_of_sale_id': 'Point of sale ID',
  'mapping-keys-field__client_id': 'Multibrand ID',
  'mapping-keys-field__date': 'Date',
  'mapping-keys-field__hour': 'Hour',
  'mapping-keys-field__floor': 'Floor',
  'mapping-keys-field__caja': 'Cash Register',
  'mapping-keys-field__order_number': 'Order Number',
  'mapping-keys-field__order_line': 'Order Line',
  'mapping-keys-field__product_reference': 'Product Reference',
  'mapping-keys-field__product_description': 'Product Description',
  'mapping-keys-field__brand_ean': 'Brand EAN',
  'mapping-keys-field__product_ean_code': 'Multibrand EAN',
  'mapping-keys-field__product_brand': 'Brand',
  'mapping-keys-field__color_code': 'Color Code',
  'mapping-keys-field__product_color': 'Color Name',
  'mapping-keys-field__product_size_eu': 'Size',
  'mapping-keys-field__product_season': 'Season',
  'mapping-keys-field__division': 'Division',
  'mapping-keys-field__family': 'Family',
  'mapping-keys-field__subfamily': 'Sub Family',
  'mapping-keys-field__gender': 'Gender',
  'mapping-keys-field__units': 'Units',
  'mapping-keys-field__price': 'Sales Price',
  'mapping-keys-field__cost': 'Purchase Price',
  'mapping-keys-field__discount': 'Discount Amount',
  'mapping-keys-field__discount_percentaje': 'Discount%',
  'mapping-keys-field__payment_method': 'Payment Method',
  'mapping-keys-field__seller': 'Seller',
  //////////////////////////////////////////////////////

  // META ///////////////////////////
  meta_connections_title: 'Connections',
  'meta_connections_new-connection': '+ New',
  meta_connections_modal_title: 'New connection',
  'meta_connections_modal_form_user-name': 'Username',
  'meta_connections_modal_form_user-name_required': 'Please enter the username',
  meta_connections_modal_form_password: 'Password',
  meta_connections_modal_form_password_required: 'Please enter the password',
  meta_connections_modal_form_period: 'Frequency',
  meta_table_createdAt: 'Creation date',
  'meta_table_user-name': 'User',
  meta_table_password: 'Password',
  meta_table_type: 'Type',
  meta_table_period: 'Send frequency',
  ///////////////////////////////////

  // ACTIONS/REDUCER ///////////////
  // Main contacts
  'action_get-main-contacts_error': 'Error getting main contacts',
  'action_select-main-contact_error': 'Error selecting main contacts',
  'action_create-main-contact_success': 'Contact created successfully',
  'action_create-main-contact_error': 'Error creating contact',
  'action_update-main-contact_success': 'Contact updated successfully',
  'action_update-main-contact_error': 'Error updating contact',
  'action_delete-main-contact_success': 'Contact deleted successfully',
  'action_delete-main-contact_error': 'Error deleting contact',
  // My Brands
  'action_my-brands_get-my-brands_error': 'Error getting my brands',
  'action_my-brands_remove-from-my-brands_success': 'Brand unlinked successfully',
  'action_my-brands_remove-from-my-brands-multiple_success':
    'Brands successfully unlinked',
  'action_my-brands_remove-from-my-brands_error': 'Error unlinking the brand',
  'action_my-brands_add-to-my-brands_exists': 'You have already added this brand',
  'action_my-brands_add-to-my-brands_error': 'Error adding the brand',
  'action_my-brands_add-to-my-brands_success': 'Association request sent successfully',
  'action_my-brands_create-new-brand_success': 'Brand added successfully',
  'action_my-brands_create-new-brand_exists': 'A brand with the same name already exists',
  'action_my-brands_add-to-my-brands_brand-without-tenant':
    'The brand is not yet on the platform, we will contact you when it is available',
  // Brand
  'action_brand_get-brand_error': 'Error obtaining the brand',
  'action_brand_get-brand_contact-errors': 'Error obtaining brand contacts',
  'action_brand_share-data-with-brand_error': 'Error sharing data with the brand',
  'action_brand_share-data-with-brand_share-success':
    'Sharing data with the point of sale',
  'action_brand_share-data-with-brand_no-share-success':
    'You have stopped sharing data with the point of sale',
  // Sales CSV upload
  'sales-csv-upload_reject-upload-success': 'Sales upload successfully removed',
  'sales-csv-upload_reject-upload-error': 'Error removing sales upload',
  'sales-csv-upload_get-mappings-error-1': 'Error fetching mapping keys',
  'sales-csv-upload_get-mappings-error-2': 'Server error fetching mapping keys',
  'sales-csv-upload_get-mappings-error-3': 'Error fetching my mappings',
  'sales-csv-upload_delete-mapping-success': 'Mapping successfully removed',
  'sales-csv-upload_delete-mapping-error': 'Error removing mapping',
  'sales-csv-upload_create-mapping-success': 'Mapping successfully created',
  'sales-csv-upload_create-mapping-success_without-send-file':
    'Map created successfully. It usually takes less than two minutes to appear in the list.',
  'sales-csv-upload_create-mapping-error': 'Error creating mapping',
  // Meta
  'action_meta_get-connections_error': 'Error fetching the connections',
  'action_meta_delete-connection_success': 'Connection successfully deleted',
  'action_meta_delete-connection_error': 'Error deleting the connection',
  'action_meta_create-connection_success': 'Connection successfully created',
  'action_meta_create-connection_error': 'Error creating the connection',
  //////////////////////////

  // MESSAGE PAGES //////////
  // Error
  'errors-page_404_subtitle': 'Sorry, the page you are looking for does not exist.',
  'errors-page_404_btn-go-back': 'Go back',
  // Coming soon
  'coming-soon-page_title': 'Coming Soon!',
  'coming-soon-page_go-back': 'Go Back',
  /////////////////////////

  // BREADCRUMBS ///////////////
  breadcrumbs_multimedia: 'Multimedia',
  'breadcrumbs_multimedia-videos': 'Videos',
  'breadcrumbs_multimedia-images': 'Images',
  // Sales
  breadcrumbs_sales: 'Sales',
  'breadcrumbs_sales-history': 'Upload History',
  'breadcrumbs_sales-create': 'Upload Sales',
  // Catalog
  breadcrumb_catalog: 'Catalog',
  breadcrumb_product: 'All Brands',
  'breadcrumb_catalog-download': 'Download catalog',
  // Clients
  breadcrumbs_clients: 'Clients',
  'breadcrumbs_clients-list': 'Clients list',
  'breadcrumbs_add-client': 'Add client',
  breadcrumbs_resume: 'Summary',
  'breadcrumbs_business-info': 'Business information',
  'breadcrumbs_main-contacts': 'Main contacts',
  'breadcrumbs_sale-points': 'Stores',
  'breadcrumbs_commercial-info': 'Commercial information',
  // Account
  breadcrumbs_account: 'Account',
  breadcrumbs_account_profile: 'Profile',
  breadcrumbs_account_company: 'Company Information',
  breadcrumbs_account_contacts: 'Key Contacts',
  'breadcrumbs_account_my-brands': 'My Brands',
  'breadcrumbs_account_points-of-sale': 'Points of Sale',
  //////////////////////////////

  // MODALS ////////////////////
  'modal_email-verified_title': 'Check your email',
  'modal_email-verified_content': 'To proceed with the process, please check your email',
  'modal_email-verified_content-1':
    'Verify the address from the received email link and then click',
  'modal_email-verified_content_btn_resend': 'Resend Verification',
  'modal_email-verified_content_btn_reload': 'Reload',
  'modal_email-verified_content_notif_resend_title': 'Resend Notification',
  'modal_email-verified_content_notif_resend_content-1':
    'The verification email has been sent to this address',
  'modal_email-verified_content_notif_resend_content-2': 'Check your email to continue.',
  //////////////////////////////

  // POLICY PRIVACY //////////
  'policy-privacy-modal_title': 'Terms of Service',
  'policy-privacy-modal_btn-accept': 'Accept',
  'policy-privacy-modal_btn-cancel': 'Cancel',
  'policy-privacy-modal_checkbox': 'I accept the Terms of Service',
  'policy-privacy-modal_checkbox-message': 'Accept the privacy policy and terms.',
  ////////////////////////////

  // SERVER DOWN MODAL /////
  'server-down-modal_title': 'Server Error',
  'server-down-modal_content':
    'We are experiencing issues with our services. Please try again later or contact technical support.',
  //////////////////////////

  // NOTIFICATIONS /////////
  'notification-error_message': 'Error Code',
  'notification-error_description':
    'Please try again later. If the problem persists, contact Hub4Retail (help@hub4retail.com) and provide the error code.',
  //////////////////////////

  // GOOGLE ADDRESS FORM //////
  'google-address-form_address': 'Address',
  'google-address-form_floor': 'Floor',
  'google-address-form_door': 'Door',
  'google-address-form_postal-code': 'Postal Code',
  'google-address-form_city': 'City',
  'google-address-form_province': 'Province',
  'google-address-form_country': 'Country',
  'google-address-form_select-country': 'Select a country',
  //////////////////////////////

  // SHARED //////////////////
  settings: 'Settings',
  logout: 'Logout',
  'admin-panel_link': 'Admin Panel',
  'btn-save': 'Save',
  'btn-cancel': 'Cancel',
  'see-more-content-button': 'See more',
  'see-less-content-button': 'See less',
  'see-all-content-button': 'See all',
  'search-btn-label': 'Search',
  'close-btn-label': 'Close',
  ////////////////////////////
};

export default englishTranslation;
