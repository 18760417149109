import * as MyBrandsTypes from './myBrands.types';
import * as api from 'api/myBrands.api';
import { myBrandsError } from 'utils/errorCodes';
import { notificationErr } from 'views/components/UI/notification';
import {
  messageError,
  messageSuccess,
  messageWarning,
} from 'views/components/UI/message';
import { createNewBrandAPI } from 'api/brands.api';
import * as status from 'contexts/myBrands/constants';
import {
  IMyBrand,
  IMyBrandsBrandPointOfSale,
  ITenant,
  TCreateNewBrandProps,
} from 'interfaces/myBrands.interface';
import { IAssociationsStates } from 'interfaces/user.interface';
import { getAssociationStateName } from 'utils/getAssociationStateName';
import { IBrandsBrandState } from 'interfaces/brands.interface';
import { TBannerItemResponse } from 'api/general.api';
import { IGetMyBrandsAPIResponse } from 'api/responseInterfaces/myBrands.response.interface';

export async function getMyBrandsAction(
  dispatch: any,
  clientId: string,
  token: string,
  translate: any,
  associationStates: IAssociationsStates,
  country_id: string
) {
  dispatch({ type: MyBrandsTypes.SET_IS_LOADING, payload: true });
  try {
    const [connectedBrandsFetch, pendingBrandsFetch] = await Promise.all([
      api.getMyBrandsAPI(clientId, associationStates.connected.id, token),
      api.getMyBrandsAPI(clientId, associationStates.pending.id, token),
      // api.getMyBrandsAPI(clientId, associationStates.rejected.id, token),
      // api.getMyBrandsAPI(clientId, associationStates.disconnected.id, token),
    ]);
    const connectedBrands: IMyBrand[] = await getMyBrands(
      connectedBrandsFetch,
      associationStates,
      country_id,
      clientId,
      token,
      'connected'
    );
    const pendingBrands: IMyBrand[] = await getMyBrands(
      pendingBrandsFetch,
      associationStates,
      country_id,
      clientId,
      token,
      'pending'
    );
    // const rejectedBrands: IMyBrand[] = await getMyBrands(rejectedBrandsFetch, associationStates, country_id, clientId, token)
    // const disconnectedBrands: IMyBrand[] = await getMyBrands(disconnectedBrandsFetch, associationStates, country_id, clientId, token, 'disconnected')
    const myBrands = [...connectedBrands, ...pendingBrands];
    dispatch({
      type: MyBrandsTypes.GET_MY_BRANDS,
      payload: myBrands ?? [],
    });

    // Get all points of sale connected
    let pointsOfSaleConnected: IMyBrandsBrandPointOfSale[] = [];
    for (const item of connectedBrands) {
      if (item.tenant.pointsOfSale?.length > 0) {
        for (const point of item.tenant.pointsOfSale) {
          point.associated === true && pointsOfSaleConnected.push(point);
        }
      }
    }
    return dispatch({
      type: MyBrandsTypes.SET_POINTS_OF_SALE_CONNECTED,
      payload: pointsOfSaleConnected ?? [],
    });
  } catch (err) {
    console.log(err);
    messageError(translate('action_my-brands_get-my-brands_error'));
    return notificationErr(myBrandsError.get_my_brands_001, translate);
  } finally {
    dispatch({ type: MyBrandsTypes.SET_IS_LOADING, payload: false });
  }
}

export async function addToMyBrandAction(
  dispatch: any,
  brand: IBrandsBrandState | null,
  banner: TBannerItemResponse | null,
  clientId: string,
  token: string,
  translate: any,
  setIsOpenDrawer: (value: boolean) => void,
  associationStates: IAssociationsStates,
  countryId: string
) {
  dispatch({
    type: MyBrandsTypes.SET_IS_LOADING_ADD_TO_MY_BRAND,
    payload: {
      isLoading: true,
      brandId: banner ? banner.brand_id : (brand as IBrandsBrandState).id,
    },
  });
  let body: { tenant_id: string | undefined; client_id: string } = {
    tenant_id: undefined,
    client_id: clientId,
  };
  if (banner) {
    if (banner.tenant_id === null)
      return messageWarning(
        translate('action_my-brands_add-to-my-brands_banner-without-tenant')
      );
    body.tenant_id = banner.tenant_id;
  } else {
    if ((brand as IBrandsBrandState).tenants.length === 0)
      return messageWarning(
        translate('action_my-brands_add-to-my-brands_brand-without-tenant')
      );
    const findTenantByCountryId = (brand as IBrandsBrandState).tenants.find(
      (item: any) => item.country_id === countryId
    );
    body.tenant_id = findTenantByCountryId
      ? findTenantByCountryId.id
      : (brand as IBrandsBrandState).tenants[0].id;
  }
  try {
    const createFetch = await api.addToMyBrandsAPI(body, token);
    if (createFetch.response.status === 403) {
      return messageWarning(translate('action_my-brands_add-to-my-brands_exists'));
    }
    if (createFetch.response.status === 404) {
      notificationErr(myBrandsError.add_to_my_brands_004, translate);
      return messageError(translate('action_my-brands_add-to-my-brands_error'));
    }
    try {
      const [connectedBrandsFetch, pendingBrandsFetch] = await Promise.all([
        api.getMyBrandsAPI(clientId, associationStates.connected.id, token),
        api.getMyBrandsAPI(clientId, associationStates.pending.id, token),
        // api.getMyBrandsAPI(clientId, associationStates.rejected.id, token),
        // api.getMyBrandsAPI(clientId, associationStates.disconnected.id, token),
      ]);
      const connectedBrands: IMyBrand[] = await getMyBrands(
        connectedBrandsFetch,
        associationStates,
        countryId,
        clientId,
        token,
        'connected'
      );
      const pendingBrands: IMyBrand[] = await getMyBrands(
        pendingBrandsFetch,
        associationStates,
        countryId,
        clientId,
        token,
        'pending'
      );
      // const rejectedBrands: IMyBrand[] = await getMyBrands(rejectedBrandsFetch, associationStates, countryId, clientId, token)
      // const disconnectedBrands: IMyBrand[] = await getMyBrands(disconnectedBrandsFetch, associationStates, countryId, clientId, token, 'disconnected')
      const myBrands = [...connectedBrands, ...pendingBrands];

      setIsOpenDrawer && setIsOpenDrawer(false);
      dispatch({
        type: MyBrandsTypes.ADD_TO_MY_BRAND,
        payload: myBrands,
      });
      return messageSuccess(translate('action_my-brands_add-to-my-brands_success'));
    } catch (err) {
      messageError(translate('action_my-brands_add-to-my-brands_error'));
      notificationErr(myBrandsError.add_to_my_brands_001, translate);
    }
  } catch (err) {
    messageError(translate('action_my-brands_add-to-my-brands_error'));
    notificationErr(myBrandsError.add_to_my_brands_002, translate);
  } finally {
    dispatch({
      type: MyBrandsTypes.SET_IS_LOADING_ADD_TO_MY_BRAND,
      payload: {
        isLoading: false,
        brandId: '',
      },
    });
  }
}

export async function addToMyBrandMultipleAction(
  dispatch: any,
  myBrands: IMyBrand[],
  brands: IBrandsBrandState[],
  clientId: string,
  token: string,
  translate: any,
  associationStates: IAssociationsStates,
  countryId: string
) {
  dispatch({
    type: MyBrandsTypes.SET_ADD_TO_MY_BRAND_MULTIPLE_STATUS,
    payload: { isLoading: true },
  });
  let totalBrands = brands.length;
  let haveToAdd = true;
  let brandsToAddCount = 0;
  let brandsToAdd: IBrandsBrandState[] = [];
  let brandsAddedCount = 0;
  if (totalBrands === 0) {
    dispatch({
      type: MyBrandsTypes.SET_ADD_TO_MY_BRAND_MULTIPLE_STATUS,
      payload: {
        haveBrandsToAdd: false,
      },
    });
  }
  const myBrandsIds = myBrands.map((item: IMyBrand) => item.brand.id);
  for (const brand of brands) {
    if (
      brand.associated === false &&
      brand.tenants.length > 0 &&
      !myBrandsIds.includes(brand.id)
    ) {
      brandsToAddCount++;
      haveToAdd = true;
      brandsToAdd.push(brand);
    }
  }
  dispatch({
    type: MyBrandsTypes.SET_ADD_TO_MY_BRAND_MULTIPLE_STATUS,
    payload: {
      haveBrandsToAdd: haveToAdd,
      totalBrands: totalBrands,
      brandsToAdd: brandsToAddCount,
    },
  });
  if (haveToAdd === true) {
    dispatch({
      type: MyBrandsTypes.SET_ADD_TO_MY_BRAND_MULTIPLE_STATUS,
      payload: { isOpenModal: true },
    });
    setTimeout(async () => {
      try {
        for (const brand of brandsToAdd as any[]) {
          const findTenantByCountryId = brand.tenants.find(
            (item: any) => item.country_id === countryId
          );
          const body = {
            tenant_id: findTenantByCountryId
              ? findTenantByCountryId.id
              : brand.tenants[0].id,
            client_id: clientId,
          };
          try {
            const createFetch = await api.addToMyBrandsAPI(body, token);
            if (
              createFetch.response.status === 200 ||
              createFetch.response.status === 201
            ) {
              brandsAddedCount++;
              dispatch({
                type: MyBrandsTypes.SET_ADD_TO_MY_BRAND_MULTIPLE_STATUS,
                payload: {
                  brandsAdded: brandsAddedCount,
                },
              });
            }
          } catch (error) {
            console.log(error);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        dispatch({
          type: MyBrandsTypes.SET_ADD_TO_MY_BRAND_MULTIPLE_STATUS,
          payload: {
            isLoading: false,
          },
        });
        try {
          const [connectedBrandsFetch, pendingBrandsFetch] = await Promise.all([
            api.getMyBrandsAPI(clientId, associationStates.connected.id, token),
            api.getMyBrandsAPI(clientId, associationStates.pending.id, token),
            // api.getMyBrandsAPI(clientId, associationStates.rejected.id, token),
            // api.getMyBrandsAPI(clientId, associationStates.disconnected.id, token),
          ]);
          const connectedBrands: IMyBrand[] = await getMyBrands(
            connectedBrandsFetch,
            associationStates,
            countryId,
            clientId,
            token,
            'connected'
          );
          const pendingBrands: IMyBrand[] = await getMyBrands(
            pendingBrandsFetch,
            associationStates,
            countryId,
            clientId,
            token,
            'pending'
          );
          // const rejectedBrands: IMyBrand[] = await getMyBrands(rejectedBrandsFetch, associationStates, countryId, clientId, token)
          // const disconnectedBrands: IMyBrand[] = await getMyBrands(disconnectedBrandsFetch, associationStates, countryId, clientId, token, 'disconnected')
          const myBrands = [...connectedBrands, ...pendingBrands];

          dispatch({
            type: MyBrandsTypes.ADD_TO_MY_BRAND,
            payload: myBrands,
          });
          messageSuccess(`${brandsAddedCount} solicitudes de asociaciones enviadas`);
          return dispatch({
            type: MyBrandsTypes.SET_ADD_TO_MY_BRAND_MULTIPLE_STATUS,
            payload: {
              isOpenModal: false,
              totalBrands: 0,
              brandsToAdd: 0,
              brandsAdded: 0,
            },
          });
        } catch (err) {
          messageError(translate('action_my-brands_add-to-my-brands_error'));
          notificationErr(myBrandsError.add_to_my_brands_001, translate);
        }
      }
    }, 1500);
  }
}

export async function removeFromMyBrandAction(
  dispatch: any,
  associationId: string,
  brands: IMyBrand[],
  associationStates: IAssociationsStates,
  token: string,
  translate: Function
) {
  try {
    const removeFetch = await api.removeFromMyBrandAPI(associationId, token);
    if (removeFetch.response.status === 200) {
      // const newBrands: IMyBrand[] = [...brands]
      // const findBrand = newBrands.findIndex((item) => item.key === associationId)
      // if (newBrands[findBrand].state_name === status.connected) {
      // 	newBrands[findBrand].state_id = associationStates.disconnected.id
      // 	newBrands[findBrand].state_name = getAssociationStateName(newBrands[findBrand].state_id, associationStates) === status.pending ? status.rejected : status.disconnected
      // } else {
      // 	newBrands.splice(findBrand, 1)
      // }
      dispatch({
        type: MyBrandsTypes.REMOVE_FROM_MY_BRAND,
        payload: brands.filter((item) => item.key !== associationId),
      });
      return messageSuccess(translate('action_my-brands_remove-from-my-brands_success'));
    } else {
      messageError(translate('action_my-brands_remove-from-my-brands_error'));
      return notificationErr(myBrandsError.remove_my_from_my_brands_001, translate);
    }
  } catch (err) {
    messageError(translate('action_my-brands_remove-from-my-brands_error'));
    notificationErr(myBrandsError.remove_my_from_my_brands_002, translate);
  }
}

export async function removeFromMyBrandsMultipleAction(
  dispatch: any,
  associationsIds: string[],
  brands: IMyBrand[],
  token: string,
  translate: Function
) {
  let brandsAssociated: number = 0;
  try {
    for (const associationId of associationsIds) {
      const removeFetch = await api.removeFromMyBrandAPI(associationId, token);
      if (removeFetch.response.status === 200) {
        brandsAssociated++;
      }
    }
    if (brandsAssociated === associationsIds.length) {
      messageSuccess(
        translate('action_my-brands_remove-from-my-brands-multiple_success')
      );
      dispatch({
        type: MyBrandsTypes.REMOVE_FROM_MY_BRAND,
        payload: brands.filter((item: any) => !associationsIds.includes(item.key)),
      });
      return true;
    } else {
      messageError(translate('action_my-brands_remove-from-my-brands_error'));
      notificationErr(myBrandsError.remove_my_from_my_brands_multiple_001, translate);
      return false;
    }
  } catch (err) {
    messageError(translate('action_my-brands_remove-from-my-brands_error'));
    notificationErr(myBrandsError.remove_my_from_my_brands_multiple_002, translate);
  }
}

export async function createNewBrandAction(
  dispatch: any,
  brand: TCreateNewBrandProps,
  clientId: string,
  clientName: string,
  token: string,
  translate: any,
  associationStates: IAssociationsStates,
  setIsOpenDrawer: (value: boolean) => void,
  formReset: any
) {
  try {
    const createBrandFetch = await createNewBrandAPI(brand, clientId, clientName, token);
    if (createBrandFetch.response.status === 200) {
      const {
        id,
        client_id,
        state_id,
        brand_id,
        client_ok,
        brand_ok,
        createdAt,
        updatedAt,
        client_alias_id,
        brand,
      } = createBrandFetch.data.association;
      // const { contact_name, contact_prefix, contact_suffix, contact_email, address, city } = createBrandFetch.data.alias
      const newBrand: IMyBrand = {
        key: id,
        id,
        client_id: client_id,
        state_id: state_id,
        state_name: getAssociationStateName(
          createBrandFetch.data.association.state_id,
          associationStates
        ),
        brand_id: brand_id,
        client_ok: client_ok,
        brand_ok: brand_ok,
        createdAt: createdAt,
        updatedAt: updatedAt,
        client_alias_id: client_alias_id,
        tenant_id: '',
        tenant_ok: false,
        brand: {
          id: brand.id,
          name: brand.name,
          logo: brand.logo,
        },
        tenant: {
          pointsOfSale: [] as IMyBrandsBrandPointOfSale[],
        } as ITenant,
      };
      messageSuccess(translate('action_my-brands_create-new-brand_success'));
      dispatch({ type: MyBrandsTypes.CREATE_NEW_BRAND, payload: newBrand });
      setIsOpenDrawer(false);
      formReset.resetFields();
    } else {
      messageWarning(translate('action_my-brands_create-new-brand_exists'));
    }
  } catch (err) {
    console.log(err);
  }
}

/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
async function getMyBrands(
  myBrandsData: IGetMyBrandsAPIResponse,
  associationStates: IAssociationsStates,
  countryId: string,
  clientId: string,
  token: string,
  type: string
) {
  let orderedMyBrands: IMyBrand[] = [];
  if (myBrandsData.response.status === 404) return [];
  for (const item of myBrandsData.data) {
    // const [brandFetch, pointsOfSaleFetch] = await Promise.all([
    // 	api.getBrandProfileAPI(item.tenant.brand.id, countryId, token),
    // 	api.getBrandPointsOfSaleAPI(clientId, item.tenant.id, token)
    // ])
    let pointsOfSale: any[] = [];
    if (type === status.connected) {
      const [pointsOfSaleFetch] = await Promise.all([
        api.getTenantPointsOfSaleAPI(clientId, item.tenant.id, token),
      ]);
      pointsOfSale = pointsOfSaleFetch.data ?? [];
    }
    const myBrandObj: IMyBrand = {
      ...item,
      key: item.id,
      state_name: getAssociationStateName(item.state_id, associationStates),
      brand: {
        id: item.tenant?.brand.id,
        name: item.tenant?.brand.name,
        logo: item.tenant?.brand.logo,
      },
      tenant: {
        ...item.tenant,
        pointsOfSale,
      },
    };
    orderedMyBrands.push(myBrandObj);
  }
  return orderedMyBrands || [];
}
