import { Suspense, lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { LoginRequired, NoCatalogRedirectToHome } from './middlewares';
import * as path from './paths';

// LAYOUTS
const MainLayout = lazy(() => import('../views/layouts/mainLayout'));
const AuthenticationLayout = lazy(() => import('../views/layouts/authLayout'));
const AdminLayout = lazy(() => import('views/layouts/adminLayout'));
const AccountLayout = lazy(() => import('../views/layouts/accountLayout'));
//// PAGES
// Main Pages
const HomePage = lazy(() => import('views/pages/main/homePage'));
const BrandPage = lazy(() => import('../views/pages/main/brandFeedPage'));
const DashboardPage = lazy(() => import('views/pages/main/dashboardPage'));
const CatalogPage = lazy(() => import('views/pages/main/catalog/catalogPage'));
const AddProductsPage = lazy(() => import('views/pages/main/catalog/addProductsPage'));
const CatalogDownloadPage = lazy(
  () => import('views/pages/main/catalog/catalogDownloadPage')
);
const ProductPage = lazy(() => import('views/pages/main/catalog/productPage'));
const MultimediaPage = lazy(() => import('views/pages/main/multimediaPage'));
const MultimediaVideosPage = lazy(
  () => import('views/pages/main/multimediaPage/multimediaVideos')
);
const MultimediaImagesPage = lazy(
  () => import('views/pages/main/multimediaPage/multimediaImages')
);
const MultimediaBannersPage = lazy(
  () => import('views/pages/main/multimediaPage/multimediaBanners')
);
const RegisterBrand = lazy(() => import('views/pages/main/registerBrandPage'));
const ThanksForRegister = lazy(() => import('views/pages/main/thanksForRegister'));
const NotificationsPage = lazy(() => import('views/pages/main/notificationsPage'));
const SalesPage = lazy(() => import('views/pages/main/salesPage'));
const SalesHistoryPage = lazy(
  () => import('views/pages/main/salesPage/salesHistoryPage')
);
const SalesCreatePage = lazy(() => import('views/pages/main/salesPage/salesCreatePage'));
// Auth Pages
const SignupPage = lazy(() => import('views/pages/authentication/signupPage'));
const LoginRedirect = lazy(() => import('views/pages/authentication/loginRedirect'));
// Profile pages
const ProfilePage = lazy(() => import('views/pages/main/accountPage/profilePage'));
const CompanyDataPage = lazy(
  () => import('views/pages/main/accountPage/companyDataPage')
);
const MainContactsPage = lazy(
  () => import('views/pages/main/accountPage/mainContactsPage')
);
const MyBrandsPage = lazy(() => import('views/pages/main/accountPage/myBrandsPage'));
const PointsOfSalePage = lazy(
  () => import('views/pages/main/accountPage/pointsOfSalePage')
);
const SelectedBrandPage = lazy(() => import('views/pages/main/accountPage/brandpage'));
//Config
const SettingsPage = lazy(() => import('views/pages/main/settingsPage'));
const UserConfig = lazy(
  () => import('views/pages/main/settingsPage/usersPage/UsersPage')
);
const MetaPage = lazy(() => import('views/pages/main/settingsPage/metaPage/MetaPage'));
const EditApiConnection = lazy(
  () =>
    import(
      'views/pages/main/settingsPage/metaPage/components/editApiConnection/EditApiConnection'
    )
);
// Admin
const AdminHome = lazy(() => import('views/pages/admin/adminHome'));
// Error
const ErrorXXX = lazy(() => import('views/pages/messages/errorXXX'));
const ComingSoon = lazy(() => import('views/pages/messages/comingSoon/ComingSoon'));

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <LoginRequired>
        <NoCatalogRedirectToHome>
          <Lazy>
            <MainLayout />
          </Lazy>
        </NoCatalogRedirectToHome>
      </LoginRequired>
    ),
    children: [
      {
        path: '/',
        element: (
          <Lazy>
            <HomePage />
          </Lazy>
        ),
      },
      {
        path: '/brand-feed/:brandId',
        element: (
          <Lazy>
            <BrandPage />
          </Lazy>
        ),
      },
      {
        path: 'dashboard',
        element: (
          <Lazy>
            <DashboardPage />
          </Lazy>
        ),
      },
      // { path: 'dashboard/my-brands', element: <Lazy><MyBrandsPage /></Lazy> },
      // CATALOG
      {
        path: 'catalog',
        element: (
          <Lazy>
            <CatalogPage />
          </Lazy>
        ),
      },
      {
        path: 'catalog/:id',
        element: (
          <Lazy>
            <ProductPage />
          </Lazy>
        ),
      },
      {
        path: 'catalog/add-product',
        element: (
          <Lazy>
            {' '}
            <AddProductsPage />{' '}
          </Lazy>
        ),
      },
      {
        path: path.catalogDownload,
        element: (
          <Lazy>
            {' '}
            <CatalogDownloadPage />{' '}
          </Lazy>
        ),
      },
      // MULTIMEDIA
      {
        path: 'multimedia',
        element: (
          <Lazy>
            <MultimediaPage />
          </Lazy>
        ),
        children: [
          {
            path: 'videos',
            element: (
              <Lazy>
                <MultimediaVideosPage />
              </Lazy>
            ),
          },
          {
            path: 'images',
            element: (
              <Lazy>
                <MultimediaImagesPage />
              </Lazy>
            ),
          },
          {
            path: 'banners',
            element: (
              <Lazy>
                <MultimediaBannersPage />
              </Lazy>
            ),
          },
          // { path: 'videos', element: <Lazy><ComingSoon /></Lazy> },
          // { path: 'images', element: <Lazy><ComingSoon /></Lazy> },
          // { path: 'banners', element: <Lazy><ComingSoon /></Lazy> },
        ],
      },
      // SALES
      {
        path: path.sales,
        element: (
          <Lazy>
            <SalesPage />
          </Lazy>
        ),
        children: [
          {
            path: path.salesHistory,
            element: (
              <Lazy>
                <SalesHistoryPage />
              </Lazy>
            ),
          },
          {
            path: path.salesCreate,
            element: (
              <Lazy>
                <SalesCreatePage />
              </Lazy>
            ),
          },
        ],
      },
      // ACCOUNT
      {
        path: 'account',
        element: (
          <Lazy>
            <AccountLayout />
          </Lazy>
        ),
        children: [
          {
            path: 'profile',
            element: (
              <Lazy>
                <ProfilePage />
              </Lazy>
            ),
          },
          {
            path: 'company',
            element: (
              <Lazy>
                <CompanyDataPage />
              </Lazy>
            ),
          },
          {
            path: 'contacts',
            element: (
              <Lazy>
                <MainContactsPage />
              </Lazy>
            ),
          },
          {
            path: 'my-brands',
            element: (
              <Lazy>
                <MyBrandsPage />
              </Lazy>
            ),
          },
          {
            path: 'brand/:brandId',
            element: (
              <Lazy>
                <SelectedBrandPage />
              </Lazy>
            ),
          },
          {
            path: 'points-of-sale',
            element: (
              <Lazy>
                <PointsOfSalePage />
              </Lazy>
            ),
          },
        ],
      },
      // CONFIG
      {
        path: path.settings,
        element: (
          <Lazy>
            <SettingsPage />
          </Lazy>
        ),
      },
      {
        path: path.settingsUsers,
        element: (
          <Lazy>
            <UserConfig />
          </Lazy>
        ),
      },
      {
        path: path.settingsChronos,
        element: (
          <Lazy>
            <ComingSoon />
          </Lazy>
        ),
      },
      {
        path: path.settingsDevices,
        element: (
          <Lazy>
            <ComingSoon />
          </Lazy>
        ),
      },
      {
        path: path.settingsTouchScreen,
        element: (
          <Lazy>
            <ComingSoon />
          </Lazy>
        ),
      },
      {
        path: path.settingsSystemSettings,
        element: (
          <Lazy>
            <ComingSoon />
          </Lazy>
        ),
      },
      {
        path: path.settingsMeta,
        element: (
          <Lazy>
            <MetaPage />
          </Lazy>
        ),
      },

      {
        path: `${path.settingsMetaEditAPI}/:apiType/:connectorId`,
        element: (
          <Lazy>
            <EditApiConnection />
          </Lazy>
        ),
      },

      {
        path: path.settingsBilling,
        element: (
          <Lazy>
            <ComingSoon />
          </Lazy>
        ),
      },
      {
        path: path.settingsSupport,
        element: (
          <Lazy>
            <ComingSoon />
          </Lazy>
        ),
      },
      // NOTIFICATIONS
      {
        path: 'notifications',
        element: (
          <Lazy>
            <NotificationsPage />
          </Lazy>
        ),
      },
      // ERROR
      {
        path: '*',
        element: (
          <Lazy>
            <ErrorXXX code="404" />
          </Lazy>
        ),
      },
    ],
  },
  {
    path: 'authentication',
    element: (
      <Lazy>
        <AuthenticationLayout />
      </Lazy>
    ),
    children: [
      {
        path: 'signup',
        element: (
          <Lazy>
            <SignupPage />
          </Lazy>
        ),
      },
      {
        path: 'signup-brand',
        element: (
          <Lazy>
            <RegisterBrand />
          </Lazy>
        ),
      },
      {
        path: 'thanks',
        element: (
          <Lazy>
            <ThanksForRegister />
          </Lazy>
        ),
      },
      {
        path: 'login',
        element: (
          <Lazy>
            <LoginRedirect />
          </Lazy>
        ),
      },
    ],
  },
  {
    path: 'admin',
    element: (
      <Lazy>
        <AdminLayout />
      </Lazy>
    ),
    children: [
      {
        path: 'home',
        element: (
          <Lazy>
            <AdminHome />
          </Lazy>
        ),
      },
    ],
  },
]);

function Lazy({ children }: any) {
  return <Suspense fallback={<></>}>{children}</Suspense>;
}

export default router;
