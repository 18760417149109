import { IInitialBrandsState } from 'interfaces/brands.interface';
import * as BrandsTypes from './brands.types';

export default function brandsReducer(state: IInitialBrandsState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case BrandsTypes.GET_PAGINATION_BRANDS:
      const getPaginationBrands: IInitialBrandsState = {
        ...state,
        brandsWithPagination: payload,
        bodyFetch: {
          ...state.bodyFetch,
          index: payload.length,
        },
      };
      return getPaginationBrands;

    case BrandsTypes.GET_ALL_BRANDS:
      const getAllBrands: IInitialBrandsState = {
        ...state,
        allBrands: payload,
      };
      return getAllBrands;

    case BrandsTypes.ADD_MORE_BRANDS:
      const addMoreBrands: IInitialBrandsState = {
        ...state,
        brandsWithPagination: state.brandsWithPagination.concat(payload.data),
        bodyFetch: {
          ...state.bodyFetch,
          index: payload.index + payload.data.length,
        },
      };
      return addMoreBrands;

    case BrandsTypes.SET_THERE_ARE_MORE_BRANDS:
      const setThereAreMoreBrands: IInitialBrandsState = {
        ...state,
        thereAreMoreBrands: payload,
      };
      return setThereAreMoreBrands;

    case BrandsTypes.SET_IS_LOADING_MORE_BRANDS:
      const setIsLoadingMoreBrands: IInitialBrandsState = {
        ...state,
        isLoadingMoreBrands: payload,
      };
      return setIsLoadingMoreBrands;

    case BrandsTypes.SET_BODY_SEARCH:
      const setBodySearch: IInitialBrandsState = {
        ...state,
        bodyFetch: {
          ...state.bodyFetch,
          search: payload,
        },
      };
      return setBodySearch;

    case BrandsTypes.RESET_BODY_SEARCH:
      const resetBodySearch: IInitialBrandsState = {
        ...state,
        bodyFetch: {
          ...state.bodyFetch,
          search: '',
        },
      };
      return resetBodySearch;

    case BrandsTypes.SET_IS_LOADING:
      const setIsLoading: IInitialBrandsState = {
        ...state,
        isLoading: payload,
      };
      return setIsLoading;

    default:
      return state;
  }
}
