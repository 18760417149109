import { countriesTranslation } from 'assets/locale/es/core/countriesTranslation';

const spanishTranslation = {
  ...countriesTranslation,
  // HEADER //////////////////
  // Nav
  header_nav_home: 'Inicio',
  'header_nav_my-brands': 'Mis Marcas',
  header_nav_dashboard: 'Dashboard',
  header_nav_catalog: 'Catálogo',
  'header_nav_catalog-download': 'Descargar catálogo',
  header_nav_multimedia: 'Multimedia',
  'header_sub-nav_view-catalog': 'Ver catálogo',
  'header_sub-nav_add-product': 'Añadir producto',
  'header_sub-nav_multimedia-catalog': 'Catálogo multimedia',
  'header_sub-nav_multimedia-catalog-videos': 'Vídeos',
  'header_sub-nav_multimedia-catalog-images': 'Imágenes',
  'header_sub-nav_multimedia-catalog-banners': 'Banners',
  'header_sub-nav_my-brands-list': 'Lista de Marcas',
  'header_sub-nav_add-brand': 'Añadir Marca',
  header_nav_sales: 'Ventas',
  'header_nav_sales-history': 'Historial de subidas',
  'header_nav_sales-create': 'Subir ventas',
  // Notifications
  'header_notifications_empty-notifications': 'No hay notificaciones',
  ////////////////////////////

  // SIGNUP PAGE /////////////
  'signup-page_title': 'Regístrate en:',
  'signup-page_sub-title': 'Regístrate para hacer crecer tu negocio',
  'signup-page_button-brand': 'Registrarme como marca',
  'signup-page_button-multibrand': 'Registrarme como multimarca',
  'signup-page_button-brand-wh': 'Registrarme como marca o multimarca',
  'signup-page_return-to-login': 'Volver a inicio de sesión',
  ////////////////////////////

  // HOME PAGE ///////////////
  'home-page_brands_my-brands_title': 'Mis Marcas',
  'home-page_brands_item_button-see-catalog': 'Ver Catálogo',
  'home-page_brands_my-brands_my-brands-link': 'Ir a Mis Marcas',
  'home-page_brands_all-brands_title': 'Marcas en HUB',
  'home-page_brands_all-brands_see-more': 'Ver todas',
  'home-page_brands_all-brands_see-less': 'Ver menos',
  'home-page_brands_item_button-connect': 'Conectar',
  'home-page_brands_item_button-see-profile': 'Ver Perfil',
  'home-page_brands_item_button-pending': 'Pendiente',
  'home-page_brands_item_button-disconnected': 'Desconectado',
  'home-page_brands_item_empty-brands': 'Todavía no hay asociaciones',
  'home-page_brands_see_more': 'Ver más',
  'home-page_brands_see_brand': 'Ver Perfil',
  'home-page_brands_no_more_results': 'No se encontraron resultados...',
  'home-page_brands_connect-modal_title': '¡La solicitud se ha enviado a la marca!',
  'home-page_brands_connect-modal_text1':
    'Hub4Retail se pondrá en contacto con la marca para asociarla.',
  'home-page_brands_connect-modal_text2': 'Le estaremos enviando un mail actualizándolo.',
  'home-page_brands-not-associated_title': 'Marcas que vendes no asociadas',
  'home-page_brands-not-associated_search-input-placeholder':
    'Buscar en marcas no asociadas',
  'home-page_brands-not-associated_selected-brand': 'marca seleccionada',
  'home-page_brands-not-associated_selected-brands': 'marcas seleccionadas',
  'home-page_brands-not-associated_select-all': 'Seleccionar todo',
  'home-page_brands-not-associated_clear-select': 'Limpiar selección',
  'home-page_brands-not-associated_associate-btn': 'Asociar marcas',
  'home-page_my-brands_search-input-placeholder': 'Buscar en mis marcas',
  ////////////////////////////

  // ACCOUNT PAGE /////////////
  'profile-page_nav_profile': 'Perfil',
  'profile-page_nav_company': 'Datos de empresa',
  'profile-page_nav_main-contacts': 'Contactos principales',
  'profile-page_nav_my-brands': 'Mis Marcas',
  'profile-page_nav_points-of-sale': 'Puntos de venta',
  /////////////////////////////

  /// DASHBOARDS //////////////
  'dashboard_empty-dashboard': 'Contacta con Hub4Retail para habilitar este servicio.',
  dashboard_dashboard_error:
    'Error al cargar el dashboard. Si el problema persiste, contactar con:',
  dashboard_maintenance_message: 'Estamos trabajando para ti !!',
  /////////////////////////////

  // HELMET ///////////////////
  signup: 'Registrarse',
  home: 'Inicio',
  dashboard: 'Dashboard',
  multimedia: 'Multimedia',
  'multimedia-videos': 'Multimedia videos',
  'multimedia-images': 'Multimedia imágenes',
  'multimedia-banners': 'Multimedia banners',
  clients: 'Clientes',
  profile: 'Perfil',
  company: 'Datos de empresa',
  'main-contacts': 'Contactos principales',
  'commercial-info': 'Información comercial',
  'points-of-sale': 'Puntos de venta',
  catalog: 'Catálogo',
  catalog_download: 'Descargar catálogo',
  notifications: 'Notificaciones',
  helmet_settings: 'Ajustes',
  helmet_meta: 'Meta',
  //***************************************************************** */
  //***************************************************************** */
  helmet_meta_edit_connection: 'Meta - Editar conexión',
  //***************************************************************** */
  //***************************************************************** */
  helmet_users: 'Usuarios',
  'helmet_sales-history': 'Historial de ventas',
  'helmet_upload-sales': 'Subir ventas',
  /////////////////////////////

  // USERS ///////////////////////
  // Departments
  users_department_commercial: 'Comercial',
  users_department_administration: 'Administración',
  users_department_agent: 'Agente',
  users_department_owner: 'Propietario',
  'users_department_store-manager': 'Encargado de tienda',
  users_department_logistics: 'Logística',
  users_department_marketing: 'Marketing',
  users_department_others: 'Otros',
  ////////////////////////////////

  // SUPERADMIN //////////////////
  'super-admin_search-input_placeholder': 'Buscar...',
  ////////////////////////////////

  // ACCOUNT //////////////////
  account_profile_title: 'Mi Perfil',
  'account-info-panel_title': 'Información General',
  'account-company-info-panel_title': 'Datos de la empresa',
  // Profile
  'account-info-panel_profile_name': 'Nombre',
  'account-info-panel_profile_lastname': 'Apellido',
  'account-info-panel_profile_email': 'Email',
  'account-info-panel_profile_phone-number': 'Teléfono',
  'account-info-panel_profile_sector': 'Departamento',
  'account-info-panel_profile_security-data': 'Datos de Seguridad',
  'account-info-panel_profile_change-password': 'Cambiar Contraseña',
  'account-info-panel_profile_pwd_password-label': 'Contraseña nueva',
  'account-info-panel_profile_pwd_repeat-password-label': 'Repite la contraseña nueva',
  'account-info-panel_profile_pwd_change-button': 'Cambiar contraseña',
  'account-info-panel_profile_pwd_validation':
    'La contraseña debe tener 8 caracteres de mínimo y 16 como máximo, 1 número, 1 letra mayúscula y una minúscula.',
  'account-info-panel_profile_pwd_validation-match': 'Las contraseñas no coinciden',
  'account-info-panel_profile_pwd_change-success': 'Contraseña cambiada correctamente.',
  'account-info-panel_profile_pwd_empty-inputs': 'Rellena los campos vacíos.',
  // Profile form
  'account-profile_form-input_name': 'Introduce tu nombre',
  'account-profile_form-input_name-validation': 'Por favor, introduce tu nombre',
  'account-profile_form-input_lastname': 'Introduce tu apellido',
  'account-profile_form-input_lastname-validation': 'Por favor, introduce tu apellido',
  'account-profile_form-input_email': 'Introduce tu email',
  'account-profile_form-input_email-validation-required': 'Por favor, introduce tu email',
  'account-profile_form-input_email-validation': 'El email no es válido',
  'account-profile_form-input_sector': 'Introduce el departamento',
  'account-profile_form-input_sector-validation': 'Por favor, introduce el departamento',
  'account-profile_form-input_phone-number': '',
  'account-profile_form-input_phone-number-validation':
    'Por favor, introduce el número de teléfono',
  // Company
  'account-info-panel_company_title': 'Datos de la empresa',
  'account-info-panel_company_cif': 'Nº CIF',
  'account-info-panel_company_trade-name': 'Nombre Comercial',
  'account-info-panel_company_business-name': 'Razón Social',
  'account-info-panel_company_address': 'Dirección',
  'account-info-panel_company_postal-code': 'Código Postal',
  'account-info-panel_company_population': 'Población',
  'account-info-panel_company_country': 'País',
  'account-info-panel_company_province': 'Provincia',
  'account-info-panel_company_contact_name': 'Contacto Principal',
  'account-info-panel_company_email': 'Correo electrónico',
  'account-info-panel_company_phone-number': 'Teléfono',
  'account-info-panel_matas-connected_title': 'Metas conectados',
  'account-info-panel_active-connections_title': 'Conexiones activas',
  // Company form
  'account-company_form-input_trade-name': 'Introduce el nombre comercial',
  'account-company_form-input_trade-name-validation':
    'Por favor, introduce el nombre comercial',
  'account-company_form-input_business-name': 'Introduce la razón social',
  'account-company_form-input_business-name-validation':
    'Por favor, introduce la razón social',
  'account-company_form-input_country': 'Introduce el país',
  'account-company_form-input_country-validation': 'Por favor, introduce el país',
  'account-company_form-input_population': 'Introduce la población',
  'account-company_form-input_population-validation': 'Por favor, introduce la población',
  'account-company_form-input_address': 'Introduce la dirección',
  'account-company_form-input_address-validation': 'Por favor, introduce tu dirección',
  'account-company_form-input_postal-code': 'Introduce el código postal',
  'account-company_form-input_postal-code-validation':
    'Por favor, introduce el código postal',
  'account-company_form-input_cif': 'Introduce el CIF',
  'account-company_form-input_cif-validation': 'Por favor, introduce el CIF',
  'account-company_form-input_phone-number': 'Número de Teléfono',
  'account-company_form-input_phone-number-validation':
    'Por favor, introduce el número de teléfono',
  'account-company_form-input_contact_name':
    'Introduce el contacto principal de la empresa',
  'account-company_form-input_contact_name-validation-required':
    'Por favor, introduce el contacto principal de la empresa',
  'account-company_form-input_email': 'Introduce el email de empresa',
  'account-company_form-input_email-validation-required': 'Por favor, introduce tu email',
  'account-company_form-input_email-validation': 'El email no es válido',
  'account-company_form-input_instagram': 'Introduce la url de Instagram',
  'account-company_form-input_youtube': 'Introduce la url de Youtube',
  'account-company_form-input_linkedin': 'Introduce la url de Linkedin',
  'account-company_form-input_facebook': 'Introduce la url de Facebook',
  'account-company_social-media-title': 'Redes Sociales',
  'account-company_form-input_social-media-label': 'Nombre Red Social',
  'account-company_form-input_social-media-label-placeholder': 'Nombre de la Red Social',
  'account-company_form-input_social-media-label_validation':
    'Por favor, introduce el nombre de la red social',
  'account-company_form-input_social-media-value': 'URL Red Social',
  'account-company_form-input_social-media-value-placeholder': 'URL de la Red Social',
  'account-company_form-input_social-media-value_validation':
    'Por favor, introduce la URL de la red social',
  'account-company_form-input_social-media-add-button': 'Agregar Red Social',
  'account-company_form-input_social-media-accept-button_title': 'Actualizar Red Social',
  'account-company_form-input_social-media-reject-button_title': 'Descartar cambios',
  'account-company_form-input_social-media-delete-button_title': 'Eliminar Red Social',
  'account-company_form-input_social-media-delete-button_popconfirm_title':
    'Eliminar Red Social',
  'account-company_form-input_social-media-delete-button_popconfirm_description':
    '¿Seguro que quiere eliminar esta Red Social?',
  'account-company_form-input_social-media-delete-button_popconfirm_ok': 'Eliminar',
  'account-company_form-input_social-media-delete-button_popconfirm_cancel': 'Cancelar',
  // Company drawer
  'account-company_drawer_title': 'Datos de la Empresa',
  // Main Contacts
  'main-contacts_title': 'Contactos Principales',
  'main-contacts_add-contact': '+ Agregar',
  'main-contacts_table_name': 'Nombre',
  'main-contacts_table_lastname': 'Apellido',
  'main-contacts_table_email': 'Correo electrónico',
  'main-contacts_table_phone-number': 'Teléfono',
  'main-contacts_table_department': 'Departamento',
  'main-contacts_table_pop-confirm_title': 'Eliminar contacto',
  'main-contacts_table_pop-confirm_description':
    '¿Seguro que quieres eliminar este contacto?',
  'main-contacts_table_pop-confirm_btn-accept': 'Borrar',
  'main-contacts_table_pop-confirm_btn-cancel': 'Cancelar',
  // Main Contacts drawer
  'main-contacts_drawer_create-title': 'Crear contacto',
  'main-contacts_drawer_update-title': 'Actualizar contacto',
  // Main Contacts Form
  'main-contacts_form-label_name': 'Nombre',
  'main-contacts_form-label_lastname': 'Apellido',
  'main-contacts_form-label_email': 'Correo electrónico',
  'main-contacts_form-label_phone-number': 'Teléfono',
  'main-contacts_form-label_department': 'Departamento',
  'main-contacts_form-input_name': 'Introduce el nombre',
  'main-contacts_form-input_lastname': 'Introduce el apellido ',
  'main-contacts_form-input_email': 'Introduce el email',
  'main-contacts_form-input_department': 'Introduce el departamento',
  'main-contacts_form_sector_label': 'Departamento',
  'main-contacts_form-input_sector': 'Introduce el departamento',
  'main-contacts_form-input_sector_validation': 'Introduce el departamento',
  'main-contacts_form-input_phone-number': 'Introduce el número de teléfono',
  'main-contacts_form-input-validation_name': 'Por favor, introduce el nombre',
  'main-contacts_form-input-validation_lastname': 'Por favor, introduce el apellido ',
  'main-contacts_form-input-validation_email': 'El email no es válido',
  'main-contacts_form-input-validation-required_email': 'Por favor, introduce el email',
  'main-contacts_form-input-validation_department':
    'Por favor, introduce el departamento',
  'main-contacts_form-input-validation_phone-number':
    'Por favor, introduce el número de teléfono',
  // My Brands
  'my-brands_title': 'Mis Marcas',
  'my-brands_add-to-my-brands': '+ Agregar',
  'my-brands_table_brand-name': 'Marca',
  'my-brands_table_main-contact': 'Contacto principal',
  'my-brands_table_phone-number': 'Teléfono',
  'my-brands_table_email': 'Email',
  'my-brands_table_address': 'Dirección',
  'my-brands_table_city': 'Ciudad',
  'my-brands_table_pop-confirm_title': 'Desvincular de mis marcas',
  'my-brands_table_pop-confirm_description1': '¿Seguro que quieres desvincular',
  'my-brands_table_pop-confirm_description2': 'de mis marcas?',
  'my-brands_table_pop-confirm_btn-accept': 'Desvincular',
  'my-brands_table_pop-confirm_btn-cancel': 'Cancelar',
  'my-brands_table_disassociate-tooltip-title': 'Desvincular marca',
  // My brands drawer
  'my-brands_drawer_title': 'Busca y agrega marcas',
  'my-brands_drawer_add-title': 'Crear nueva marca',
  'my-brands_drawer_go-back-to-brands': 'Volver',
  'my-brands_drawer_form_address': 'Dirección',
  'my-brands_drawer_form_address-street-number': 'Calle y número',
  'my-brands_drawer_form_contact': 'Persona de Contacto',
  'my-brands_drawer_form_contact_name': 'Nombre',
  'my-brands_drawer_form_contact_lastname': 'Apellido',
  'my-brands_drawer_search-placeholder': 'Buscar',
  'my-brands_drawer_more-brands': '¿Quieres una marca que no ves aquí?',
  'my-brands_drawer_close-drawer': 'Cerrar',
  'my-brands_drawer_brand_associate-btn': 'Asociar',
  'my-brands_drawer_brand_see-profile-btn': 'Ver Perfil',
  'my-brands_disassociate-modal_title': 'Desvincular',
  'my-brands_disassociate-multiple-modal_title': 'Desvincular marcas',
  'my-brands_disassociate-multiple-modal_content':
    '¿Está seguro que quiere desvincular las marcas seleccionadas y todos sus puntos de venta?',
  'my-brands_disassociate-multiple-modal_popconfirm-description':
    '¿Estás seguro que quieres desvincular las marcas seleccionadas?',
  'my-brands_disassociate-modal_on-ok': 'Desvincular',
  'my-brands_disassociate-modal_on-cancel': 'Cancelar',
  'my-brands_disassociate-modal_content':
    '¿Está seguro que quiere desvincular esta marca y todos sus puntos de venta?',
  /////////////////////////////

  // MULTIMEDIA ///////////////
  'multimedia_nav-videos': 'Videos',
  'multimedia_nav-images': 'Imágenes',
  'multimedia_sort-btn': 'Ordenar por',
  'multimedia_filter-btn': 'Filtros',
  'multimedia_apply-filters-btn': 'Aplicar filtros',
  'multimedia_search-input-placeholder':
    'Buscar por marca, género, temporada, pais, fecha',
  'multimedia_search-results': 'Resultados',
  'multimedia_sort-last-favorites': 'Favoritos',
  multimedia_download: 'Descargar',
  multimedia_download_started: 'La descarga ha comenzado',
  multimedia_open: 'Abrir',
  'multimedia_link-copied': 'Enlace copiado al portapapeles',
  'multimedia_link-copy-button': 'Copiar enlace',
  // Sort by
  'multimedia_sort-last-aggregates': 'Últimos agregados',
  'multimedia_sort-best-seller': 'Más vendido',
  'multimedia_sort-less-sold': 'Menos vendido',
  'multimedia_sort-best-pvp_great-minor': 'PVP: de mayor a menor',
  'multimedia_sort-best-pvp_minor-great': 'PVP: de menor a mayor',
  'multimedia_sort-best-pvi_great-minor': 'PVI: de mayor a menor',
  'multimedia_sort-best-pvi_minor-great': 'PVI: de menor a mayor',
  'multimedia_sort-by-color': 'Por color a-z',
  'multimedia_sort-by-reference': 'Por referencia a-z',
  // Images
  'multimedia_images_popover-edit': 'Editar',
  'multimedia_images_popover-remove': 'Eliminar',
  'multimedia_images_popover-yes': 'Sí',
  'multimedia_images_popover-no': 'No',
  // Filter
  'multimedia_filter-remove-filters': 'Borrar filtros',
  'multimedia_filter-season': 'Temporada',
  'multimedia_filter-division': 'División',
  'multimedia_filter-genders': 'Género',
  'multimedia_filter-visualization': 'Visualización',
  'multimedia_filter-image-rights': 'Derechos de imagen',
  'multimedia_filter-country': 'País',
  'multimedia_filter-tags': 'Tags',
  'multimedia_filter-favorites': 'Mis favoritos',
  'multimedia_filter-start': 'Emitible desde',
  'multimedia_filter-end': 'Emitible hasta',
  //Dragger
  'multimedia_upload-images-manually_dragger_msg-error': 'Archivos admitidos',
  'multimedia_upload-images-manually_dragger_text-1':
    'Haz click o suelta y arrastra una imagen',
  // Upload image manually
  'multimedia_upload-images-manually_form-item_selection': 'Selecciona',
  'multimedia_upload-images-manually_form-item_title': 'Título',
  'multimedia_upload-images-manually_form-item_title-required':
    'Por favor, introduce el título de la imagen',
  'multimedia_upload-images-manually_form-item_name': 'Nombre Técnico',
  'multimedia_upload-images-manually_form-item_name-required':
    'Por favor, introduce el nombre de la imagen',
  'multimedia_upload-images-manually_form-item_description': 'Descripción',
  'multimedia_upload-images-manually_form-item_description-required':
    'Por favor, introduce la descripción',
  'multimedia_upload-images-manually_form-item_divisions': 'Divisiones',
  'multimedia_upload-images-manually_form-item_season': 'Temporada',
  'multimedia_upload-images-manually_form-item_language': 'Idioma',
  'multimedia_upload-images-manually_form-item_genders': 'Géneros',
  'multimedia_upload-images-manually_form-item_products': 'Productos',
  'multimedia_upload-images-manually_form-item_catalog_id': 'Id catálogo',
  'multimedia_upload-images-manually_form-item_catalog_id_text-1':
    'Campo asociado a chronos para definir su circuito publicitario',
  'multimedia_upload-images-manually_form-item_catalog_id_text-2':
    'Contacta a Hub4Retail para activar Chronos',
  'multimedia_upload-images-manually_form-item_usage': 'Uso del contenido',
  'multimedia_upload-images-manually_form-item_visualization': 'Visualización',
  'multimedia_upload-images-manually_form-item_tags': 'Etiquetas',
  'multimedia_upload-images-manually_form-item_tags_text-1': 'Crea tus propias etiquetas',
  'multimedia_upload-images-manually_form-item_start': 'Emitible desde',
  'multimedia_upload-images-manually_form-item_end': 'Emitible hasta',
  'multimedia_upload-images-manually_form-item_copyright':
    '¿Este video está sujeto a derechos de imagen?',
  'multimedia_upload-images-manually_form-item_enable_download': '¿Permites la descarga?',
  'multimedia_upload-images-manually_form-item_countries': 'Países',
  'multimedia_upload-images-manually_form-item_add': 'Añadir',
  'multimedia_upload-images-manually_form-item_next': 'Siguiente',
  'multimedia_upload-images-manually_form-item_yes': 'Sí',
  'multimedia_upload-images-manually_form-item_no': 'No',
  // Modal manually
  'multimedia_upload-images-manually_modal_title': 'Actualizar imagen',
  'multimedia_upload-images-manually_modal_step-1': 'Selecciona una imagen',
  'multimedia_upload-images-manually_modal_step-2': 'Completa los datos',
  'multimedia_upload-images-manually_modal_step-2-msg': 'Título de la imagen',
  'multimedia_upload-images-manually_modal_step-3-msg': 'Imagen subida correctamente',
  // Upload images CSV
  'multimedia_upload-images_button': 'Subir imágenes',
  'multimedia_upload-images_step-1': 'Selecciona imágenes',
  'multimedia_upload-images_step-1_dragger_text-1':
    'Haz click o suelta y arrastra archivos de imagen',
  'multimedia_upload-images_step-1_dragger_text-2': 'Ten en cuenta lo siguiente...',
  'multimedia_upload-images_step-1_dragger_text-3': 'Guardar las imágenes con el nombre',
  'multimedia_upload-images_step-1_dragger_text-4': 'referencia_color_nºimagen',
  'multimedia_upload-images_step-2': 'Subir CSV',
  'multimedia_upload-images_step-2_dragger_text-1':
    'Haz click o suelta y arrastra el archivo CSV',
  'multimedia_upload-images_step-3': '¡Carga finalizada!',
  'multimedia_upload-images_step-3-text': 'archivos de imagen subidos',
  'multimedia_upload-images_modal_title': 'Subir imágenes',
  'multimedia_upload-images_modal_btn-back': 'Atrás',
  'multimedia_upload-images_modal_btn-next': 'Siguiente',
  'multimedia_upload-images_modal_btn-close': 'Cerrar',
  ////////////////////////////

  // BRAND USERS /////////////////
  'brand-user_title': 'Usuarios',
  'brand-user_description':
    'Controla cómo interactúan los miembros de tu equipo con Hub4retail.',
  'brand-user_create-user': 'Añadir usuario',
  'brand-user_modal_title-create': 'Añadir usuario',
  'brand-user_modal_title-edit': 'Editar usuario',
  'brand-user_modal_input_name': 'Nombre',
  'brand-user_modal_input_lastname': 'Apellido',
  'brand-user_modal_input_email': 'Email',
  'brand-user_modal_input_points-of-sale': 'Puntos de venta',
  'brand-user_modal_input_password': 'Contraseña',
  'brand-user_modal_input_btn-save': 'Guardar',
  'brand-user_modal_input_btn-cancel': 'Cancelar',
  'brand-user_table_name': 'Nombre',
  'brand-user_table_email': 'Email',
  'brand-user_table_role': 'Rol',
  'brand-user_table_last-session': 'Última sesión',
  'brand-user_table_last-session-never': 'Nunca',
  'brand-user_delete-popconfirm_title': 'Eliminar el usuario',
  'brand-user_delete-popconfirm_description':
    '¿Seguro que quieres eliminar este usuario?',
  'brand-user_delete-popconfirm_confirm': 'Sí',
  'brand-user_delete-popconfirm_cancel': 'No',
  //*********************************************************************** */
  //*********************************************************************** */
  'brand-user_delete-popconfirm_icon-tooltip': 'Eliminar conexión',
  //*********************************************************************** */
  //*********************************************************************** */
  ////////////////////////////////

  // CATALOG //////////////////
  'catalog_add-product': 'Agregar producto',
  catalog_ProductList_results: 'Resultados',
  'catalog_no-more-results': 'No se encontraron resultados...',
  'catalog_tags_delete-all': 'Borrar todo',
  'catalog_loading-filters': 'Cargando filtros',
  'catalog_selected-brands-title': 'Marcas seleccionadas',
  'catalog_brand-tag': 'Marca',
  'catalog_brands-tag': 'Marcas',
  // Sort by
  'catalog_sort-by_dateAsc': 'Últimos agregados',
  'catalog_sort-by_dateDesc': 'Más antiguos',
  'catalog_sort-by_unitsAsc': 'Más vendido',
  'catalog_sort-by_unitsDesc': 'Menos vendido',
  'catalog_sort-by_nameAsc': 'Nombre a-z',
  'catalog_sort-by_nameDesc': 'Nombre z-a',
  'catalog_sort-by_colorAsc': 'Color a-z',
  'catalog_sort-by_colorDesc': 'Color z-a',
  'catalog_sort-by_pviAsc': 'PVI de menor a mayor',
  'catalog_sort-by_pviDesc': 'PVI de mayor a menor',
  'catalog_sort-by_pvprAsc': 'PVPR de menor a mayor',
  'catalog_sort-by_pvprDesc': 'PVPR de mayor a menor',
  'catalog_sort-by_referenceAsc': 'Referencia a-z',
  'catalog_sort-by_referenceDesc': 'Referencia z-a',
  'catalog_sort-all-brands': 'Todas las marcas',
  'catalog_brand-feed-btn': 'Perfil marca',
  // Catalog download
  'catalog_search-input-placeholder': 'Buscar por referencia, color o descripción',
  'catalog_download_alert_brand-required': 'Por favor, selecciona una marca',
  'catalog_download_alert_get-filters-error': 'Error al obtener los filtros',
  'catalog_download_alert_dowloading-compressed-files':
    'Descargando archivos comprimidos...',
  'catalog_download_progress-images': 'Imágenes',
  'catalog_download_success-msg': 'Imágenes descargadas correctamente',
  'catalog_download_success-msg-imgs-and-csv': 'CSV e imágenes descargadas correctamente',
  'catalog_download_csv-msg-without-images': 'CSV descargado correctamente',
  'catalog_download_csv-msg-pending-images':
    'CSV descargado correctamente, pero hay imágenes pendientes',
  'catalog_download_csv-msg-pending-images-navigate':
    'CSV descargado correctamente, pero hay imágenes pendientes. Puedes seguir navegando mientras se descarga',
  'catalog_download_apply-changes-btn': 'Aplicar cambios',
  'catalog_download_cancel-filter-btn': 'Cancelar',
  'catalog_download_cancel-values-selection-btn': 'Limpiar selección',
  'catalog_download_cancel-values-download-btn': 'Cancelar descarga',
  'catalog_download_clear-filters-btn': 'Limpiar filtros',
  catalog_download_result: 'resultado',
  catalog_download_results: 'resultados',
  catalog_download_columns_brand: 'Marca',
  catalog_download_columns_reference: 'Referencia',
  catalog_download_columns_ean: 'EAN',
  catalog_download_columns_size: 'Talla',
  catalog_download_columns_name: 'Nombre',
  catalog_download_columns_color: 'Descripción color',
  catalog_download_columns_color_code: 'Código color',
  catalog_download_columns_pvi: 'PVI',
  catalog_download_columns_pvpr: 'PVPR',
  catalog_download_columns_division: 'División',
  catalog_download_columns_gender: 'Género',
  catalog_download_columns_season: 'Temporada',
  catalog_download_columns_segmentation: 'Segmentación',
  catalog_download_columns_family: 'Familia',
  catalog_download_columns_material: 'Composición',
  catalog_download_columns_images: 'Imágenes',
  'catalog_download_columns_all-images': 'Todos los productos',
  'catalog_download_columns_with-images': 'Con imágenes',
  'catalog_download_columns_without-images': 'Sin imágenes',
  catalog_download_columns_favorites: 'Favoritos',
  'catalog_download_columns_column-search_search': 'Buscar',
  'catalog_download_columns_column-search_reset': 'Reiniciar',
  'catalog_download_columns_column-search_close': 'cerrar',
  'catalog_download_list_select-all': 'Seleccionar todo',
  'catalog_download_list_clear-all': 'Limpiar selección',
  'catalog_download_list_download-btn': 'Descargar',
  'catalog_download_list_download-images-btn': 'Descargar imágenes',
  catalog_download_list_item: 'item',
  catalog_download_list_items: 'items',
  'catalog_download_list_select-order_popover-title': 'Selecciona y ordena',
  ////////////////////////////

  // PRODUCT /////////////////
  product_infoBox_codeEAN: 'Códigos',
  product_collection_title: 'Toda la colection',
  product_infoProduct_other_colors: 'Otros colores',
  product_infoProduct_currency: '€',
  product_infoProduct_sizes: 'Tallas y precios',
  product_infoProduct_EAN_size: 'Talla',
  product_infoProduct_EAN_code: 'Codigo EAN',
  product_collapse_title_key1: 'Tallas',
  product_collapse_title_key2: 'INFORMACION GENERAL',
  product_collapse_title_key3: 'COMPOSICIÓN',
  'product_card-color': 'color',
  'product_mobile_product-details_size': 'Talla',
  'product_mobile_product-details_info': 'Información General',
  'product_mobile_product-details_ean-codes': 'Códigos EAN',
  'product_mobile_product-details_ean-code': 'Código EAN',
  'product_mobile_product-details_composition': 'Composición',
  'product_related-videos_title': 'Catálogo Multimedia',
  'product_related-videos_subtitle': 'Videos relacionados',
  'product_related-products_title': 'También puede interesarte...',
  'product_card_sold-units': 'Unidades vendidas:',
  ////////////////////////////

  // POINTS OF SALE ///////////
  'points-of-sale_title': 'Puntos de venta',
  'points-of-sale_add-point-of-sale': '+ Agregar',
  'points-of-sale_empty-points-of-sale': 'Todavía no hay puntos de venta',
  'points-of-sale_table_brand': 'Marca',
  'points-of-sale_table_connection': 'Conexión',
  'points-of-sale_table_recive-data': 'Recibo Datos',
  'points-of-sale_table_share-data': 'Comparto Datos',
  'points-of-sale_drawer_title': 'Puntos de venta',
  'points-of-sale_drawer_name_label': 'Nombre del punto de venta',
  'points-of-sale_drawer_add-edit-sale-point_point-of-sale-type':
    'Tipo de punto de venta',
  'points-of-sale_drawer_add-edit-sale-point_point-of-sale-type_physical':
    'Tienda Física',
  'points-of-sale_drawer_add-edit-sale-point_point-of-sale-type_online': 'Tienda Online',
  'points-of-sale_drawer_add-edit-sale-point_category': 'Categoría',
  'points-of-sale_drawer_add-edit-sale-point_category_input-validation':
    'Por favor, introduce la categoría del punto de venta',
  'points-of-sale_drawer_exchange_label': 'Tipo de moneda',
  'points-of-sale_drawer_add-edit-sale-point_exchange_input-validation':
    'Por favor, introduce el tipo de moneda',
  'points-of-sale_drawer_associate-brand_button_title':
    'Añadir a marcas con las que trabaja el punto de venta y asociarla a mis marcas',
  'points-of-sale_drawer_add-brand_button_title':
    'Añadir a marcas con las que trabaja el punto de ventas',
  'points-of-sale_drawer_selected-brand_button_title': 'Asociar a mis marcas',
  'points-of-sale_drawer_associate-all-brands_modal-title-1': 'Solicitando asociación a',
  'points-of-sale_drawer_associate-all-brands_modal-title-2': 'de',
  'points-of-sale_drawer_associate-all-brands_modal-title-3': 'marcas',
  'points-of-sale_drawer_associate-all-brands_modal-title-4': 'marca',
  'points-of-sale_drawer_associate-all-brands_modal-button-1': 'marcas asociadas',
  'points-of-sale_drawer_associate-all-brands_modal-button-2': 'Aplicando cambios',
  'points-of-sale_drawer_associate_button_associate-all': 'sin asociar',
  'points-of-sale_drawer_associate_button_associate-all-1': 'Asociar',
  'points-of-sale_drawer_associate_button_associate-all-2': 'Marcas',
  'points-of-sale_drawer_expand-less': 'Ver menos',
  'points-of-sale_drawer_expand-more': 'Ver todas',
  'points-of-sale_drawer_sub-title-1': 'Marcas con las que trabaja el punto de venta',
  'points-of-sale_drawer_sub-title-2': 'Añade marcas al punto de venta de',
  'points-of-sale_drawer_associate-all_popconfirm_title-1':
    '¿Quieres enviar solicitud de asociación a',
  'points-of-sale_drawer_associate-all_popconfirm_title-2': 'esta marca',
  'points-of-sale_drawer_associate-all_popconfirm_title-3': 'estas marcas',
  'points-of-sale_drawer_associate-all_popconfirm_description-1': 'más',
  'points-of-sale_drawer_associate-all_popconfirm_on-ok': 'Asociar',
  'points-of-sale_drawer_associate-all_popconfirm_on-cancel': 'Cancelar',
  'points-of-sale_drawer_associate-all_modal_title': 'Cambios realizados en',
  'points-of-sale_drawer_associate-all_modal_on-ok': 'Guardar cambios',
  'points-of-sale_drawer_associate-all_modal_on-cancel': 'Descartar cambios',
  'points-of-sale_drawer_associate-all_modal_description-1':
    'Hay cambios realizados en el punto de venta de',
  'points-of-sale_drawer_associate-all_modal_description-2':
    '¿Desea guardar los cambios?',
  'points-of-sale_drawer_name_input': 'Introduce un nombre',
  'points-of-sale_drawer_name_input-validation': 'Por favor, introduce un nombre',
  'points-of-sale_drawer_web_label': 'Web',
  'points-of-sale_drawer_web_input': 'Introduce una web',
  'points-of-sale_drawer_address_label': 'Dirección',
  'points-of-sale_drawer_address_input': 'Introduce una dirección',
  'points-of-sale_drawer_address_input-validation': 'Por favor, introduce una dirección',
  'points-of-sale_drawer_postal-code_label': 'Código postal',
  'points-of-sale_drawer_postal-code_input': 'Introduce un código postal',
  'points-of-sale_drawer_postal-code_input-validation':
    'Por favor, introduce un código postal',
  'points-of-sale_drawer_city_label': 'Ciudad',
  'points-of-sale_drawer_city_input': 'Introduce una ciudad',
  'points-of-sale_drawer_city_input-validation': 'Por favor, introduce una ciudad',
  'points-of-sale_drawer_country_label': 'País',
  'points-of-sale_drawer_province_label': 'Estado/Provincia',
  'points-of-sale_drawer_province_input': 'Introduce un estado/provincia',
  'points-of-sale_drawer_province_input-validation':
    'Por favor, introduce un estado/provincia',
  'points-of-sale_drawer_region_label': 'Región',
  'points-of-sale_drawer_region_input': 'Introduce una región',
  'points-of-sale_drawer_region_input-validation': 'Por favor, introduce una región',
  'points-of-sale_drawer_tel-sufix_label': 'Número de teléfono',
  'points-of-sale_drawer_tel_input': 'Introduce un número de teléfono',
  'points-of-sale_drawer_tel_input-validation':
    'Por favor, introduce un número de teléfono',
  'points-of-sale_drawer_data-share_label': 'Comparto datos',
  'points-of-sale_drawer_receiving_label': 'Recibo datos',
  'points-of-sale_drawer_connected_label': 'Conexión',
  'points-of-sale_drawer_brands_title':
    'Busca y agrega tus marcas en este punto de venta',
  'points-of-sale_drawer_brands_input-placeholder': 'Buscar',
  'points-of-sale_drawer_brands_more-brands': '¿Quieres una marca que no ves aquí?',
  'points-of-sale_drawer_add-point-of-sale_title': 'Agregar punto de venta',
  'points-of-sale_drawer_add-brand_title': 'Agregar nueva marca',
  'points-of-sale_drawer_edit-point-of-sale_title': 'Editar punto de venta',
  'points-of-sale_drawer_brands-to-associate-selected': 'Marcas de',
  'points-of-sale_drawer_brands-to-associate-selected-1': '+ Solicitud de asociación',
  'points-of-sale_drawer_brands-selected': 'Marcas en',
  'points-of-sale_drawer__brands--brand__add-brand': 'Añadir',
  'points-of-sale_drawer__brands--brand__asociate-brand': 'Asociar',
  'points-of-sale_drawer__edit-button': 'Editar',
  'points-of-sale_add-edit-sale-point_area': 'Supeficie comercial (M2)',
  'points-of-sale_add-edit-sale-point_center-code': 'Código de centro',
  'points-of-sale_add-edit-sale-point_main-contact': 'Contacto principal',
  //***************************************************************************** */
  //***************************************************************************** */
  'points-of-sale_drawer_remove-point-of-sale_confirm': 'Eliminar punto de venta',
  'points-of-sale_drawer_remove-point-of-sale_description':
    '¿Estás seguro que quieres eliminar este punto de venta?',
  'points-of-sale_drawer_remove-point-of-sale_button': 'Eliminar punto de venta',
  //***************************************************************************** */
  //***************************************************************************** */
  /////////////////////////////

  /// THANKS PAGE //////////////
  ThanksForRegister__register: 'REGISTRO MARCAS',
  ThanksForRegister__title: '¡Gracias por tu interés en Hub4Retail!',
  ThanksForRegister__subtitle:
    'Su requerimiento está siendo procesado y nos pondremos a la brevedad.',
  ThanksForRegister__button: 'Ir a la web',
  ////////////////////////////

  ///REGISTER BRAND///////////
  RegisterBrand__title1: '¿Listo para comenzar a',
  RegisterBrand__title2: 'llevar tu negocio al',
  RegisterBrand__title3: 'próximo nivel?',
  RegisterBrand__subtitle: 'Plataforma diseñada para:',
  RegisterBrand__ex1: 'Mejorar las herramientas de colaboración y uso compartido.',
  RegisterBrand__ex2: 'Garantizar la seguridad de los datos en todo momento.',
  RegisterBrand__ex3: 'Aumentar los márgenes de ingresos.',
  RegisterBrand__button: 'Volver',
  RegisterBrand__register: 'Registro',
  registerBrand__form__name: 'Nombre',
  registerBrand__form__lastName: 'Apellido',
  registerBrand__form__business: 'Razón social',
  registerBrand__form__company: 'Nombre Comercial',
  registerBrand__form__phone: 'Web',
  registerBrand__form__email: 'Teléfono',
  registerBrand__form__web: 'Email Corporativo',
  registerBrand__form__description: '¿Desea decirnos algo más?',
  registerBrand__form__selector: 'Sector',
  'registerBrand__form__say-more': '¿Desea decirnos algo más?',
  registerBrand__form__selector__optionDefault: '',
  registerBrand__form__selector__option1: 'Calzado',
  registerBrand__form__selector__option2: 'Accesorios',
  registerBrand__form__selector__option3: 'Hogar-Jardín',
  registerBrand__form__selector__option4: 'Belleza',
  registerBrand__form__selector__option5: 'Electrónico',
  registerBrand__form__selector__option6: 'Deporte',
  registerBrand__form__selector__option7: 'Automotriz',
  registerBrand__form__selector__option8: 'Alimenticio',
  registerBrand__form__selector__option9: 'Otro',
  ////////////////////////////

  // BRAND PAGE //////////
  'brand-page_send-association-btn': 'Enviar solicitud de asociación',
  'brand-page_history-timeline_title': 'Historial',
  'brand-page_history-points-of-sale_title': 'Puntos de Venta y Conexión',
  'brand-page_history-points-of-sale_total': 'Total',
  'brand-page_history-points-of-sale_name': 'Nombre',
  'brand-page_history-points-of-sale_address': 'Dirección',
  'brand-page_history-points-of-sale_city': 'Ciudad',
  'brand-page_history-points-of-sale_recive-data': 'Recibo Datos',
  'brand-page_history-points-of-sale_recive-data_active': 'Activo',
  'brand-page_history-points-of-sale_recive-data_inactive': 'Inactivo',
  'brand-page_history-points-of-sale_status_associate': 'Asociado',
  'brand-page_history-points-of-sale_status_pending': 'Pendiente',
  'brand-page_history-points-of-sale_status_no-associate': 'No asociado',
  'brand-page_history-points-of-sale_status': 'Estado',
  'brand-page_history-points-of-sale_status_createdAt': 'Fecha',
  'brand-page_history-points-of-sale_share-data': 'Comparto Datos',
  'brand-page_history-points-of-sale_associated': 'Asociado',
  'brand-page_history-points-of-sale_not-associated': 'No asociado',
  'brand-page_history-active-contacts_name': 'Nombre',
  'brand-page_history-active-contacts_email': 'Email',
  'brand-page_history-active-contacts_phone-number': 'Nº Teléfono',
  'brand-page_history-active-contacts_department': 'Departamento',
  'brand-page_history-active-seasons_title': 'Temporadas Activas',
  'brand-page_history-active-contacts_title': 'Contactos Principales',
  'brand-page_history-contacts_popover-title': 'Información del contacto',
  'brand-page_history-contacts_popover-content_name': 'Nombre:',
  'brand-page_history-contacts_popover-content_surname': 'Apellido:',
  'brand-page_history-contacts_popover-content_email': 'Email:',
  'brand-page_history-contacts_popover-content_phone-number': 'Nº Teléfono:',
  'brand-page_history-contacts_popover-content_sector': 'Sector:',
  ////////////////////////

  // SETTINGS PAGE ////////
  'settings-page_nav_chronos': 'Chronos',
  'settings-page_nav_devices': 'Dispositivos',
  'settings-page_nav_touch-screen': 'Pantallas Táctiles',
  'settings-page_nav_users': 'Usuarios',
  'settings-page_nav_system-settings': 'Ajustes del Sistema',
  'settings-page_nav_meta': 'Meta',
  'settings-page_nav_billing': 'Facturación',
  'settings-page_nav_support': 'Soporte',
  // Users page
  'settings-page_users-page_create-user-btn': 'Crear usuario',
  /////////////////////////

  // BRAND FEED PAGE ////////
  'brand-feed_page_info_title': 'Acerca de nosotros',
  'brand-feed_page_buttons_connect': 'Conectar',
  'brand-feed_page_videos_title': 'Catálogo Multimedia',
  'brand-feed_page_videos_subtitle': 'Videos relacionados',
  ///////////////////////////

  // NOTIFICATIONS /////////////////
  // Notifications Page
  'notifications-page_notifications-title': 'Notificaciones',
  'notifications-page_alerts-title': 'Alertas',
  'notifications_btn-name_catalog': 'Ver Catálogo',
  'notifications_btn-name_profile': 'Ver Perfil',
  'notifications_list_show-all': 'Todo',
  'notifications_list_show-unreaded': 'No leído',
  'notifications_list_per-page-select': 'por página',
  // Popover
  notifications_popover_title: 'NOTIFICACIONES',
  'notifications_popover_empty-notifications': 'No hay notificaciones',
  'notifications_popover_btn-name_catalog': 'Ver Catálogo',
  'notifications_popover_btn-name_profile': 'Ver Perfil',
  'notifications_popover_item_mark-as-readed_msg': 'Marcado como leído',
  'notifications_popover_item_mark-as-unreaded_msg': 'Marcado como no leído',
  'notifications_popover_item_mark-as-readed': 'Marcar como leído',
  'notifications_popover_item_mark-as-unreaded': 'Marcar como no leído',
  'notifications_popover_all-notifications-btn': 'Todas las notificaciones',
  notifications_popover_accept: 'Aceptar',
  notifications_popover_reject: 'Rechazar',
  alerts_popover_title: 'ALERTAS',
  'alerts_popover_empty-notifications': 'No hay alertas',
  'alerts_popover_all-notifications-btn': 'Todas las alertas',
  //////////////////////////////////

  // SALES ///////////////////////////
  'sales_csv-info_title': 'Información del CSV',
  'sales_csv-info_columns': 'Columnas:',
  'sales_csv-info_rows': 'Filas:',
  'sales_csv-info_separator': 'Separador:',
  'sales_csv-info_headers': 'Cabeceras:',
  'sales_csv-info_errors': 'Errores:',
  // Add sales CSV
  'sales_add-sales-csv-input-title': 'Subir CSV',
  'sales_add-sales-csv_warning-msg-file-pnly-contain-a-header':
    'El archivo solo contiene una cabecera',
  'sales_add-sales-csv-error-column-mismatch':
    'Algunas líneas no coindicen con las cabeceras del CSV',
  'sales_add-sales-xlsx-input-title': 'Subir XLSX',
  'sales_add-sales-xlsx_select-row_description':
    'Selecciona la fila que contienen las cabeceras',
  'sales_add-sales-xlsx_select-row_input-placeholder': 'Nº Fila',
  'sales_add-sales-csv-s2-text-1': 'Subir CSV o XLSX de ventas',
  'sales_add-sales-csv-s2-text-2': 'Crea o selecciona un mapeo de columnas',
  // Handle not matched points of sale
  'sales_csv-upload_not-matched-points-of-sale_title': 'Puntos de venta que no coinciden',
  'sales_csv-upload_not-matched-points-of-sale_description':
    ' Los siguientes puntos de venta no coinciden con los puntos de venta de la empresa. Por favor, selecciona un punto de venta de la lista para cada punto de venta no coincidente.',
  'sales_csv-upload_not-matched-points-of-sale_checking-matching':
    'Comprobando puntos de venta...',
  // My csv mappings
  'sales_csv-mappings_title': 'Mapa coincidente',
  'sales_csv-mappings_title-create': 'Crea un mapa para el CSV',
  'sales_csv-mappings_save-file_success': 'Archivo de ventas enviado correctamente',
  'sales_csv-mappings_send-file-btn': 'Enviar ventas',
  // Create mapping form
  'sales_csv-mapping-form_title': 'Selecciona las cabeceras del CSV para crear un mapa.',
  'sales_csv-mapping-form_columns-without-reference': 'Columnas sin refenciar del CSV:',
  'sales_csv-mapping-form_periodicity': 'Periodicidad',
  'sales_csv-mapping-form_periodicity_required': 'La periodicidad es requerida',
  'sales_csv-mapping-form_periodicity_dairy': 'Diario',
  'sales_csv-mapping-form_periodicity_weekly': 'Semanal',
  'sales_csv-mapping-form_periodicity_biweekly': 'Quincenal',
  'sales_csv-mapping-form_save-map-btn': 'Guardar mapa',
  'sales_csv-mapping-form_onfinish_field-required-msg':
    'Los campos marcados con * son obligatorios',
  'sales_csv-mapping-form_onfinish_map-points-of-sale-required':
    'Todos los puntos de venta deben ser mapeados',
  // Mapping table
  'sales_csv-mapping-table_column-1_label': 'Campos de ventas',
  'sales_csv-mapping-table_column-2_label': 'Columnas del CSV',
  'sales_csv-mapping-table_column-2_select-label': 'Selecciona una columna',
  'sales_csv-mapping-table_column-2_select-label-multiple':
    'Selecciona una o varias columnas',
  'sales_csv-mapping-table_column-2_select-format_label': 'Selecciona un formato',
  'sales_csv-mapping-table_column-3_label': 'Datos resultantes',
  // MyCSVMappingInfoPopover
  'sales_csv-mapping-info-popover_periodicity': 'Periodicidad:',
  'sales_csv-mapping-info-popover_periodicity-days': 'días',
  'sales_csv-mapping-info-popover_date-format': 'Formato de fecha:',
  'sales_csv-mapping-info-popover_points-of-sale-mapped': 'Puntos de venta mapeados:',
  'sales_csv-mapping-info-popover_references': 'Referencias',
  'sales_csv-mapping-info-popover_tags': 'Tags',
  'sales_csv-mapping-info-popover_columns-without-references': 'Columnas sin referenciar',
  'sales_csv-mapping-info-popover_popconfirm_title':
    '¿Estás seguro de eliminar este mapa?',
  'sales_csv-mapping-info-popover_popconfirm_btn': 'Eliminar mapa',
  'sales_csv-mapping-info-popover_popconfirm_ok': 'Sí',
  'sales_csv-mapping-info-popover_popconfirm_cancel': 'No',
  // SalesHistoryPage
  'sales-history_page_title': 'Historial de subida de ventas',
  'sales-history_page_upload-btn': 'Subir ventas',
  'sales-history_page_table_column-1_title': 'Fecha de subida',
  'sales-history_page_table_column-2_title': 'Nombre del archivo',
  'sales-history_page_table_column-3_title': 'Estado',
  'sales-history_page_table_column-3_processed': 'Procesado',
  'sales-history_page_table_column-3_pending': 'Pendiente de procesar',
  'sales-history_page_table_column-4_popover_title': 'Eliminar subida de ventas',
  'sales-history_page_table_column-4_popover_description':
    '¿Estás seguro de eliminar la subida de ventas?',
  'sales-history_page_table_column-4_delete-btn': 'Eliminar',
  'sales-history_page_table_column-4_dowload-processed-success':
    'Archivo procesado descargado correctamente',
  'sales-history_page_table_column-4_dowload-error': 'Error al descargar el archivo',
  'sales-history_page_table_column-4_dowload-original-success':
    'Archivo original descarga correctamente',
  'sales-history_page_table_column-4_dowload-processed-file':
    'Descargar archivo procesado',
  'sales-history_page_table_column-4_dowload-original-file': 'Descargar archivo original',
  'sales-history_page_table_original-name': 'Nombre original del archivo',
  'sales-history_page_table_upload-type': 'Tipo de subida',
  'sales-history_page_table_file-type': 'Tipo de archivo',
  'sales-history_page_table_pending-mapping-title': 'Mapeo pendiente',
  'sales-history_page_table_mapping-error-title': 'Ha ocurrido un error',
  'sales-history_page_table_download-file-error-msg': 'Error al descargar el archivo',
  'sales-history_page_table_download-file-format-not-supported':
    'Formato de archivo no soportado',
  'sales-history_page_table_create-mapping-label': 'Crear mapeo',
  'sales-history_page_table_info-popover_title':
    'Lineas del archivo en las que está fallando',
  'sales-history_page_table_info-popover_contact-with-us':
    'Si el problema persiste, comuníquese con el equipo de soporte (help@hub4retail.com), y proporcione este número de identificación del problema:',
  'sales-history_page_table_info-popover_UNKNOWN_CHAR':
    'Se ha encontrado un carácter que no coincide con el encoding especificado en el archivo.',
  'sales-history_page_table_info-popover_PTO_MISSING':
    'Mapeo de punto de venta pendiente.',
  'sales-history_page_table_info-popover_MISSING_PTO_MAP':
    'Mapeo de punto de venta pendiente.',
  'sales-history_page_table_info-popover_SKIP': 'No se ha podido procesar, se ignora.',
  'sales-history_page_table_info-popover_MAP_ID_NOT_FOUND':
    'No coincide ningún mapa, crea el mapa para procesar el archivo.',
  'sales-history_page_table_info-popover_MAPS_NOT_FOUND':
    'No coincide ningún mapa, crea el mapa para procesar el archivo.',
  'sales-history_page_table_info-popover_MAP_NOT_FOUND':
    'No coincide ningún mapa, crea el mapa para procesar el archivo.',
  'sales-history_page_table_info-popover_BRAND_CLIENT_ID_NOT_FOUND':
    'Hay un archivo creado en una carpeta con id de marca o cliente que ya no existe en la base de datos.',
  'sales-history_page_table_info-popover_FOLDER_NOT_FOUND':
    'El el registro de usuarios de sftp hay un id de marca o cliente pero la carpeta con este id no existe en Amazon S3 por tanto no es posible obtener los archivos.',
  'sales-history_page_table_info-popover_FOLDER_LIST_UNAVAILABLE':
    'No es posible conectarse a Amazon S3. El usuario de aws se ha borrado o los permisos han cambiado haciendo la conexión a S3 imposible.',
  'sales-history_page_table_info-popover_FILES_NOT_FOUND':
    'La carpeta de esa marca o cliente está vacía. No hay archivos para procesar.',
  'sales-history_page_table_info-popover_NO_CONTENT_TYPE':
    'El archivo subido a aws no tiene un content type definido.',
  'sales-history_page_table_info-popover_NO_ENCODING':
    'El archivo subido a aws no tiene un content type definido.',
  'sales-history_page_table_info-popover_NO_FILE_METADATA':
    'No es posible leer el contenido del archivo.',
  'sales-history_page_table_info-popover_CANNOT_CREATE_BUFFER':
    'No es posible convertir el datastream del archivo a buffer.',
  'sales-history_page_table_info-popover_HEADERS_NOT_FOUND':
    'No se han encontrado las cabeceras del archivo.',
  'sales-history_page_table_info-popover_SIGNATURE_MISMATCH':
    'No coincide ningún mapa, crea el mapa para procesar el archivo.',
  'sales-history_page_table_info-popover_SEPARATOR_NOT_FOUND':
    'No hay un separador en el mapa.',
  'sales-history_page_table_info-popover_SEPARATOR_MISMATCH':
    'El separador en el mapa no coincide con el separador encontrado en el header del arhivo.',
  'sales-history_page_table_info-popover_DATAEFORMAT_NOT_FOUND':
    'No se ha encontrado el formato de fecha.',
  'sales-history_page_table_info-popover_DATE_PARSE_ERROR':
    'El formato de fecha en el mapa no coincide con el formato de fecha del archivo.',
  'sales-history_page_table_info-popover_JSON_NORM_ERROR':
    'Se aborta la lectura de data del archivo(json) debido a errores.',
  'sales-history_page_table_info-popover_JSON_PARSE_ABORT':
    'Debido a errores se ha abortado el proceso de lectura.',
  'sales-history_page_table_info-popover_BRAND_CLIENT_NOT_FOUND':
    'No se encuentran ids de marca o cliente.',
  'sales-history_page_table_info-popover_MISSING_CSV_DATA':
    'El csv no tiene datos. Elimina el archivo si es necesario.',
  'sales-history_page_table_info-popover_DATA_TRIM_ERROR':
    'No se pueden normalizar los espacios de algunos strings.',
  'sales-history_page_table_info-popover_DATA_INTEGRITY_ERROR':
    'El numero de columnas de algunas filas no coincide con el número de cabeceras. Arregla el archivo y vuelve a subirlo.',
  'sales-history_page_table_search_reset': 'Restaurar',
  'sales-history_page_delete-sales-history-not-processed-popover_title':
    'Eliminar todas las ventas no procesadas',
  'sales-history_page_delete-sales-history-not-processed-popover_description':
    '¿Estás seguro de que deseas eliminar todas las ventas no procesadas?',
  'sales-history_page_delete-sales-history-not-processed-popover_delete-all-btn':
    'Eliminar todo',
  'sales-history_page_delete-sales-history-not-processed-msg-success':
    'Todos los archivos no procesados han sido eliminados',
  'sales-history_page_reload-sales-history': 'Recargar historial',
  ////////////////////////////////////

  // MAPPING KEYS //////////////////////////////////////
  // Sales mapping keys
  'mapping-keys-field__multibrand': 'Multimarca',
  'mapping-keys-field__multibrand_point_of_sale': 'Punto de venta',
  'mapping-keys-field__point_of_sale_id': 'ID de punto de venta',
  'mapping-keys-field__client_id': 'ID de multimarca',
  'mapping-keys-field__date': 'Fecha',
  'mapping-keys-field__hour': 'Hora',
  'mapping-keys-field__floor': 'Planta',
  'mapping-keys-field__caja': 'Caja',
  'mapping-keys-field__order_number': 'Nº ticket',
  'mapping-keys-field__order_line': 'Linea ticket',
  'mapping-keys-field__product_reference': 'Referencia Producto',
  'mapping-keys-field__product_description': 'Descripción Producto',
  'mapping-keys-field__brand_ean': 'Ean Marca',
  'mapping-keys-field__product_ean_code': 'Ean Multimarca',
  'mapping-keys-field__product_brand': 'Marca',
  'mapping-keys-field__color_code': 'Codigo Color',
  'mapping-keys-field__product_color': 'Nombre Color',
  'mapping-keys-field__product_size_eu': 'Talla',
  'mapping-keys-field__product_season': 'Temporada',
  'mapping-keys-field__division': 'División',
  'mapping-keys-field__family': 'Familia',
  'mapping-keys-field__subfamily': 'Sub Familia',
  'mapping-keys-field__gender': 'Género',
  'mapping-keys-field__units': 'Unidades',
  'mapping-keys-field__price': 'Precio Venta',
  'mapping-keys-field__cost': 'Precio Compra',
  'mapping-keys-field__discount': 'Dto Importe',
  'mapping-keys-field__discount_percentaje': 'Dto%',
  'mapping-keys-field__payment_method': 'Forma de Pago',
  'mapping-keys-field__seller': 'Vendedor',
  //*************************************************************************** */
  //*************************************************************************** */
  // Products mapping keys
  'mapping-keys-field__name': 'Nombre del producto',
  'mapping-keys-field__reference': 'Referencia',
  'mapping-keys-field__color': 'Color',
  'mapping-keys-field__season': 'Temporada',
  'mapping-keys-field__ean': 'EAN',
  'mapping-keys-field__material': 'Composición',
  'mapping-keys-field__segmentation': 'Segmentación',
  'mapping-keys-field__tier': 'Segmentación Catálogo (Tier)',
  'mapping-keys-field__color_description': 'Descripción del color',
  'mapping-keys-field__pvi': 'PVI',
  'mapping-keys-field__pvpr': 'PVPR',
  'mapping-keys-field__size': 'Talla',
  'mapping-keys-field__tags': 'Etiquetas',
  //*************************************************************************** */
  //*************************************************************************** */
  //////////////////////////////////////////////////////

  // META ///////////////////////////
  meta_connections_title: 'Conexiones',
  'meta_connections_new-connection': '+ Nueva',
  meta_connections_modal_title: 'Nueva conexión',
  'meta_connections_modal_form_user-name': 'Nombre de usuario',
  'meta_connections_modal_form_user-name_required':
    'Por favor ingrese el nombre de usuario',
  meta_connections_modal_form_password: 'Contraseña',
  meta_connections_modal_form_password_required: 'Por favor ingrese la contraseña',
  meta_connections_modal_form_period: 'Frecuencia',
  meta_table_createdAt: 'Fecha de creación',
  'meta_table_user-name': 'Usuario',
  meta_table_password: 'Contraseña',
  meta_table_type: 'Tipo',
  meta_table_period: 'Frecuencia de envío',

  //********************************************************************** */
  //********************************************************************** */
  'meta_table_connection-name': 'Nombre de la conexión',
  'meta_table_last-update': 'Última subida',
  meta_table_status: 'Estado',
  'meta_connections_modal_popover-title': 'Información de conexión',
  'meta_connections_edit-info_title': 'Edita la información de la conexión',
  'meta_connections_edit-info_btn-tooltip': 'Editar conexión',
  'meta_connections_remove-popover_title': 'Eliminar conexión con Shopify',
  'meta_connections_remove-popover_description':
    '¿Estás seguro de que deseas eliminar la conexión con Shopify?',
  'meta_connections_edit-connection-mapping_title': 'Edita el mapa de datos a procesar',
  'meta_connections_edit-connection-mapping_select': 'Selecciona el tipo de mapeo:',
  'meta_connections_edit-connection-mapping_select_products': 'Productos',
  'meta_connections_edit-connection-mapping_select_sales': 'Ventas',
  // Shopify form
  meta_connections_modal_form_shopify_domain: 'Dominio',
  'meta_connections_modal_form_shopify_domain-required':
    'El dominio de Shopify es requerido',
  'meta_connections_modal_form_shopify_access-token': 'Token de acceso',
  'meta_connections_modal_form_shopify_access-token-required':
    'El token de acceso de Shopify es requerido',
  'meta_connections_modal_form_shopify_access-api-secret-key': 'Clave secreta de API',
  'meta_connections_modal_form_shopify_access-api-secret-key-required':
    'La clave secreta de API de Shopify es requerida',
  // Prestashop form
  'meta_connections_modal_form_prestashop_store-url': 'URL de la tienda',
  'meta_connections_modal_form_prestashop_store-url-required':
    'La URL de la tienda de Shopify es requerida',
  meta_connections_modal_form_prestashop_version: 'Versión de Prestashop',
  'meta_connections_modal_form_prestashop_version-required':
    'La versión de Prestashop es requerida',
  // Odoo form
  'meta_connections_modal_form_odoo_instance-url': 'URL de instancia',
  'meta_connections_modal_form_odoo_instance-url-required':
    'La URL de instancia de Odoo es requerida',
  'meta_connections_modal_form_odoo_user-name': 'Nombre de usuario',
  'meta_connections_modal_form_odoo_user-name-required':
    'El nombre de usuario de Odoo es requerido',
  'meta_connections_modal_form_odoo_database-name': 'Nombre de la base de datos',
  'meta_connections_modal_form_odoo_database-name-required':
    'El nombre de la base de datos de Odoo es requerido',
  // Shared
  'meta_connections_api-connections_comming-soon': 'Próximamente...',
  'meta_connections_create-new-connection_connection-type': 'Tipo de conexión:',
  'meta_connections_form-items_tag-form-item_label': 'Nombre de la conexión',
  'meta_connections_form-items_tag-form-item_label-required':
    'Por favor, introduce un nombre',
  'meta_connections_modal_form_access-api-key': 'Clave de API',
  'meta_connections_modal_form_access-api-key-required':
    'La clave de API de Shopify es requerida',
  //********************************************************************** */
  //********************************************************************** */

  ///////////////////////////////////

  // ACTIONS/REDUCER ///////////////
  // Main contacts
  'action_get-main-contacts_error': 'Error al obtener los contactos principales',
  'action_select-main-contact_error': 'Error al seleccionar los contactos principales',
  'action_create-main-contact_success': 'Contacto creado correctamente',
  'action_create-main-contact_error': 'Error al crear el contacto',
  'action_update-main-contact_success': 'Contacto actualizado con correctamente',
  'action_update-main-contact_error': 'Error al actualizar el contacto',
  'action_delete-main-contact_success': 'Contacto eliminado correctamente',
  'action_delete-main-contact_error': 'Error al eliminar el contacto',
  // My Brands
  'action_my-brands_get-my-brands_error': 'Error al obtener mis marcas',
  'action_my-brands_remove-from-my-brands_success': 'Marca desvinculada correctamente',
  'action_my-brands_remove-from-my-brands-multiple_success':
    'Marcas desvinculadas correctamente',
  'action_my-brands_remove-from-my-brands_error': 'Error al desvincular la marca',
  'action_my-brands_add-to-my-brands_exists':
    'Ya has enviado una solicitud de conexión a esta marca',
  'action_my-brands_add-to-my-brands_error': 'Error al añadir la marca',
  'action_my-brands_add-to-my-brands_success':
    'Solicitud de asociación enviada correctamente',
  'action_my-brands_create-new-brand_success': 'Marca agregada correctamente',
  'action_my-brands_create-new-brand_exists': 'Ya existe una marca con el mismo nombre',
  'action_my-brands_add-to-my-brands_brand-without-tenant':
    'La marca todavía no está en la plataforma, nos pondremos en contacto contigo cuando esté disponible',
  // Brand
  'action_brand_get-brand_error': 'Error al obtener la marca',
  'action_brand_get-brand_contact-errors': 'Error al obtener los contactos de la marca',
  'action_brand_share-data-with-brand_error': 'Error al compartir datos con la marca',
  'action_brand_share-data-with-brand_share-success':
    'Compartiendo datos con el punto de venta',
  'action_brand_share-data-with-brand_no-share-success':
    'Has dejado de compartir datos con el punto de venta',
  // Sales CSV upload
  'sales-csv-upload_reject-upload-success': 'Subida de ventas eliminada correctamente',
  'sales-csv-upload_reject-upload-error': 'Error al eliminar la subida de ventas',
  'sales-csv-upload_get-mappings-error-1': 'Error al obtener las claves del mapa',
  'sales-csv-upload_get-mappings-error-2':
    'Error del servidor al obtener las claves del mapa',
  'sales-csv-upload_get-mappings-error-3': 'Error al obtener mis mapas',
  'sales-csv-upload_delete-mapping-success': 'Mapa eliminado correctamente',
  'sales-csv-upload_delete-mapping-error': 'Error al eliminar el mapa',
  'sales-csv-upload_create-mapping-success': 'Mapa creado correctamente',
  'sales-csv-upload_create-mapping-success_without-send-file':
    'Mapa creado correctamente. Suele tardar menos de dos minutos en aparecer en la lista.',
  'sales-csv-upload_create-mapping-error': 'Error al crear el mapa',
  // Meta
  'action_meta_get-connections_error': 'Error al obtener las conexiones',
  'action_meta_delete-connection_success': 'Conexión eliminada correctamente',
  'action_meta_delete-connection_error': 'Error al eliminar la conexión',
  'action_meta_create-connection_success': 'Conexión creada correctamente',
  //******************************************************************* */
  //******************************************************************* */
  'action_meta_create-sftp-connection_error-403':
    'El nombre de usuario ya existe. Prueba con otro.',
  'action_meta_get-mapping-keys_error': 'Error al obtener las claves del mapa',
  'action_meta_create-shopify-connection_error-409': 'El dominio ya está en uso',
  'action_meta_create-shopify-connection_error': 'Error al crear la conexión',
  //******************************************************************* */
  //******************************************************************* */
  'action_meta_create-connection_error': 'Error al crear la conexión',
  //////////////////////////

  // MESSAGE PAGES //////////
  // Error
  'errors-page_404_subtitle': 'Lo sentimos, la página que estás buscando no existe.',
  'errors-page_404_btn-go-back': 'Volver',
  // Coming soon
  'coming-soon-page_title': '¡Próximamente!',
  'coming-soon-page_go-back': 'Volver',
  /////////////////////////

  // BREADCRUMBS ///////////////
  breadcrumbs_multimedia: 'Multimedia',
  'breadcrumbs_multimedia-videos': 'Videos',
  'breadcrumbs_multimedia-images': 'Imágenes',
  // Sales
  breadcrumbs_sales: 'Ventas',
  'breadcrumbs_sales-history': 'Historial de subidas',
  'breadcrumbs_sales-create': 'Subir ventas',
  // Catalog
  breadcrumb_catalog: 'Catálogo',
  breadcrumb_product: 'Todas las marcas',
  'breadcrumb_catalog-download': 'Descargar catálogo',
  // Clients
  breadcrumbs_clients: 'Clientes',
  'breadcrumbs_clients-list': 'Listado clientes',
  'breadcrumbs_add-client': 'Añadir cliente',
  breadcrumbs_resume: 'Resumen',
  'breadcrumbs_business-info': 'Datos de la empresa',
  'breadcrumbs_main-contacts': 'Contactos principales',
  'breadcrumbs_sale-points': 'Puntos de venta',
  'breadcrumbs_commercial-info': 'Información comercial',
  // Account
  breadcrumbs_account: 'Mi Cuenta',
  breadcrumbs_account_profile: 'Perfil',
  breadcrumbs_account_company: 'Datos de empresa',
  breadcrumbs_account_contacts: 'Contactos Principales',
  'breadcrumbs_account_my-brands': 'Mis Marcas',
  'breadcrumbs_account_points-of-sale': 'Puntos de venta',
  //****************************************************************** */
  //****************************************************************** */
  // Meta
  breadcrumbs_meta_edit_connetion: 'Editar conexión',
  //****************************************************************** */
  //****************************************************************** */
  //////////////////////////////

  // EMAIL VERIFIED MODAL ///////
  'modal_email-verified_title': 'Revisa tu correo electrónico',
  'modal_email-verified_content':
    'Para continuar con el proceso, necesitamos que nos confirmes la dirección de correo electrónico',
  'modal_email-verified_content-1':
    'Verifica la dirección desde el enlace del correo recibido y a continuación pulsa',
  'modal_email-verified_content_btn_resend': 'Reenviar verificación',
  'modal_email-verified_content_btn_reload': 'Recargar',
  'modal_email-verified_content_notif_resend_title': 'Notificación de envío',
  'modal_email-verified_content_notif_resend_content-1':
    'Se ha enviado el email de verificación a esta dirección',
  'modal_email-verified_content_notif_resend_content-2':
    'Revisa el correo para continuar.',
  //////////////////////////////

  // POLICY PRIVACY MODAL //////////////////
  'policy-privacy-modal_title': 'Condiciones del servicio',
  'policy-privacy-modal_btn-accept': 'Aceptar',
  'policy-privacy-modal_btn-cancel': 'Cancelar',
  'policy-privacy-modal_checkbox': 'Acepto las condiciones del servicio',
  'policy-privacy-modal_checkbox-message':
    'Acepta la política de privacidad y condiciones.',
  ////////////////////////////

  // SERVER DOWN MODAL /////
  'server-down-modal_title': 'Error del servidor',
  'server-down-modal_content':
    'Estamos teniendo problemas con nuestros servicios, vuelva a intentarlo más tarde o póngase en contacto con el servicio técnico.',
  //////////////////////////

  // NOTIFICATIONS /////////
  'notification-error_message': 'Código de error',
  'notification-error_description':
    'Vuelve a itentarlo más tarde. Si el problema persiste, comuníquese con Hub4Retail (help@hub4retail.com) y proporcione el código de error.',
  //////////////////////////

  // GOOGLE ADDRESS FORM //////
  'google-address-form_address': 'Dirección',
  'google-address-form_floor': 'Piso',
  'google-address-form_door': 'Puerta',
  'google-address-form_postal-code': 'Código Postal',
  'google-address-form_city': 'Población',
  'google-address-form_province': 'Provincia',
  'google-address-form_country': 'País',
  'google-address-form_select-country': 'Seleccione un país',
  /////////////////////////////

  //************************************************************************************************** */
  //************************************************************************************************** */
  // UI ///////////////////////
  'copy-to-clipboard-success': 'Copiado al portapapeles',
  /////////////////////////////
  //************************************************************************************************** */
  //************************************************************************************************** */

  // SHARED //////////////////
  settings: 'Ajustes',
  logout: 'Cerrar sesión',
  'admin-panel_link': 'Panel de administrador',
  'btn-save': 'Guardar',
  'btn-cancel': 'Cancelar',
  'see-more-content-button': 'Ver más',
  'see-less-content-button': 'Ver menos',
  'see-all-content-button': 'Ver todas',
  'search-btn-label': 'Buscar',
  'close-btn-label': 'Cerrar',
  //******************************************************************************************* */
  //******************************************************************************************* */
  'remove-label': 'Eliminar',
  //******************************************************************************************* */
  //******************************************************************************************* */
  ////////////////////////////
};

export default spanishTranslation;
