import { TResponseApi } from 'interfaces/general.interface';
import { basePath } from './utils/config';
import { TCountryItem } from 'interfaces/countries.interface';

type TGetCountryDataApi = {
  data: TCountryItem[];
};
export const getCountriesAPI = async (
  token: string
): Promise<TResponseApi & TGetCountryDataApi> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${basePath}/country/get_all`, params);
  const data = await response.json();
  return { response, data };
};
