import { IBrandUser } from 'interfaces/brandUsers.interface';
import {
  ISAdminClientState,
  IInitialSuperAdminState,
} from 'interfaces/superAdmin.interface';

const initialSuperAdminState: IInitialSuperAdminState = {
  clients: [] as ISAdminClientState[],
  removeUserClient: (clientId: string, userId: string) => {},
  addUserClient: (clientId: string, userId: IBrandUser) => {},
};

export default initialSuperAdminState;
