import * as apiUser from 'api/user.api';
import * as apiMyBrands from 'api/myBrands.api';
import * as apiCompany from 'api/company.api';
import * as UserTypes from './user.types';
import { Company, DbUser } from 'types';
import { notificationErr } from 'views/components/UI/notification';
import { userCompanyError } from 'utils/errorCodes';
import { messageError, messageSuccess } from 'views/components/UI/message';
import * as status from 'contexts/myBrands/constants';
import * as key from 'utils/constants';
import {
  ISAdminClientState,
  ISAdminUserClientState,
} from 'interfaces/superAdmin.interface';
import {
  ICompanyState,
  IDbUserState,
  IInitialUserState,
  TSocialMedia,
  TUpdateCompanyProps,
  TUpdateLanguageProps,
} from 'interfaces/user.interface';
import { TLanguageResponseItem } from 'api/responseInterfaces/general.response.interface.';

let userId: string;
export async function loginUserAction(
  dispatch: {
    (value: any): void;
    (arg0: { type: string; payload: { user: any; company: any } }): any;
  },
  token: string,
  auth0User: any,
  translate: any,
  logout: any
) {
  dispatch({ type: UserTypes.SET_IS_LOADING, payload: true });
  try {
    const [userFetch, associationStatesFetch] = await Promise.all([
      apiUser.loginDbUserAPI(token),
      apiMyBrands.getAssociationsStatesAPI(token),
    ]);
    let stateValues = {
      pending: {},
      connected: {},
      rejected: {},
      disconnected: {},
    };
    if (
      associationStatesFetch?.response?.status !== 200 ||
      !associationStatesFetch.data ||
      associationStatesFetch?.data?.length === 0
    ) {
      notificationErr(userCompanyError.login_user_006, translate);
    } else {
      for (const state of associationStatesFetch.data) {
        if (state.state === status.pending) {
          stateValues.pending = state;
        }
        if (state.state === status.connected) {
          stateValues.connected = state;
        }
        if (state.state === status.rejected) {
          stateValues.rejected = state;
        }
        if (state.state === status.disconnected) {
          stateValues.disconnected = state;
        }
      }
      dispatch({ type: UserTypes.SET_ASSOCIATIONS_STATES, payload: stateValues });
    }
    await loginExcep(
      userFetch,
      logout,
      translate,
      dispatch,
      token,
      userCompanyError.login_user_001,
      stateValues
    );
  } catch (err) {
    return notificationErr(userCompanyError.login_user_002, translate);
  } finally {
    dispatch({ type: UserTypes.SET_IS_LOADING, payload: false });
    if (userId && auth0User.email_verified === true) {
      try {
        const body = {
          filter: { id: userId },
          update: { enabled: true },
        };
        const verifyFetch = await apiUser.verifyEmailAPI(body, token);
        if (verifyFetch.response.status === 200) {
          return dispatch({
            type: UserTypes.VERIFY_EMAIL,
            payload: true,
          });
        }
      } catch (error) {
        messageError('Error al verificar el usuario.');
        return notificationErr(userCompanyError.login_user_003, translate);
      }
    } else {
      return dispatch({
        type: UserTypes.VERIFY_EMAIL,
        payload: false,
      });
    }
  }
}

export async function updateUserAction(
  dispatch: { (value: any): void; (arg0: { type: string; payload: any }): any },
  dbUser: IDbUserState,
  data: DbUser,
  token: string,
  setIsOpenDrawer: (boolean: boolean) => void,
  translate: any
) {
  const body = { filter: { id: dbUser.id }, update: data };
  try {
    const fetch = await apiUser.updateDbUserAPI(body, token);

    if (fetch.response.status === 403) {
      messageError('No tienes permisos.');
      return notificationErr(userCompanyError.update_user_002, translate);
    }

    if (fetch.response.status === 200) {
      messageSuccess('Usuario actualizado correctamente.');
      setIsOpenDrawer(false);
      return dispatch({
        type: UserTypes.UPDATE_USER,
        payload: data,
      });
    }
  } catch (err) {
    messageError('Error al actualizar el usuario.');
    return notificationErr(userCompanyError.update_user_001, translate);
  }
}

export async function updateCompanyAction(
  dispatch: { (value: any): void; (arg0: { type: string; payload: Company }): any },
  userState: IInitialUserState,
  companyData: TUpdateCompanyProps,
  token: string,
  setIsOpenDrawer: (boolean: boolean) => void,
  translate: any
) {
  const newSocialMedia = companyData.new_social_media;
  delete companyData.new_social_media;
  const body = { filter: { id: userState.company.id }, update: companyData };
  try {
    const [companyFetch, socialMediaFetch] = await Promise.all([
      apiCompany.updateCompanyDataAPI(body, token),
      apiCompany.addSocialMediaAPI(userState.company.id, newSocialMedia, token),
    ]);
    if (socialMediaFetch.response.status === 200) {
      for (const item of socialMediaFetch.data) {
        const socialMediaItem = {
          id: item.id,
          name: item.name,
          url: item.url,
          client_id: item.client_id,
        };
        dispatch({
          type: UserTypes.ADD_SOCIAL_MEDIA,
          payload: socialMediaItem,
        });
      }
    }
    if (companyFetch.data[0] === 1) {
      messageSuccess('Datos de la compañia actualizados correctamente.');
      setIsOpenDrawer(false);
      return dispatch({
        type: UserTypes.UPDATE_COMPANY,
        payload: companyData,
      });
    }
    if (companyFetch.response.status === 404) {
      messageError('No se ha encontrado al usuario.');
      return notificationErr(userCompanyError.update_company_001, translate);
    }
  } catch (err) {
    messageError('Error al actualizar los datos de empresa.');
    return notificationErr(userCompanyError.update_company_002, translate);
  }
}

export async function updateUserAvatarAction(
  dispatch: { (value: any): void; (arg0: { type: string; payload: any }): any },
  userId: any,
  image: File,
  token: any,
  translate: any
) {
  try {
    const response = await apiUser.updateUserAvatarAPI(userId, image, token);
    messageSuccess(
      'Avatar de usuario actualizado correctamente. Reiniciando la página para refrescar los cambios'
    );
    dispatch({
      type: UserTypes.UPDATE_USER_AVATAR,
      payload: response,
    });
    return;
  } catch (err) {
    messageError('Error al actualizar el avatar de usuario.');
    return notificationErr(userCompanyError.update_user_avatar_001, translate);
  }
}

export async function updateCompanyLogoAction(
  dispatch: { (value: any): void; (arg0: { type: string; payload: any }): any },
  tenantId: string,
  image: File,
  token: any,
  translate: any
) {
  try {
    const response = await apiUser.updateCompanyLogoAPI(tenantId, image, token);
    messageSuccess(
      'Logo de compañia actualizado correctamente. Reiniciando la página para refrescar los cambios'
    );
    dispatch({
      type: UserTypes.UPDATE_COMPANY_LOGO,
      payload: response,
    });
    return;
  } catch (err) {
    messageError('Error al actualizar el logo de la compañia.');
    return notificationErr(userCompanyError.update_company_logo_001, translate);
  }
}

export async function updateLanguageAction(
  dispatch: any,
  dbUser: IDbUserState,
  language: TUpdateLanguageProps,
  token: string,
  translate: any
) {
  if (language.id === dbUser.language.id) return;
  const body = {
    filter: { id: dbUser.id },
    update: { i18n_lang_id: language.id },
  };
  try {
    const updateLanguageFetch = await apiUser.updateUserLanguageAPI(body, token);
    if (updateLanguageFetch.data.updated_user[0] === 1) {
      return dispatch({ type: UserTypes.UPDATE_LANGUAGE, payload: language });
    } else {
      return notificationErr(userCompanyError.update_language_002, translate);
    }
  } catch (err) {
    return notificationErr(userCompanyError.update_language_001, translate);
  }
}

export async function updateSocialMediaAction(
  dispatch: any,
  companyState: ICompanyState,
  socialMedia: TSocialMedia,
  setShowConfirmEditBtn: any,
  token: string,
  translate: any
) {
  const { client_id, id, url, name } = socialMedia;
  try {
    const updateFetch = await apiCompany.updateSocialMediaAPI(
      client_id,
      id,
      url,
      name,
      token
    );
    if (updateFetch.data[0] === 1) {
      setShowConfirmEditBtn(false);
      messageSuccess('Red social actualizada correctamente.');
      const findSocialMedia = companyState.social_media.findIndex((el) => el.id === id);
      companyState.social_media[findSocialMedia].url = url;
      companyState.social_media[findSocialMedia].name = name;
      return dispatch({
        type: UserTypes.SET_SOCIAL_MEDIA,
        payload: companyState.social_media,
      });
    } else {
      messageError('Error al actualizar la red social.');
      return notificationErr(userCompanyError.updateSocialMedia_002, translate);
    }
  } catch (err) {
    messageError('Error al actualizar la red social.');
    return notificationErr(userCompanyError.updateSocialMedia_001, translate);
  }
}

export async function removeSocialMediaAction(
  dispatch: any,
  companyState: ICompanyState,
  socialMediaId: string,
  token: string,
  translate: any
) {
  try {
    const removeFetch = await apiCompany.removeSocialMediaAPI(socialMediaId, token);
    if (removeFetch.data === 1) {
      const filteredCompany = companyState.social_media.filter(
        (el) => el.id !== socialMediaId
      );
      messageSuccess('Red social eliminada correctamente.');
      return dispatch({
        type: UserTypes.SET_SOCIAL_MEDIA,
        payload: filteredCompany,
      });
    } else {
      messageError('Error al eliminar la red social.');
      return notificationErr(userCompanyError.removeSocialMedia_002, translate);
    }
  } catch (err) {
    return notificationErr(userCompanyError.removeSocialMedia_001, translate);
  }
}

export async function selectUserAndCompanySuperAdminAction(
  dispatch: any,
  client: ISAdminClientState,
  user: ISAdminUserClientState,
  languages: TLanguageResponseItem[],
  translate: any,
  token: string
) {
  if (!client || !user) return;
  dispatch({ type: UserTypes.SET_IS_LOADING, payload: true });
  try {
    const [companyFetch] = await Promise.all([
      apiCompany.getCompanyInfoAPI(user.client_id, token),
    ]);
    client.social_media = companyFetch.client_social_media;
    return dispatch({
      type: UserTypes.LOGIN_COMPANY_USER_SUPER_ADMIN,
      payload: {
        user: user,
        company: client,
        languages,
      },
    });
  } catch (err) {
    console.log(err);
  } finally {
    dispatch({ type: UserTypes.SET_IS_LOADING, payload: false });
  }
}

export async function selectCompanySuperAdminAction(
  dispatch: any,
  client: ISAdminClientState,
  translate: any,
  token: string
) {
  if (!client) return;
  dispatch({ type: UserTypes.SET_IS_LOADING, payload: true });
  try {
    const [companyFetch] = await Promise.all([
      apiCompany.getCompanyInfoAPI(client.id, token),
    ]);
    client.social_media = companyFetch.client_social_media;
    return dispatch({
      type: UserTypes.LOGIN_COMPANY_SUPER_ADMIN,
      payload: {
        company: client,
      },
    });
  } catch (err) {
    console.log(err);
  } finally {
    dispatch({ type: UserTypes.SET_IS_LOADING, payload: false });
  }
}

/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
const loginExcep = async (
  userFetch: any,
  logout: any,
  translate: any,
  dispatch: any,
  token: string,
  error: any,
  stateValues: any
) => {
  if (!userFetch || !userFetch.response) return;

  const getUrlToRedirectId = () => {
    if (
      process.env.REACT_APP_NODE_ENV === key.clientLocalKey ||
      !process.env.REACT_APP_NODE_ENV
    ) {
      return key.clientLocal;
    } else if (process.env.REACT_APP_NODE_ENV === key.clientProdKey) {
      return key.clientProd;
    } else if (process.env.REACT_APP_NODE_ENV === key.clientDevKey) {
      return key.clientDev;
    } else if (process.env.REACT_APP_NODE_ENV === key.clientStagingKey) {
      return key.clientStaging;
    }
  };

  if (userFetch?.response?.status === 404) {
    return logout();
  }

  if (userFetch?.response?.status === 403) {
    return window.location.replace(getUrlToRedirectId() as string);
  }

  if (userFetch.response?.status === 200) {
    if (userFetch?.data?.role === key.superAdminRole) {
      return dispatch({
        type: UserTypes.LOGIN_USER,
        payload: {
          user: userFetch.data,
        },
      });
    } else {
      const clientId = userFetch?.data?.user_clients[0]?.client?.client_id;
      const [companyFetch] = await Promise.all([
        apiCompany.getCompanyInfoAPI(clientId, token),
      ]);
      if (clientId) {
        return dispatch({
          type: UserTypes.LOGIN_USER,
          payload: {
            user: userFetch.data,
            company: companyFetch,
          },
        });
      }
    }
  } else {
    return notificationErr(error, translate);
  }
};
