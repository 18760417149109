import { IPointsOfSaleState } from 'interfaces/brand.interface';
import { IGetMyBrandsAPIResponse } from './responseInterfaces/myBrands.response.interface';
import { basePath } from './utils/config';

export const getMyBrandsAPI = async (
  clientId: string,
  stateId: string | null,
  token: string
): Promise<IGetMyBrandsAPIResponse> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${basePath}/client_profile/get_client_brands_works?client_id=${clientId}&state_id=${stateId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const removeFromMyBrandAPI = async (
  associationId: string,
  token: string
): Promise<any> => {
  const params = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${basePath}/client_profile/delete_association_with_brand?association_id=${associationId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const addToMyBrandsAPI = async (body: any, token: string): Promise<any> => {
  const params = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(
    `${basePath}/client_profile/create_association_with_brand`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const getBrandProfileAPI = async (
  brandId: string,
  countryId: string,
  token: string
): Promise<any> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${basePath}/brand_profile/get_brand_profile?brand_id=${brandId}&country_id=${countryId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const getBrandsProfileAPI = async (
  brandId: string,
  token: string
): Promise<any> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${basePath}/brand_profile/get_brand_profile?brand_id=${brandId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const getBrandsAPI = async (body: any, token: string): Promise<any> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${basePath}/brand/search`, params);
  const data = await response.json();
  return { response, data };
};

export const getAssociationsStatesAPI = async (token: string): Promise<any> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${basePath}/client_profile/get_association_states`,
    params
  );
  const data = await response.json();
  return { response, data };
};

type TgetTenantPointsOfSaleAPIResponse = {
  response: Response;
  data: Array<IPointsOfSaleState>;
};
export const getTenantPointsOfSaleAPI = async (
  clientId: string,
  tenantId: string,
  token: string
): Promise<TgetTenantPointsOfSaleAPIResponse> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${basePath}/datashare/get_client_datashare?client_id=${clientId}&tenant_id=${tenantId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const shareDataWithBrandAPI = async (
  datashareId: string,
  value: boolean,
  token: string
): Promise<any> => {
  const params = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify([
      {
        data_share_id: datashareId,
        data_share: value,
      },
    ]),
  };
  const response = await fetch(`${basePath}/datashare/update_client_datashare`, params);
  const data = await response.json();
  return { response, data };
};
