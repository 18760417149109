/* eslint-disable react-hooks/exhaustive-deps */
import { useReducer, useMemo, useCallback, memo } from 'react';
import BrandContext from './BrandContext';
import initialBrandState from './initialBrandState';
import brandReducer from 'contexts/brand/reducer/brand.reducer';
import * as action from 'contexts/brand/reducer/brand.actions';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { ITenantState } from 'interfaces/brand.interface';
import useContextUser from 'hooks/contexts/useContextUser';
import useContextMyBrands from 'hooks/contexts/useContextMyBrands';
import { ChildrenProps } from 'interfaces/general.interface';

function BrandProvider(props: ChildrenProps) {
  const [brandState, dispatch] = useReducer(brandReducer, initialBrandState);
  const { getAccessTokenSilently } = useAuth0();
  const { t: translate } = useTranslation();
  const { company } = useContextUser();
  const { myBrands } = useContextMyBrands();

  const getBrand = useCallback(
    async (brandId: string) => {
      const token = await getAccessTokenSilently();
      if (!company.id || !company.country?.id || !myBrands) return;
      action.getBrandAction(
        dispatch,
        company.id,
        brandId,
        company.country.id,
        myBrands,
        token,
        translate
      );
    },
    [company.id, company.country?.id, myBrands, translate]
  );

  const selectTenant = useCallback(
    async (tenant: ITenantState) => {
      action.selectTenantAction(dispatch, tenant, translate);
    },
    [translate]
  );

  const shareDataWithBrand = useCallback(
    async (datashareId: string, value: boolean) => {
      const token = await getAccessTokenSilently();
      action.shareDataWithBrandAction(
        dispatch,
        datashareId,
        value,
        brandState,
        token,
        translate
      );
    },
    [brandState, translate]
  );

  const memoProvider = useMemo(
    () => ({
      ...brandState,
      getBrand,
      selectTenant,
      shareDataWithBrand,
    }),
    [brandState, getBrand, selectTenant, shareDataWithBrand]
  );

  return (
    <BrandContext.Provider value={memoProvider}>{props.children}</BrandContext.Provider>
  );
}

export default memo(BrandProvider);
