import { IInitialSuperAdminState } from 'interfaces/superAdmin.interface';
import * as SuperAdminOfSaleType from './superAdmin.types';

export default function superAdminReducer(state: IInitialSuperAdminState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case SuperAdminOfSaleType.SET_CLIENTS:
      return {
        ...state,
        clients: payload,
      };

    default:
      return state;
  }
}
