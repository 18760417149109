import { IAssociationsStates } from 'interfaces/user.interface';

export const getAssociationStateName = (
  state_id: string,
  associationStates: IAssociationsStates
) => {
  if (state_id === associationStates.pending.id) {
    return associationStates.pending.state;
  }
  if (state_id === associationStates.connected.id) {
    return associationStates.connected.state;
  }
  if (state_id === associationStates.rejected.id) {
    return associationStates.rejected.state;
  }
  if (state_id === associationStates.disconnected.id) {
    return associationStates.disconnected.state;
  }
};
