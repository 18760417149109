import { createContext } from 'react';

export const DrawersMultimediaContext = createContext({
  isOpenImagesDrawer: false,
  isOpenVideosDrawer: false,
  isOpenBannersDrawer: false,
  setIsOpenImagesDrawerAction: (isOpen: boolean) => {},
  setIsOpenVideosDrawerAction: (isOpen: boolean) => {},
  setIsOpenBannersDrawerAction: (isOpen: boolean) => {},
});
