import * as MetaTypes from './meta.types';
import { IInitialMetaState } from 'interfaces/meta.interface';

export default function metaReducer(state: IInitialMetaState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case MetaTypes.SET_IS_LOADING_CONNECTIONS: {
      const setConnections: IInitialMetaState = {
        ...state,
        isLoadingConnections: payload,
      };
      return setConnections;
    }

    // SFTP ///////////////////////////////////////////////////////////
    case MetaTypes.SET_SFTP_CONNECTIONS: {
      const setSftpConnections: IInitialMetaState = {
        ...state,
        sftp: {
          ...state.sftp,
          connections: payload,
        },
      };
      return setSftpConnections;
    }

    case MetaTypes.DELETE_SFTP_CONNECTION: {
      const deleteSftpConnection: IInitialMetaState = {
        ...state,
        sftp: {
          ...state.sftp,
          connections: state.sftp.connections.filter((item) => item.name !== payload),
        },
      };
      return deleteSftpConnection;
    }

    case MetaTypes.SET_IS_LOADING_DELETE_SFPT_CONNECTIONS: {
      const setIsLoadingRemoveSftpConnection: IInitialMetaState = {
        ...state,
        sftp: {
          ...state.sftp,
          isLoadingRemoveSftpConnection: {
            loading: payload.loading,
            user_name: payload.user_name,
          },
        },
      };
      return setIsLoadingRemoveSftpConnection;
    }

    case MetaTypes.ADD_NEW_CONNECTION:
      const addNewSftpConnection: IInitialMetaState = {
        ...state,
        sftp: {
          ...state.sftp,
          connections: [payload, ...state.sftp.connections],
        },
      };
      return addNewSftpConnection;

    case MetaTypes.SET_IS_LOADING_CREATE_SFPT_CONNECTIONS: {
      const setIsLoadingCreateSftpConection: IInitialMetaState = {
        ...state,
        sftp: {
          ...state.sftp,
          isLoadingCreateSftpConnection: payload,
        },
      };
      return setIsLoadingCreateSftpConection;
    }
    ///////////////////////////////////////////////////////////////////

    // SHOPIFY ////////////////////////////////////////////////////////
    case MetaTypes.SET_SHOPIFY_CONNECTIONS: {
      const setShopifyConnections: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          connections: payload,
        },
      };
      return setShopifyConnections;
    }

    case MetaTypes.ADD_NEW_SHOPIFY_CONNECTION: {
      const addNewShopifyConnection: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          connections: [payload, ...state.shopify.connections],
        },
      };
      return addNewShopifyConnection;
    }
    ///////////////////////////////////////////////////////////////////

    case MetaTypes.SET_MAPPING_KEYS:
      const setMappingKeys: IInitialMetaState = {
        ...state,
        h4rMappingKeys: payload,
      };
      return setMappingKeys;

    default:
      return state;
  }
}
