/* eslint-disable react-hooks/exhaustive-deps */
import { useReducer, useMemo, useCallback, useEffect, memo } from 'react';
import BrandsContext from './BrandsContext';
import initialBrandsState from './initialBrandsState';
import brandsReducer from 'contexts/brands/reducer/brands.reducer';
import * as action from 'contexts/brands/reducer/brands.actions';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import useContextMyBrands from 'hooks/contexts/useContextMyBrands';
import { ChildrenProps } from 'interfaces/general.interface';

function BrandsProvider(props: ChildrenProps) {
  const [brandsState, dispatch] = useReducer(brandsReducer, initialBrandsState);
  const { isLoading } = useContextMyBrands();
  const { getAccessTokenSilently } = useAuth0();
  const { t: translate } = useTranslation();

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      if (!isLoading && brandsState.allBrands.length <= 1) {
        action.getAllBrandsAction({ dispatch, token, translate });
      }
    })();
  }, [isLoading, brandsState.allBrands.length, translate]);

  const addMoreBrands = useCallback(async () => {
    const token = await getAccessTokenSilently();
    action.addMoreBrandsAction(dispatch, brandsState, token, translate);
  }, [brandsState, translate]);

  const setBodySearch = useCallback(
    async (query: string) => {
      const token = await getAccessTokenSilently();
      action.setBodySearchAction(
        dispatch,
        query,
        brandsState.bodyFetch,
        token,
        translate
      );
    },
    [brandsState.bodyFetch, translate]
  );

  const memoProvider = useMemo(
    () => ({
      ...brandsState,
      addMoreBrands,
      setBodySearch,
    }),
    [brandsState, addMoreBrands, setBodySearch]
  );

  return (
    <BrandsContext.Provider value={memoProvider}>{props.children}</BrandsContext.Provider>
  );
}

export default memo(BrandsProvider);
