import {
  IInitialMetaState,
  TH4RMappingKeysItem,
  TShopifyConnectionItem,
} from 'interfaces/meta.interface';

const initialMetaState: IInitialMetaState = {
  getConnections: () => Promise.resolve(false),
  isLoadingConnections: false,
  sftp: {
    connections: [],
    createSftpConnection: () => Promise.resolve(false),
    isLoadingCreateSftpConnection: false,
    removeSftpConnection: () => {},
    isLoadingRemoveSftpConnection: {
      loading: false,
      user_name: '',
    },
  },
  shopify: {
    connections: [] as TShopifyConnectionItem[],
    mappingKeys: [
      {
        value: 'productDescription',
        label: 'productDescription',
        type: 'products',
        examples: ['123', '456'],
      },
      {
        value: 'ean',
        label: 'ean',
        type: 'products',
        examples: ['85466e45345656', '85466e453456123'],
      },
      {
        value: 'points_of_sale',
        label: 'points_of_sale',
        type: 'sales',
        examples: ['Punto de venta 1', 'Punto de venta 1'],
      },
      {
        value: 'pvi',
        label: 'pvi',
        type: 'sales',
        examples: [100, 200],
      },
    ],
    mapping: {
      createdAt: '2024-09-05T13:29:37.267Z',
      updatedAt: '2024-09-05T13:29:37.267Z',
      id: '123',
      tenant_id: null,
      brand_id: null,
      category: 'category',
      client_id: '123456',
      date_format: 'YYYY-MM-DD',
      productsMap: {
        product_description: 'productDescription',
        ean: 'ean',
      },
      salesMap: {
        multibrand_point_of_sale: 'points_of_sale',
        price: 'pvi',
      },
      point_of_sale_map: {
        '123': {
          name: 'Point of sale 1',
          id: '123',
        },
        '456': {
          name: 'Point of sale 2',
          id: '456',
        },
      },
      tags: ['tag1', 'tag2'],
    },
    // Create
    createShopifyConnection: () => Promise.resolve(false),
    isLoadingCreateShopifyConnection: false,
    // Update
    updateShopifyConnection: () => Promise.resolve(false),
    isLoadingUpdateShopifyConnection: false,
    // Remove
    removeShopifyConnection: () => Promise.resolve(false),
    isLoadingRemoveShopifyConnection: {
      loading: false,
      connection_id: '',
    },
  },
  h4rMappingKeys: [] as TH4RMappingKeysItem[],
  getH4rMappingKeys: () => Promise.resolve(false),
};

export default initialMetaState;
