/* eslint-disable react-hooks/exhaustive-deps */
import { useReducer, useMemo, useCallback, useEffect, memo } from 'react';
import CatalogContext from './CatalogContext';
import initialCatalogState from './initialCatalogState';
import * as CatalogTypes from 'contexts/catalog/reducer/catalog.types';
import catalogReducer from 'contexts/catalog/reducer/catalog.reducer';
import * as action from 'contexts/catalog/reducer/catalog.actions';
import { useAuth0 } from '@auth0/auth0-react';
import { initialProductsQuantity } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { abortCatalogController } from 'utils/abortController';
import { TQuerySearchArray } from 'interfaces/catalog.interface';
import useContextUser from 'hooks/contexts/useContextUser';
import useContextMyBrands from 'hooks/contexts/useContextMyBrands';
import { ChildrenProps } from 'interfaces/general.interface';

function CatalogProvider(props: ChildrenProps) {
  const [catalogState, dispatch] = useReducer(catalogReducer, initialCatalogState);
  const { getAccessTokenSilently } = useAuth0();
  const { dbUser, company } = useContextUser();
  const { connectedBrands } = useContextMyBrands();
  const { t: translate } = useTranslation();

  useEffect(() => {
    (async () => {
      if (connectedBrands.length > 0 && dbUser.language?.id && company.id) {
        const associationsId: string[] = connectedBrands.map((brand) => brand.brand.id);
        dispatch({
          type: CatalogTypes.SET_BODY_PRODUCT,
          payload: {
            brandId: associationsId,
            clientId: company.id,
            limit: initialProductsQuantity,
            languageId: dbUser.language?.id,
          },
        });
        if (catalogState.catalogItems.data.length > 0) return;
        const bodyProductSearchTempCopy = JSON.parse(
          JSON.stringify(catalogState.bodyProductSearchTemp)
        );
        bodyProductSearchTempCopy.options.brand_id = associationsId;
        bodyProductSearchTempCopy.options.client_id = company.id;
        bodyProductSearchTempCopy.options.limit = initialProductsQuantity;
        bodyProductSearchTempCopy.options.language_id = dbUser.language?.id;
        const signal = abortCatalogController();
        const token = await getAccessTokenSilently();
        return action.getCatalogAction(
          dispatch,
          bodyProductSearchTempCopy,
          translate,
          signal,
          token
        );
      }
    })();
  }, [company.id, connectedBrands, dbUser.language?.id, translate]);

  // const getCatalog = useCallback(async () => {
  // 	const signal = abortCatalogController()
  // 	const token = await getAccessTokenSilently()
  // 	return action.getCatalogAction(dispatch, catalogState.bodyProductSearchTemp, translate, signal, token)
  // }, [getAccessTokenSilently, catalogState.bodyProductSearchTemp, translate])

  const getRelatedProducts = useCallback(async () => {
    const token = await getAccessTokenSilently();
    return (
      catalogState.bodyProductSearchTemp.options.brand_id[0] &&
      action.getRelatedProductsAction(
        dispatch,
        company.id,
        catalogState.bodyProductSearchTemp,
        translate,
        token
      )
    );
  }, [catalogState.bodyProductSearchTemp, company.id, translate]);

  const addMoreProductsToCatalog = useCallback(async () => {
    const token = await getAccessTokenSilently();
    return (
      catalogState.bodyProductSearchTemp &&
      action.addMoreProductsToCatalogAction(
        dispatch,
        catalogState.bodyProductSearchTemp,
        translate,
        token
      )
    );
  }, [catalogState.bodyProductSearchTemp, translate]);

  const getProduct = useCallback(
    async (productId: string, brandId: string) => {
      const token = await getAccessTokenSilently();
      if (dbUser.id) {
        return action.getProductAction(
          dispatch,
          productId,
          brandId,
          dbUser.language.id,
          dbUser.id,
          token
        );
      }
    },
    [dbUser]
  );

  const resetCatalogItems = useCallback(async () => {
    return dispatch({ type: CatalogTypes.RESET_CATALOG_ITEMS });
  }, []);

  const addToBodySearch = useCallback(
    async (query: string) => {
      const signal = abortCatalogController();
      const token = await getAccessTokenSilently();
      return action.addToBodySearchAction(
        dispatch,
        query,
        catalogState.bodyProductSearchTemp,
        translate,
        signal,
        token
      );
    },
    [catalogState.bodyProductSearchTemp, translate]
  );

  const removeFromBodySearch = useCallback(
    async (query: string) => {
      const signal = abortCatalogController();
      const token = await getAccessTokenSilently();
      return action.removeFromBodySearchAction(
        dispatch,
        query,
        catalogState.bodyProductSearchTemp,
        translate,
        signal,
        token
      );
    },
    [catalogState.bodyProductSearchTemp, translate]
  );

  const removeAllFromBodySearch = useCallback(async () => {
    const signal = abortCatalogController();
    const token = await getAccessTokenSilently();
    return action.removeAllFromBodySearchAction(
      dispatch,
      catalogState,
      translate,
      signal,
      token
    );
  }, [catalogState, translate]);

  const removeFromBodySearchFilter = useCallback(
    async (value: TQuerySearchArray) => {
      const signal = abortCatalogController();
      const token = await getAccessTokenSilently();
      return action.removeFromBodySearchFilterAction(
        dispatch,
        value,
        catalogState,
        translate,
        signal,
        token
      );
    },
    [catalogState, translate]
  );

  const filter = useCallback(
    async (setOpenDrawer: any) => {
      const signal = abortCatalogController();
      const token = await getAccessTokenSilently();
      return await action.filterAction(
        dispatch,
        setOpenDrawer,
        catalogState.bodyProductSearchTemp,
        connectedBrands,
        translate,
        signal,
        token
      );
    },
    [catalogState.bodyProductSearchTemp, connectedBrands, translate]
  );

  const addBodyFilters = useCallback(
    async (field: string, value: string[]) => {
      return action.addBodyFiltersAction(
        dispatch,
        field,
        value,
        catalogState.bodyProductSearchTemp
      );
    },
    [catalogState.bodyProductSearchTemp]
  );

  const removeBodyFilters = useCallback(
    async (field: string, value: string[]) => {
      return action.removeBodyFiltersAction(
        dispatch,
        field,
        value,
        catalogState.bodyProductSearchTemp
      );
    },
    [catalogState.bodyProductSearchTemp]
  );

  const addBodyBrandOptions = useCallback(
    async (value: string[]) => {
      return action.addBodyBrandOptionsAction(
        dispatch,
        value,
        catalogState.bodyProductSearchTemp
      );
    },
    [catalogState.bodyProductSearchTemp]
  );

  const removeBodyBrandOptions = useCallback(
    async (value: string[]) => {
      return action.removeBodyBrandOptionsAction(
        dispatch,
        value,
        catalogState.bodyProductSearchTemp
      );
    },
    [catalogState.bodyProductSearchTemp]
  );

  const resetTempBodyFilters = useCallback(async () => {
    return dispatch({ type: CatalogTypes.RESET_TEMP_BODY_FILTERS });
  }, []);

  const resetBodyFilters = useCallback(
    async (setOpenDrawer: any) => {
      const signal = abortCatalogController();
      const token = await getAccessTokenSilently();
      return action.resetBodyFiltersAction(
        dispatch,
        catalogState.bodyProductSearchTemp,
        setOpenDrawer,
        translate,
        signal,
        token
      );
    },
    [catalogState.bodyProductSearchTemp, translate]
  );

  const setQuerySearch = useCallback(async (query: string) => {
    return action.setQuerySearchAction(dispatch, query);
  }, []);

  const resetPriceSlider = useCallback(
    async (type: string) => {
      const signal = abortCatalogController();
      const token = await getAccessTokenSilently();
      return action.resetPriceSliderAction(
        dispatch,
        type,
        catalogState.bodyProductSearchTemp,
        translate,
        signal,
        token
      );
    },
    [catalogState.bodyProductSearchTemp, translate]
  );

  const setSortBy = useCallback(
    async (sortBy: string) => {
      const signal = abortCatalogController();
      const token = await getAccessTokenSilently();
      return action.setSortByAction(
        dispatch,
        sortBy,
        catalogState.bodyProductSearchTemp,
        translate,
        signal,
        token
      );
    },
    [catalogState.bodyProductSearchTemp, translate]
  );

  const setPriceSliders = useCallback(async (field: string, value: any) => {
    return action.setPriceSlidersAction(dispatch, field, value);
  }, []);

  const getProductsByBrands = useCallback(
    async (brands: string[]) => {
      const signal = abortCatalogController();
      const token = await getAccessTokenSilently();
      return action.getProductsByBrandsAction(
        dispatch,
        connectedBrands,
        brands,
        translate,
        catalogState.bodyProductSearchTemp,
        signal,
        token
      );
    },
    [catalogState.bodyProductSearchTemp, connectedBrands, translate]
  );

  const setFavorites = useCallback(async (userId: string) => {
    return action.setFavoritesAction(dispatch, userId);
  }, []);

  const addLike = useCallback(
    async (productId: string, setHaveLike: () => void) => {
      const token = await getAccessTokenSilently();
      return action.addLikeAction(
        dispatch,
        catalogState.catalogItems,
        dbUser.id,
        productId,
        setHaveLike,
        token
      );
    },
    [dbUser.id, catalogState.catalogItems]
  );

  const removeLike = useCallback(
    async (productId: string, favorites: any, setHaveLike: () => void) => {
      const token = await getAccessTokenSilently();
      return action.removeLikeAction(
        dispatch,
        catalogState.catalogItems,
        productId,
        favorites,
        dbUser.id,
        setHaveLike,
        token
      );
    },
    [catalogState.catalogItems, dbUser.id]
  );

  const addLikeToProduct = useCallback(
    async (productId: string, setHaveLike: () => void) => {
      const token = await getAccessTokenSilently();
      return action.addLikeToProductAction(
        dispatch,
        catalogState.catalogItems,
        dbUser.id,
        productId,
        setHaveLike,
        token
      );
    },
    [catalogState.catalogItems, dbUser.id]
  );

  const removeLikeToProduct = useCallback(
    async (favorites: any, productId: string, setHaveLike: () => void) => {
      const token = await getAccessTokenSilently();
      return action.removeLikeToProductAction(
        dispatch,
        catalogState.catalogItems,
        productId,
        favorites,
        dbUser.id,
        setHaveLike,
        token
      );
    },
    [catalogState.catalogItems, dbUser.id]
  );

  const addLikeToRelatedProduct = useCallback(
    async (productId: string, setHaveLike: () => void) => {
      const token = await getAccessTokenSilently();
      return action.addLikeToRelatedProductAction(
        dispatch,
        catalogState,
        dbUser.id,
        productId,
        setHaveLike,
        token
      );
    },
    [dbUser.id, catalogState]
  );

  const removeLikeToRelatedProduct = useCallback(
    async (productId: string, favorites: any, setHaveLike: () => void) => {
      const token = await getAccessTokenSilently();
      return action.removeLikeToRelatedProductAction(
        dispatch,
        catalogState,
        productId,
        favorites,
        dbUser.id,
        setHaveLike,
        token
      );
    },
    [dbUser.id, catalogState]
  );

  const setProductBrandId = useCallback(async (brandId: string) => {
    return action.setProductBrandIdAction(dispatch, brandId);
  }, []);

  const addToQuerySearchArray = useCallback(async (value: TQuerySearchArray) => {
    return action.addToQuerySearchArrayAction(dispatch, value);
  }, []);

  const removeFromQuerySearchArray = useCallback(async (value: TQuerySearchArray) => {
    return action.removeFromQuerySearchArrayAction(dispatch, value);
  }, []);

  const memoProvider = useMemo(
    () => ({
      ...catalogState,
      // getCatalog,
      getRelatedProducts,
      addMoreProductsToCatalog,
      addToBodySearch,
      removeFromBodySearch,
      resetCatalogItems,
      removeAllFromBodySearch,
      removeFromBodySearchFilter,
      getProduct,
      filter,
      resetTempBodyFilters,
      addBodyFilters,
      removeBodyFilters,
      addBodyBrandOptions,
      removeBodyBrandOptions,
      resetBodyFilters,
      setQuerySearch,
      setSortBy,
      setPriceSliders,
      getProductsByBrands,
      setFavorites,
      addLike,
      removeLike,
      addLikeToProduct,
      removeLikeToProduct,
      addLikeToRelatedProduct,
      removeLikeToRelatedProduct,
      setProductBrandId,
      addToQuerySearchArray,
      removeFromQuerySearchArray,
      resetPriceSlider,
    }),
    [
      catalogState,
      // getCatalog,
      getRelatedProducts,
      addMoreProductsToCatalog,
      addToBodySearch,
      removeFromBodySearch,
      resetCatalogItems,
      removeAllFromBodySearch,
      removeFromBodySearchFilter,
      getProduct,
      filter,
      resetTempBodyFilters,
      addBodyFilters,
      removeBodyFilters,
      addBodyBrandOptions,
      removeBodyBrandOptions,
      resetBodyFilters,
      setQuerySearch,
      setSortBy,
      setPriceSliders,
      getProductsByBrands,
      setFavorites,
      addLike,
      removeLike,
      addLikeToProduct,
      removeLikeToProduct,
      addLikeToRelatedProduct,
      removeLikeToRelatedProduct,
      setProductBrandId,
      addToQuerySearchArray,
      removeFromQuerySearchArray,
      resetPriceSlider,
    ]
  );

  return (
    <CatalogContext.Provider value={memoProvider}>
      {props.children}
    </CatalogContext.Provider>
  );
}

export default memo(CatalogProvider);
