import { IInitialBrandUsersState } from 'interfaces/brandUsers.interface';
import * as BrandUsersTypes from './brandUsers.types';

export default function brandUsersReducer(state: IInitialBrandUsersState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case BrandUsersTypes.GET_BRAND_USERS:
      const getBrandUsers: IInitialBrandUsersState = {
        ...state,
        brandUsers: payload,
      };
      return getBrandUsers;

    case BrandUsersTypes.SELECT_BRAND_USER:
      const selectBrandUser: IInitialBrandUsersState = {
        ...state,
        brandUsersSelected: payload,
      };
      return selectBrandUser;

    case BrandUsersTypes.CREATE_BRAND_USER:
      const createBrandUser: IInitialBrandUsersState = {
        ...state,
        brandUsers: [...state.brandUsers, payload],
      };
      return createBrandUser;

    case BrandUsersTypes.UPDATE_BRAND_USER:
      const updateBrandUser: IInitialBrandUsersState = {
        ...state,
        brandUsers: payload,
      };
      return updateBrandUser;

    case BrandUsersTypes.DELETE_BRAND_USER:
      const deleteBrandUser: IInitialBrandUsersState = {
        ...state,
        brandUsers: state.brandUsers.filter((user) => user.id !== payload),
      };
      return deleteBrandUser;

    case BrandUsersTypes.SET_IS_LOADING_ACTION:
      const setIsLoadingAction: IInitialBrandUsersState = {
        ...state,
        isLoadingAction: payload,
      };
      return setIsLoadingAction;

    case BrandUsersTypes.SET_IS_LOADING_USERS:
      const setIsLoadingUsers: IInitialBrandUsersState = {
        ...state,
        isLoadingUsers: payload,
      };
      return setIsLoadingUsers;

    default:
      return state;
  }
}
