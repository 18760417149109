import { useContext } from 'react';
import LanguageContext from 'contexts/UI/language/LanguageContext';

export default function useContextUILanguage() {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useContextUILanguage must be used within a UILanguageProvider');
  }
  return context;
}
