import { useContext } from 'react';
import CountriesContext from 'contexts/UI/countries/CountriesContext';

export default function useContextCountries() {
  const context = useContext(CountriesContext);
  if (context === undefined) {
    throw new Error('useContextCountries must be used within a CountriesProvider');
  }
  return context;
}
