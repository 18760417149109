import { IBrandUser, IInitialBrandUsersState } from 'interfaces/brandUsers.interface';

const initialBrandUsersState: IInitialBrandUsersState = {
  brandUsers: [] as IBrandUser[],
  brandUsersSelected: {} as IBrandUser,
  isLoadingUsers: false,
  isLoadingAction: false,
  getBrandUsers: () => {},
  selectBrandUser: () => {},
  createBrandUser: () => {},
  updateBrandUser: () => {},
  deleteBrandUser: () => {},
};

export default initialBrandUsersState;
