/* eslint-disable react-hooks/exhaustive-deps */
import { useReducer, useMemo, useCallback, memo, useEffect } from 'react';
import SalesContext from './SalesContext';
import initialSalesState from './initialSalesState';
import salesReducer from './reducer/sales.reducer';
import * as action from './reducer/sales.actions';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import {
  ICreateMappingBody,
  IInitialSalesState,
  TsetCSVSelectedProps,
  TupdatePointsOfSaleMapProps,
} from 'interfaces/sales.interface';
import useContextUser from 'hooks/contexts/useContextUser';
import * as CatalogCsvUploadType from './reducer/sales.types';
import { ChildrenProps } from 'interfaces/general.interface';
import { superAdminRole } from 'utils/constants';

function SalesProvider(props: ChildrenProps) {
  const [ssState, dispatch] = useReducer(salesReducer, initialSalesState);
  const salesState: IInitialSalesState = ssState;
  const { getAccessTokenSilently, isLoading } = useAuth0();
  const { company, dbUser } = useContextUser();
  const { t: translate } = useTranslation();

  useEffect(() => {
    if (dbUser.role === superAdminRole) {
      dispatch({
        type: CatalogCsvUploadType.SET_SALES_HISTORY,
        payload: [],
      });
    }
  }, [dbUser.role, company.id]);

  const getHistory = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (isLoading || !token || !company?.id) return;
    action.getSalesHistoryAction(dispatch, company.id, token, translate);
  }, [isLoading, company?.id, translate]);

  const removeUpload = useCallback(
    async (uploadId: string, multiple: boolean) => {
      const token = await getAccessTokenSilently();
      if (isLoading || !token) return false;
      return (await action.removeUploadAction(
        dispatch,
        uploadId,
        multiple,
        token,
        translate
      )) as boolean;
    },
    [isLoading, translate]
  );

  const setCSVSelected = useCallback(
    ({ csvData, file, navigate, sendFile }: TsetCSVSelectedProps) => {
      action.setCSVSelectedAction(dispatch, csvData, file as File, navigate, sendFile);
    },
    []
  );

  const setCSVSelectedCsvData = useCallback(({ csvData }: { csvData: any[] }) => {
    action.setCSVSelectedCsvDataAction(dispatch, csvData);
  }, []);

  const setCsvMappingKeys = useCallback(
    (values: { [key: string]: string | undefined }) => {
      action.setCsvMappingKeysAction(dispatch, values, salesState.csvMappingKeys);
    },
    [salesState.csvMappingKeys]
  );

  const setIsLoadingCSVSelected = useCallback((isLoading: boolean) => {
    dispatch({
      type: CatalogCsvUploadType.SET_IS_LOADING_CSV_SELECTED,
      payload: isLoading,
    });
  }, []);

  const getMappings = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (isLoading || !token || !company.id) return;
    action.getMappingsAction(dispatch, company.id, token, translate);
  }, [isLoading, company.id, translate]);

  const deleteMapping = useCallback(
    async (mappingId: string) => {
      const token = await getAccessTokenSilently();
      if (isLoading || !token) return;
      action.deleteMappingAction(dispatch, mappingId, token, translate);
    },
    [isLoading, translate]
  );

  const createMapping = useCallback(
    async (body: ICreateMappingBody, navigate: Function) => {
      const token = await getAccessTokenSilently();
      if (isLoading || !token) return;
      action.createMappingAction(
        dispatch,
        body,
        salesState.sendFile,
        token,
        translate,
        navigate
      );
    },
    [isLoading, translate, salesState.sendFile]
  );

  const updatePointsOfSaleMap = useCallback(
    async ({ csvMapId, pointsOfSaleMap }: TupdatePointsOfSaleMapProps) => {
      const token = await getAccessTokenSilently();
      return (await action.updatePointsOfSaleMapAction(
        dispatch,
        salesState.csvMappings,
        csvMapId,
        pointsOfSaleMap,
        salesState.sendFile,
        token,
        translate
      )) as boolean;
    },
    [salesState.csvMappings, salesState.sendFile, translate]
  );

  const saveSaleFile = useCallback(
    async ({ csvMapId }: { csvMapId: string }) => {
      const token = await getAccessTokenSilently();
      if (isLoading || !token) return false;
      return (await action.saveSaleFileAction(
        dispatch,
        salesState,
        csvMapId,
        company,
        token,
        translate
      )) as boolean;
    },
    [isLoading, salesState, company, translate]
  );

  const memoProvider = useMemo(
    () => ({
      ...salesState,
      getHistory,
      removeUpload,
      setCSVSelected,
      setCSVSelectedCsvData,
      setCsvMappingKeys,
      getMappings,
      deleteMapping,
      createMapping,
      setIsLoadingCSVSelected,
      updatePointsOfSaleMap,
      saveSaleFile,
    }),
    [
      salesState,
      getHistory,
      removeUpload,
      setCSVSelected,
      setCSVSelectedCsvData,
      setCsvMappingKeys,
      getMappings,
      deleteMapping,
      createMapping,
      setIsLoadingCSVSelected,
      updatePointsOfSaleMap,
      saveSaleFile,
    ]
  );

  return (
    <SalesContext.Provider value={memoProvider}>{props.children}</SalesContext.Provider>
  );
}

export default memo(SalesProvider);
